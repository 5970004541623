import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { Grid, Tooltip, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Add as AddIcon } from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function AddComponent(props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const { trackIndex, addComponent, isModifyEnabled, translation, position } =
    props

  const [title, setTitle] = React.useState('')
  const [add, setAdd] = React.useState('')
  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        if (position === 'LocationFrom') {
          setTitle(t('tabs.traceability.inputs.addFacilityFrom'))
        } else if (position === 'LocationTo') {
          setTitle(t('tabs.traceability.inputs.addFacilityTo'))
        } else if (position === 'Inputs') {
          setTitle(t('tabs.traceability.inputs.addInput'))
        } else if (position === 'Outputs') {
          setTitle(t('tabs.traceability.inputs.addOutput'))
        }

        setAdd(t('tabs.traceability.inputs.add'))
        break
      }
      case 'lotDetails': {
        if (position === 'LocationFrom') {
          setTitle(t('tabs.traceability.inputs.addFacilityFrom'))
        } else if (position === 'LocationTo') {
          setTitle(t('tabs.traceability.inputs.addFacilityTo'))
        } else if (position === 'Inputs') {
          setTitle(t('tabs.traceability.inputs.addInput'))
        } else if (position === 'Outputs') {
          setTitle(t('tabs.traceability.inputs.addOutput'))
        }
        setAdd(t('tabs.traceability.inputs.add'))
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      spacing={2}
    >
      <Grid item xs={6} md={6}>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
          spacing={2}
        >
          <Grid item xs={12}>
            <Tooltip title={title} placement='top-end'>
              <>
                <Button
                  className='inputCardButton'
                  onClick={() => addComponent(trackIndex)}
                  disabled={!isModifyEnabled}
                  size='small'
                  aria-label='aggiungi input'
                >
                  <AddIcon />
                  <span>{add}</span>
                </Button>
              </>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

AddComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  addComponent: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired,
  position: PropTypes.string
}
export default AddComponent

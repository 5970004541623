import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import DomainLogo from '../../components/DomainLogo/DomainLogo'
import DomainLogoDown from '../../components/DomainLogoDown/DomainLogoDown'
import TextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { currLang, changeLanguage, returnAbbr } from '../../utils/translations'
import { updateOrganizationACL } from '../../utils/acl-organization'
import Loader from '../Loader'
import {
  AccountCircleTwoTone as Account,
  Language as LanguageIcon
} from '@material-ui/icons'
import { isLocalhost } from '../../serviceWorker'
import ls from 'local-storage'
import { authenticate } from '../../utils/accounts'

// variabile con i loghi
// const logosData = [
//   {
//     img: '/images/logo_apio.png',
//     title: 'Logo Apio',
//     author: 'Apio',
//     cols: 1
//   }
// ]

const styles = (theme) => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  langMenuContainer: {
    position: 'fixed',
    right: 12,
    top: 12
  },
  button: {
    width: '100%',
    marginTop: '16px',
    background: '#3C4858!important'
    // background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  },
  langButton: {
    color: theme.primary
  },
  link: {
    marginLeft: '8px',
    marginRight: '8px',
    color: theme.primary
  },
  container: {
    marginTop: '-20px',
    height: '97vh'
  },
  paddingTopZero: {
    paddingTop: '0px'
  },
  cardHeaderContainer: {
    // paddingTop: '14px',
    paddingBottom: '10px'
  },
  media: {
    height: 108,
    marginTop: '20px',
    backgroundSize: '70%',
    backgroundPosition: 'center center'
  },
  logoContainer: {
    backgroundSize: 'contain!important',
    maxHeight: '50',
    marginTop: '20'
  },
  logoContainerCustom: {
    backgroundSize: 'contain!important',
    marginTop: '20'
  },
  accountAvatar: {
    color: '#fff',
    background: '#3C4858!important'
    // background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%) !important'
    // backgroundColor: '#3f51b5'
  },
  logImgContainer: {
    textAlign: 'center'
  },
  logImg: {
    maxWidth: '15%',
    height: 'auto',
    width: '15%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logImgCustom: {
    maxWidth: '100%',
    height: 'auto',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  alertWarning: {
    backgroundColor: '#fdd835'
  },
  alertError: {
    backgroundColor: '#f44336'
  },
  whiteText: {
    color: 'white'
  }
})

// definisco la classe
class Signin extends React.Component {
  constructor(props) {
    super(props)
    // console.log('props =>', props)
    // console.log('props.t(\'title\') =>', props.t('title'))
    // variabile con lo stato del componente
    // if (props.guidedLoaded) { window.userGuiding.hideChecklist() }
    this.state = {
      loadedGuide: true,
      email: '',
      password: '',
      inputIsMissing: false,
      loginError: false,
      currLang: null,
      anchorLang: null
    }
    // bindo il this di login al this del costruttore
    this.login = this.login.bind(this)
    this.openLang = this.openLang.bind(this)
    this.closeLang = this.closeLang.bind(this)
    // prendo lo user, se c'è redirect alla home
    const userData = ls.get('user')
    if (userData) {
      return this.props.history.push('/')
    }
  }

  // funzione lanciata quando si sta per montare componente
  componentDidMount() {
    // document.body.style.backgroundImage = 'url(/images/Food.jpg)'
    // document.body.style.backgroundSize = 'cover'

    this.setState({ currLang: returnAbbr(currLang) })
    // console.log('this.state.currLang => ', this.state.currLang)
  }

  // query di login
  async login() {
    // DEV MODE
    // FINCHE' non c'è una api dietro, usare questo
    // ls.set('user', { email: 'test@test.com' })
    // ls.set('token', 'pistolino')
    // window.location.pathname = '/'
    // console.log(this.state)
    // PRODUCTION
    // resetto variabile dell'errore
    this.setState({ loginError: false })
    // controllo che ci siano i campi
    if (!this.state.email || !this.state.password) {
      this.setState({ inputIsMissing: true })
    } else {
      this.setState({ inputIsMissing: false })
      /* per il localhost */
      try {
        const response = await authenticate(
          this.state.email,
          this.state.password
          // this.props.domain.domain
        )
        if (response.data.data.projects && response.data.data.projects.length) {
          const domain = response.data.data.projects[0].domain
          if (
            this.props.domain.subDomains &&
            this.props.domain.subDomains.length &&
            !this.props.domain.subDomains.includes('trackit') &&
            !this.props.domain.subDomains.includes('i-wine')
          ) {
            if (domain !== 'trusty') {
              throw new Error('User is not allowed to access this domain')
            }
          }
        }

        console.log('accounts response => ', JSON.stringify(response.data))
        this.setState({ loadedGuide: false })
        console.log(
          '=======================accounts response =========================== '
        )
        // console.log('user:', response.data.data.user)
        // console.log('token:', response.data.data.token)
        // resetto variabile
        this.setState({ inputIsMissing: false })
        // salvo i dati dell'utente nel local storage
        ls.set('user', response.data.data.account)
        ls.set('token', response.data.data.token)
        // aggiorno i dati sull'organizzazione e sui permessi
        await updateOrganizationACL()
        // cambio location
        window.location.pathname = '/'
      } catch (e) {
        this.setState({ loginError: true })
        // window.alert('Something went wrong')
        console.error(e)
      }
    }
  }

  // funzione che al cambiamento del valore di un input aggiorna la variaible di state corrispondente
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  simulateClick = (event) => {
    if (event.keyCode === 13) {
      this.login()
    }
  }

  // funzione che viene chiamata all'apertura della lingua
  openLang(event) {
    this.setState({ anchorLang: event.currentTarget })
  }

  // funzione che viene chiamata alla chiusura del menu di lingua
  closeLang(lang) {
    if (!lang) {
      this.setState({ anchorLang: null })
      return
    }
    this.setState({ currLang: returnAbbr(lang), anchorLang: null })
    // chiamo la funzione che cambia lingua
    changeLanguage(lang)
  }

  // RENDER
  render() {
    const { classes } = this.props
    return (
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={2}
        className={classes.container}
      >
        <Loader
          show={!this.state.loadedGuide}
          text={this.props.t('guide.loading')}
        ></Loader>
        {/* bottone lingua */}
        <Box className={classes.langMenuContainer}>
          <Button
            onClick={this.openLang}
            className={classes.langButton}
            startIcon={<LanguageIcon />}
            aria-controls='lang-menu'
            aria-haspopup='true'
          >
            {this.state.currLang || 'Lang'}
          </Button>
          <Menu
            id='lang-menu'
            anchorEl={this.state.anchorLang}
            keepMounted
            open={Boolean(this.state.anchorLang)}
            onClose={() => {
              this.closeLang(null)
            }}
          >
            <MenuItem
              onClick={() => {
                this.closeLang('it-IT')
              }}
            >
              ITA
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.closeLang('en-US')
              }}
            >
              ENG
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.closeLang('fr-FR')
              }}
            >
              FRA
            </MenuItem>
          </Menu>
        </Box>
        {/* CARD */}
        <Grid item xs={12} sm={8} md={5} lg={4} xl={3} zeroMinWidth>
          {/* <Box boxShadow={3}> */}
          <Card className='signContainer'>
            <DomainLogo url={window.location.host}></DomainLogo>
            <CardHeader
              className={`${classes.cardHeaderContainer} signInHeader`}
              avatar={
                <Avatar className={classes.accountAvatar} aria-label='login'>
                  <Account />
                </Avatar>
              }
              title={`${this.props.t('title')}!`}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={`${this.props.t('subtitle')}`}
            />
            <CardContent className={classes.paddingTopZero}>
              {this.state.loginError && (
                <Paper className={classes.alertError}>
                  <Box p={1} mt={1}>
                    <Typography component='p' className={classes.whiteText}>
                      <b>{this.props.t('notifications.loginErr.main')}!</b>{' '}
                      {this.props.t('notifications.loginErr.description')}.
                    </Typography>
                  </Box>
                </Paper>
              )}
              {this.state.inputIsMissing && (
                <Paper className={classes.alertWarning} p={1}>
                  <Box p={1} mt={1}>
                    <Typography component='p'>
                      <b>{this.props.t('notifications.inputMissing.main')}!</b>{' '}
                      {this.props.t('notifications.inputMissing.description')}.
                    </Typography>
                  </Box>
                </Paper>
              )}
              <form noValidate autoComplete='off'>
                <TextField
                  onKeyUp={(e) => this.simulateClick(e)}
                  id='email'
                  name='email'
                  label={`${this.props.t('inputs.email')}`}
                  fullWidth
                  value={this.state.email}
                  onChange={this.handleChange}
                  margin='normal'
                  required
                />
                <TextField
                  onKeyUp={(e) => this.simulateClick(e)}
                  id='password'
                  name='password'
                  label={`${this.props.t('inputs.password')}`}
                  type='password'
                  fullWidth
                  onChange={this.handleChange}
                  value={this.state.password}
                  autoComplete='current-password'
                  margin='normal'
                  required
                />
                <Button
                  id='loginBtn'
                  variant='contained'
                  onClick={this.login}
                  color='primary'
                  className={classes.button}
                >
                  {this.props.t('buttons.confirm')}
                </Button>
              </form>
            </CardContent>
            {/* TODO_URGENTE: Make dynamic behaviour (depends on ) domain  */}
            {/* <CardActions>
              <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <a href="/resetpassword" className={classes.link}>
                    { this.props.t('resetPassword') }
                  </a>
                </Grid>
                <Grid item>
                  {(this.props.domain.domain === 'trusty')
                    ? <a href="https://www.trusty.id/trusty" className={classes.link}>
                      { this.props.t('signup') }
                    </a>
                    : <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        this.props.location.pathname = '/signup'
                        this.props.history.push('/signup')
                      }}
                    >
                      { this.props.t('signup') }
                    </Link>
                  }

                </Grid>
              </Grid>
            </CardActions> */}
            <CardActions>
              <Box mt={2} mb={2}>
                <DomainLogoDown url={window.location.host}></DomainLogoDown>
              </Box>
            </CardActions>
          </Card>
          {/* </Box> */}
        </Grid>
      </Grid>
    )
  }
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default withRouter(withStyles(styles)(withTranslation('login')(Signin)))

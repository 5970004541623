/* eslint-disable no-unused-vars*/

import React, {
  useState
  // useEffect
} from 'react'
// import { useHistory } from 'react-router-dom'
import {
  makeStyles,
  Grid,
  Card,
  CardHeader,
  Typography
} from '@material-ui/core'
import { useHomeStats } from './homeStats'
import HomeCard from './HomeCard'
import ChartFilter from './ChartFilter'
import { useTranslation } from 'react-i18next'
// import ls from 'local-storage'
import Loader from '../Loader'
import { isThisDomainIsIwine } from '../../utils/acl-organization'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3)
  }
}))

function Home(props) {
  console.log('Is this domain iwine', isThisDomainIsIwine())
  const root = document.getElementById('root')
  const menu = document.getElementById('customMobileSidebar')
  root.className = ''
  root.classList.add('home')
  if (menu) {
    menu.className = ''
    menu.classList.add('home')
  }
  // const userData = ls.get('user')
  const classes = useStyles()
  const [companies, facilities, products, lots] = useHomeStats()
  const { t } = useTranslation('home')
  const [currentPeriod, setCurrentPeriod] = useState('month')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  // const history = useHistory()
  const loadedGuide = true

  return (
    <>
      <Loader
        show={!loadedGuide}
        // text={t('guide.loading')}
      />
      <Typography className={classes.title} component='h1' variant='h4'>
        {t('title')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={companies} type='companies' />
        </Grid>
        {!isThisDomainIsIwine() && (
          <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
            <HomeCard data={facilities} type='facilities' />
          </Grid>
        )}
        <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={products} type='products' />
        </Grid>
        {!isThisDomainIsIwine() && (
          <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
            <HomeCard data={lots} type='lots' />
          </Grid>
        )}{' '}
      </Grid>{' '}
      {/* TODO: Bisogna cambiare il dato di questo grafico da eventi a lotti */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              className='chartFilter'
              title={t('graph.title')}
              action={
                <ChartFilter
                  setCurrentPeriod={setCurrentPeriod}
                  currentPeriod={currentPeriod}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              }
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Home

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { CardMedia, Paper, IconButton } from '@material-ui/core'

import { Edit as EditIcon } from '@material-ui/icons'
const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  imgInput: {
    display: 'none'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  tabText: {
    color: theme.primary
  }
}))

function AddImageTrackComponent(props) {
  const classes = useStyles()
  const {
    track,
    trackIndex,
    isModifyEnabled,
    translation,
    addTraceabilityImage
  } = props
  const { t } = useTranslation(translation)

  const [title, setTitle] = React.useState('')
  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setTitle(t('tabs.traceability.imgTitle'))
        break
      }
      case 'lotDetails': {
        setTitle(t('tabs.traceability.imgTitle'))
        break
      }
      default: {
        break
      }
    }
  }, [])

  return (
    <CardMedia
      style={{
        height: '280px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
      }}
      image={
        (track.media[0] && track.media[0].original) ||
        '/images/img-placeholder.png'
      }
      title={title}
    >
      <input
        className={classes.imgInput}
        id={`add-new-image-phase-${trackIndex}`}
        type='file'
        accept='image/png, image/jpeg'
        onChange={(e) => addTraceabilityImage(e, trackIndex)}
        disabled={!isModifyEnabled}
      />
      <label htmlFor={`add-new-image-phase-${trackIndex}`}>
        <Paper
          elevation={6}
          style={{ borderRadius: '50%', margin: '5px 5px 0' }}
        >
          <IconButton
            classes={{ colorPrimary: classes.tabText }}
            disabled={!isModifyEnabled}
            component='span'
            color='primary'
            style={isModifyEnabled ? {} : { display: 'none' }}
          >
            <EditIcon />
          </IconButton>
        </Paper>
      </label>
    </CardMedia>
  )
}

AddImageTrackComponent.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  translation: PropTypes.string.isRequired,
  addTraceabilityImage: PropTypes.func.isRequired
}
export default AddImageTrackComponent

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import ls from 'local-storage'
import validate from 'validate.js'
import {
  Container,
  TextField,
  FormHelperText,
  Link,
  Typography,
  Checkbox,
  Box,
  Button
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import clsx from 'clsx'
import Axios from 'axios'
import configuration from '../../configuration'
import { updateOrganizationACL } from '../../utils/acl-organization'
import { useTranslation } from 'react-i18next'
import { authenticate } from '../../utils/accounts'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: 'linear-gradient(45deg, #09A963 40%, #3BD982 100%)'
  },
  policy: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    fill: 'green'
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))

function CompleteForm({
  className,
  changeStatusCode,
  email,
  token,
  domain,
  ...rest
}) {
  const classes = useStyles()

  const { t } = useTranslation('activate')

  const schema = {
    firstName: {
      presence: { allowEmpty: false, message: t('validations.firstName') }
    },
    lastName: {
      presence: { allowEmpty: false, message: t('validations.lastName') }
    },
    password: {
      presence: { allowEmpty: false, message: t('validations.password') }
    },
    confirmPassword: {
      presence: {
        allowEmpty: false,
        message: t('validations.confirmPassword')
      },
      equality: {
        attribute: 'password',
        message: t('validations.equalConfirmPassword')
      }
    },
    country: {
      presence: { allowEmpty: false, message: t('validations.country') }
    },
    companyType: {
      presence: { allowEmpty: false, message: t('validations.companyType') }
    },
    acceptTerms: {
      presence: { allowEmpty: false, message: t('validations.acceptTerms') },
      exclusion: {
        within: [false],
        message: t('validations.acceptTerms')
      }
    }
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {}
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    const body = { ...formState.values, email, token }
    const { password } = formState.values
    try {
      console.log('body: ', body)
      const response = await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/onboardings/activation`,
        data: body
      })

      const successCode =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.code

      if (successCode === 'USER_ONBOARDING_OK_2') {
        const authResponse = await authenticate(email, password, domain.domain)
        // salvo i dati dell'utente nel local storage
        ls.set('user', authResponse.data.data.user)
        ls.set('token', authResponse.data.data.token)
        // aggiorno i dati sull'organizzazione e sui permessi
        await updateOrganizationACL()
        // cambio location
        window.location.pathname = '/'
      }
    } catch (error) {
      const errorCode =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code
      changeStatusCode(errorCode)
      console.log(errorCode)
    }
  }

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()

    console.log(event.target.checked)

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    console.log(errors)

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <Container>
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.field}>
          <TextField
            error={hasError('firstName')}
            fullWidth
            type='text'
            helperText={
              hasError('firstName') ? formState.errors.firstName[0] : null
            }
            label={t('inputs.firstName')}
            name='firstName'
            required
            onChange={handleChange}
            value={formState.values.firstName || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('lastName')}
            fullWidth
            type='text'
            helperText={
              hasError('lastName') ? formState.errors.lastName[0] : null
            }
            label={t('inputs.lastName')}
            name='lastName'
            required
            onChange={handleChange}
            value={formState.values.lastName || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('country')}
            fullWidth
            type='text'
            helperText={
              hasError('country') ? formState.errors.country[0] : null
            }
            label={t('inputs.country')}
            name='country'
            required
            onChange={handleChange}
            value={formState.values.country || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('companyType')}
            fullWidth
            type='text'
            helperText={
              hasError('companyType') ? formState.errors.companyType[0] : null
            }
            label={t('inputs.companyType')}
            name='companyType'
            required
            onChange={handleChange}
            value={formState.values.companyType || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('password')}
            fullWidth
            type='password'
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            label={t('inputs.password')}
            name='password'
            required
            onChange={handleChange}
            value={formState.values.password || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('confirmPassword')}
            fullWidth
            type='password'
            helperText={
              hasError('confirmPassword')
                ? formState.errors.confirmPassword[0]
                : null
            }
            label={t('inputs.confirmPassword')}
            name='confirmPassword'
            required
            onChange={handleChange}
            value={formState.values.confirmPassword || ''}
          />
        </div>
        <div>
          <div className={classes.policy}>
            <Checkbox
              checked={formState.values.acceptTerms || false}
              className={classes.policyCheckbox}
              color='primary'
              required
              name='acceptTerms'
              onChange={handleChange}
            />
            <Typography color='textSecondary' variant='body1'>
              {t('inputs.policyText')}{' '}
              <Link
                className='iubenda-nostyle iubenda-embed'
                color='primary'
                href='https://www.iubenda.com/terms-and-conditions/32624074'
                underline='always'
                variant='body1'
              >
                {t('inputs.policyLink')}
              </Link>
            </Typography>
          </div>
          {hasError('acceptTerms') && (
            <FormHelperText error>
              {formState.errors.acceptTerms[0]}
            </FormHelperText>
          )}
        </div>
        <div className={classes.field}>
          <Box display='flex' alignItems='center'>
            <Button
              className={
                !formState.isValid
                  ? classes.submitButton
                  : clsx(classes.submitButton, classes.primaryGradient)
              }
              color='primary'
              disabled={!formState.isValid}
              size='large'
              type='submit'
              variant='contained'
              endIcon={<ExitToAppIcon />}
            >
              {t('buttons.confirm')}
            </Button>
          </Box>
        </div>
      </form>
    </Container>
  )
}

export default CompleteForm

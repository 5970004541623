/* eslint-disable no-unused-vars*/
import React, { useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'

import { Box, Container, Grid, Typography } from '@material-ui/core'
import { useEmbededUrl } from './integrationUtils'

function Products(props) {
  const { isLoading, embededUrl, reloadResource } = useEmbededUrl({
    // pageUuid: selectedMiniApp?.id,
    pageUuid: '3b7d5782-c058-11ee-a969-df70b9facfb1',
    groupIdsArray: [1891387, 1877059]
  })
  const { t } = useTranslation('manualIntegration')

  const location = useLocation()
  // const userData = ls.get('user')
  useEffect(() => {
    reloadResource()
  }, [location.search])
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (embededUrl && !e._isManualIntegrationConfirmed) {
        const message = t('leaveMessage')
        e.returnValue = message
        return message
      }
    }
    const handlePopstate = () => {
      if (embededUrl) {
        const isConfirmed = window.confirm(t('leaveMessage'))
        if (!isConfirmed) {
          window.history.pushState(null, null, window.location.href)
        }
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.addEventListener('popstate', handlePopstate)
    }
  }, [embededUrl, t])

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={false}>
          {isLoading ? (
            <Box width='100%' align='center'>
              <LogoLoader size='large' text={t('loading')} />
              {/* <CircularProgress variant='indeterminate' size={70} thickness={4} /> */}
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                spacing={3}
                item
                xs={12}
              >
                <Grid item>
                  <Typography color='textPrimary' variant='h5'>
                    {t('title')}
                  </Typography>
                  <Typography color='textSecondary' variant='subtitle2'>
                    {t('description')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {embededUrl ? (
                  <Box sx={{ minHeight: 400 }}>
                    {/* <Retool url={embededUrl} /> */}
                    <iframe
                      src={embededUrl}
                      width='100%'
                      height={350}
                      style={{ border: '0px' }}
                    />
                  </Box>
                ) : (
                  <Box
                    width='100%'
                    height='100%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  )
}

export default Products

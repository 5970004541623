import React from 'react'
import { useParams } from 'react-router-dom'

// import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  List,
  ListItem,
  DialogTitle,
  ListItemAvatar,
  Avatar,
  ListItemText
} from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language'
import { blue } from '@material-ui/core/colors'
function TranslationForm(props) {
  const { uuid: referenceId } = useParams()
  const {
    onClose,
    onCloseWithSave,
    selectedValue,
    open,
    languages,
    referenceType
  } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = async (value) => {
    const translation = {
      referenceId,
      languageId: value.uuid,
      referenceType
    }

    onCloseWithSave(value, translation)
  }
  console.log('Mia rpod')
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <List sx={{ pt: 0 }}>
        {languages
          .sort((a, b) => a.fullName.localeCompare(b.fullName))
          .map((language) => (
            <ListItem
              button
              onClick={() => handleListItemClick(language)}
              key={language.uuid}
            >
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: blue[100], color: blue[600] }}
                >
                  <LanguageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={language.fullName} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  )
}

TranslationForm.propTypes = {}
export default TranslationForm

/* eslint-disable no-unused-vars*/

import React, { useEffect } from 'react'
/* import MaterialTable from 'material-table' */
import { useTranslation } from 'react-i18next'
import CustomTable from './CustomTable.js'
function LotsTable({
  selectedProductId,
  lotsMobileColumns,
  lotsColumns,
  lots,
  pageSize,
  frontEndUrl,
  isSmall,
  openLotModal,
  location,
  history,
  setSnackbar,
  page,
  offset,
  searchedLots,
  handlePageChange,
  searchLotsInTable,
  lastSearchCriteriaForTable,
  clearSearchBar,
  totalLotsrecords
}) {
  const { t } = useTranslation('lots')
  // title
  const [title, setTitle] = React.useState('')

  useEffect(() => {
    if (lots.lenght === 0) {
      setTitle(t('lotTable.titleNoProduct'))
    } else {
      if (!selectedProductId) {
        setTitle(t('lotTable.titleLastAddedLots'))
      } else {
        setTitle(t('lotTable.title'))
      }
    }
  }, [])

  return (
    <>
      <CustomTable
        title={title}
        lots={lots}
        lotsColumns={lotsColumns}
        lotsMobileColumns={lotsMobileColumns}
        pageSize={pageSize}
        t={t}
        selectedProductId={selectedProductId}
        isSmall={isSmall}
        openLotModal={openLotModal}
        setSnackbar={setSnackbar}
        location={location}
        history={history}
        frontEndUrl={frontEndUrl}
        page={page}
        offset={offset}
        searchedLots={searchedLots}
        handlePageChange={handlePageChange}
        searchLotsInTable={searchLotsInTable}
        lastSearchCriteriaForTable={lastSearchCriteriaForTable}
        clearSearchBar={clearSearchBar}
        totalLotsrecords={totalLotsrecords}
      />
      {/* <MaterialTable
        title={title}
        localization={{
          toolbar: t('lotTable.localization.toolbar', { returnObjects: true }),
          body: {
            emptyDataSourceMessage:
              selectedProductId !== ''
                ? t('lotTable.localization.body.emptyDataSourceMessage')
                : t('lotTable.localization.body.emptyDataSourceMessage2')
          },
          pagination: t('lotTable.localization.pagination', {
            returnObjects: true
          }),
          header: t('lotTable.localization.header', { returnObjects: true })
        }}
        columns={isSmall ? lotsMobileColumns : lotsColumns}
        data={lots}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
          showEmptyDataSourceMessage: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0
                ? '#f9f9f9'
                : ''
          })
        }}
        onRowClick={(event, rowData) => {
          console.log('rowData =>', rowData)
          const currentLot = lots.find((lot) => {
            return lot.core.uuid === rowData.core.uuid
          })
          openLotModal(currentLot)
        }}
        actions={[
          (rowData) => ({
            icon: 'translate',
            tooltip: t('lotTable.translateLot'),
            onClick: (event, rowData) => {
              if (rowData.lotType === 'core') {
                setSnackbar(
                  'Devi prima rendere il lotto modificabile. Clicca sul lotto e segui le istruzioni',
                  'warning'
                )
                return
              }
              // TODO: sviluppare. Deve essere possibile fare la traduzione solo per il lotto definito già in marketing.
              location.pathname = `/lots/${rowData.uuid}`
              history.push(`/lots/${rowData.uuid}`)
            }
          }),
          (rowData) => ({
            icon: 'open_in_new',
            tooltip: t('lotTable.goToPage'),
            onClick: (event, rowData) => {
              // console.log('rowData => ', rowData)
              openUrl(
                `${frontEndUrl}/01/${
                  rowData.productGtin
                }/10/${encodeURIComponent(rowData.core.lotNumber)}`
              )
            }
          })
        ]}
      /> */}
    </>
  )
}

export default LotsTable

import React from 'react'
import { Box, Chip, makeStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { locale, ItLocalizedUtils } from '../../utils/utils'
import EventIcon from '@material-ui/icons/Event'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1)
  },
  chipDark: {
    color: theme.palette.primary.main
  }
}))

function ChartFilter({
  currentPeriod,
  startDate,
  endDate,
  setCurrentPeriod,
  setStartDate,
  setEndDate
}) {
  const classes = useStyles()
  const { t } = useTranslation('home')

  const periods = [
    { value: 'week', label: t('graph.periods.week') },
    { value: 'month', label: t('graph.periods.month') },
    { value: 'year', label: t('graph.periods.year') },
    { value: 'custom', label: t('graph.periods.custom') }
  ]

  return (
    <Box mt={1}>
      <Box display='flex' flexWrap='wrap'>
        {periods.map((period) => (
          <Chip
            key={period.value}
            onClick={() => setCurrentPeriod(period.value)}
            classes={
              currentPeriod === period.value
                ? {}
                : { colorPrimary: classes.chipDark }
            }
            variant={currentPeriod === period.value ? 'default' : 'outlined'}
            color='primary'
            icon={<EventIcon />}
            className={classes.chip}
            label={period.label}
          />
        ))}
      </Box>
      {currentPeriod === 'custom' ? (
        <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
          <Box display='flex' pr={1}>
            <DatePicker
              style={{ marginLeft: 16, width: '100%' }}
              margin='normal'
              label='Data inizio'
              format='dd/MM/yyyy'
              cancelLabel='Annulla'
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              style={{ marginLeft: 16, width: '100%' }}
              margin='normal'
              label='Data fine'
              format='dd/MM/yyyy'
              cancelLabel='Annulla'
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Box>
        </MuiPickersUtilsProvider>
      ) : null}
    </Box>
  )
}

export default ChartFilter

import React from 'react'
import { useEmbededUrl } from './integrationUtils'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'

const UserDefinedIngredient = () => {
  const { isLoading, embededUrl } = useEmbededUrl({
    pageUuid: 'f3c1520c-1c02-11ef-96fd-af807f77d945',
    groupIdsArray: [1891387, 1877059]
  })

  const { t } = useTranslation('manualIntegration')

  return (
    <div>
      {isLoading ? (
        <Box width='100%' align='center'>
          <LogoLoader size='large' text={t('loading')} />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid
              alignItems='center'
              container
              justifyContent='space-between'
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography color='textPrimary' variant='h5'>
                  {t('titleFreeIngredients')}
                </Typography>
                <Typography color='textSecondary' variant='subtitle2'>
                  {t('descriptionFreeIngredients')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <iframe
            src={embededUrl}
            width='100%'
            height={650}
            style={{ border: '0px' }}
          />
        </>
      )}
    </div>
  )
}

export default UserDefinedIngredient

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { TextField, Grid } from '@material-ui/core'

// import {
//   Add as AddIcon,
//   InsertDriveFile as InsertDriveFileIcon
// } from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   tabContainer: {
//     padding: theme.spacing(2)
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   mainButton: {
//     background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
//     color: 'white',
//     width: '100%'
//   },
//   input: {
//     display: 'none'
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   },
//   chipPrimary: {
//     margin: '0px 5px',
//     backgroundColor: theme.primary,
//     '&:hover, &:active, &:focus': {
//       backgroundColor: theme.primaryDark
//     }
//   }
// }))

function DescriptionComponent(props) {
  //   const classes = useStyles()
  const {
    track,
    trackIndex,
    isModifyEnabled,
    trackDescriptionChanged,
    translation
  } = props
  const { t } = useTranslation(translation)

  const [label, setLabel] = React.useState('')

  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setLabel(t('tabs.traceability.inputs.description'))
        break
      }
      case 'lotDetails': {
        setLabel(t('tabs.traceability.inputs.description'))
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid item xs={12} style={{ width: '100%' }}>
      <TextField
        InputLabelProps={{ shrink: true }}
        required
        label={label}
        type='text'
        fullWidth
        value={track.description}
        onChange={(e) => trackDescriptionChanged(e, trackIndex)}
        disabled={!isModifyEnabled}
        multiline
        maxRows='9'
        minRows='5'
      />
    </Grid>
  )
}

DescriptionComponent.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  trackDescriptionChanged: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default DescriptionComponent

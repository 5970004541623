import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { InputLabel, Select, MenuItem } from '@material-ui/core'

function SelectCompany(props) {
  const { product, companies, isModifyEnabled, coreFieldChanged, translation } =
    props
  const { t } = useTranslation(translation)

  return (
    <>
      <InputLabel shrink htmlFor='companyId'>
        {t('tabs.general.inputs.company')}
      </InputLabel>
      <Select
        id='companyId'
        name='companyId'
        value={product.core.companyId}
        onChange={coreFieldChanged}
        disabled={!isModifyEnabled}
        displayEmpty
        fullWidth
      >
        {companies.map((company) => (
          <MenuItem key={company.core.uuid} value={company.core.uuid}>
            {company.core.name}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

SelectCompany.propTypes = {
  product: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  coreFieldChanged: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default SelectCompany

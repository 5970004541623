import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { InputLabel, Grid, Select, MenuItem } from '@material-ui/core'

// import {
//   Add as AddIcon,
//   InsertDriveFile as InsertDriveFileIcon
// } from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   tabContainer: {
//     padding: theme.spacing(2)
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   mainButton: {
//     background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
//     color: 'white',
//     width: '100%'
//   },
//   input: {
//     display: 'none'
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   },
//   chipPrimary: {
//     margin: '0px 5px',
//     backgroundColor: theme.primary,
//     '&:hover, &:active, &:focus': {
//       backgroundColor: theme.primaryDark
//     }
//   }
// }))

function SelectEventTypeComponent(props) {
  //   const classes = useStyles()
  const { track, trackIndex, isModifyEnabled, eventTypeChanged, translation } =
    props
  const { t } = useTranslation(translation)

  const [input, setInput] = React.useState('')
  const [commission, setCommission] = React.useState('')
  const [transformation, setTransformation] = React.useState('')
  const [observation, setObservation] = React.useState('')
  const [observationShipping, setObservationShipping] = React.useState('')
  const [observationReceiving, setObservationReceiving] = React.useState('')

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setInput(t('tabs.traceability.inputs.eventType.label'))
        setCommission(t('tabs.traceability.inputs.eventType.values.commission'))
        setTransformation(
          t('tabs.traceability.inputs.eventType.values.transformation')
        )
        setObservation(
          t('tabs.traceability.inputs.eventType.values.observation')
        )
        setObservationShipping(
          t('tabs.traceability.inputs.eventType.values.observation_shipping')
        )
        setObservationReceiving(
          t('tabs.traceability.inputs.eventType.values.observation_receiving')
        )
        break
      }
      case 'lotDetails': {
        setInput(t('tabs.traceability.inputs.eventType.label'))
        setCommission(t('tabs.traceability.inputs.eventType.values.commission'))
        setTransformation(
          t('tabs.traceability.inputs.eventType.values.transformation')
        )
        setObservation(
          t('tabs.traceability.inputs.eventType.values.observation')
        )
        setObservationShipping(
          t('tabs.traceability.inputs.eventType.values.observation_shipping')
        )
        setObservationReceiving(
          t('tabs.traceability.inputs.eventType.values.observation_receiving')
        )
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid item xs={12} md={6}>
      <InputLabel shrink htmlFor={`event_type-${trackIndex}`}>
        {input}
      </InputLabel>
      <Select
        id={`event_type-${trackIndex}`}
        name={`event_type-${trackIndex}`}
        value={track.eventType}
        displayEmpty
        onChange={(e) => eventTypeChanged(e, trackIndex)}
        fullWidth
        disabled={!isModifyEnabled}
      >
        <MenuItem value='commission'>{commission}</MenuItem>
        <MenuItem value='transformation'>{transformation}</MenuItem>
        <MenuItem value='observation'>{observation}</MenuItem>
        <MenuItem value='observation_shipping'>{observationShipping}</MenuItem>
        <MenuItem value='observation_receiving'>
          {observationReceiving}
        </MenuItem>
      </Select>
    </Grid>
  )
}

SelectEventTypeComponent.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  eventTypeChanged: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default SelectEventTypeComponent

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { InputLabel, Select, MenuItem } from '@material-ui/core'

function SelectProductType(props) {
  const { product, typeChanged, translation, disabled } = props
  const { t } = useTranslation(translation)
  console.log('Is Modify enabled:', disabled)
  return (
    <>
      <InputLabel shrink htmlFor='type'>
        {t('tabs.general.inputs.type.label')}
      </InputLabel>
      <Select
        id='type'
        name='type'
        value={product.productType}
        onChange={typeChanged}
        displayEmpty
        fullWidth
        disabled={disabled}
      >
        <MenuItem value='rawMaterial'>
          {t('tabs.general.inputs.type.values.rawMaterial')}
        </MenuItem>
        <MenuItem value='intermediateGood'>
          {t('tabs.general.inputs.type.values.intermediateGood')}
        </MenuItem>
        <MenuItem value='finishedGood'>
          {t('tabs.general.inputs.type.values.finishedGood')}
        </MenuItem>
        <MenuItem value='core'>
          {t('tabs.general.inputs.type.values.core')}
        </MenuItem>
      </Select>
    </>
  )
}

SelectProductType.propTypes = {
  product: PropTypes.object.isRequired,
  typeChanged: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}
export default SelectProductType

import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS

export async function returnProductsCount() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(
    `${configuration.apiBaseUrl}/core/projects/${currOrganization.uuid}/products/count`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// utility che fa la sanificazione dell'oggetto del prodotto in base alle sue caratteristiche
export function sanitizeProduct(product) {
  // pulisco gli attributi
  product.attributes = product.attributes.filter(
    (attr) => attr.trait_type !== '' && attr.value !== ''
  )
  // productInformation.social = this.state.social
  // pulisco la tracciabilità
  product.traceability = product.traceability.filter(
    (attr) => attr.name !== '' && attr.description !== ''
  )
  product.traceability.forEach((t, index) => {
    if (t.eventType !== '' && t.eventType !== 'transformation') {
      delete t.outputs
    }

    if (
      t.eventType === 'observation_shipping' ||
      t.eventType === 'observation_receiving'
    ) {
      delete t.location
    } else {
      delete t.location_from
      delete t.location_to
    }

    if (!t.traceId) {
      t.traceId = index
    }
  })

  // pulisco la tracciabilità se non è un prodotto finito
  if (product.type !== 'finishedGood') {
    product.traceability = []
    delete product.website
    delete product.profileImage
    delete product.backgroundImage
    delete product.allergenes
  } else {
    // pulisco le immagini base
    // se l'immagine è vuota mostro il placeholder
    if (!product.profileImage.original) {
      product.profileImage.original = 'placeholder'
    }

    if (!product.profileImage.cropped) {
      product.profileImage.cropped = 'placeholder'
    }
    if (!product.backgroundImage.original) {
      product.backgroundImage.original = 'placeholder'
    }
    if (!product.backgroundImage.cropped) {
      product.backgroundImage.cropped = 'placeholder'
    }

    // controllo se c'è il sito
    if (!product.website) {
      delete product.website
    }
  }

  // pulisco i link dei file
  product.traceability.forEach((track) => {
    track.media.forEach((m) => {
      if (!m.original) {
        m.original = 'placeholder'
      }
      if (!m.cropped) {
        m.cropped = 'placeholder'
      }
    })

    track.documents.forEach((d) => {
      if (!d.fileUrl) {
        d.fileUrl = 'placeholder'
      }
    })
  })
  console.log('product to be created => ', product)

  return product
}

// funzione che crea una nuovo prodotto
export async function createDefaultProduct(product) {
  // if (!product || !product.companyId || !product.type) {
  //   return
  // }

  const response = await axios.post(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/products/`,
    product,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}
// esport query per i prodotti
// dovrebbero, di costruzione, essere già filtrati tutti i prodotti deleted
export async function returnAllProducts() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/products/`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: {
        limit: Number.MAX_SAFE_INTEGER
      }
    }
  )
}

export async function returnAllUserDefinedIngredients() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/userDefinedIngredients`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: {
        limit: Number.MAX_SAFE_INTEGER
      }
    }
  )
}

export async function returnAllIngredients() {
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/public/schema/new_ingredients`,
    {
      headers: {
        'x-api-key': `${configuration.xApiKey}`
      }
    }
  )
}
// esport query per avere alcuni prodotti
export async function returnSomeProducts(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllProducts()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/products`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}

// esport query per un prodotto tramite uuid
export async function returnProductByUuid(uuid) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!uuid) {
    return
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/products/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// esport query per un prodotto tramite uuid
export async function returnProductByUuidFromCore(uuid) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!uuid) {
    return
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/core/projects/${currOrganization.uuid}/products/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// funzione che crea una nuovo prodotto
export async function returnNewProduct(newProduct) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è il prodotto
  if (!newProduct) {
    return
  }
  console.log('BEFORE QUERY newProduct => ', newProduct)
  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/products/`,
    newProduct,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// funzione che modifica un prodotto
export async function editProduct(product) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!product) {
    return
  }

  let uuid
  if (product.resourceId) {
    uuid = product.uuid
  } else {
    uuid = product.core.uuid
  }
  console.log('BEFORE QUERY edit product => ', product)
  // mando la query
  const response = await axios.put(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/products/${uuid}`,
    product,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
  // l'istanza di membership supplychain viene creato solo sui prodotti finiti

  return response
}

export async function editUrl(uuid, data) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda

  // mando la query
  const response = await axios.put(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/products/${uuid}/updateUrl`,
    data,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
  // l'istanza di membership supplychain viene creato solo sui prodotti finiti

  return response
}

export async function deleteProduct(product) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!product) {
    return
  }

  let uuid
  if (product.resourceId) {
    uuid = product.uuid
  } else {
    uuid = product.core.uuid
  }
  console.log('BEFORE QUERY delete product => ', product)
  // mando la query
  return axios.delete(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/products/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      data: {
        type: 'product'
      }
    }
  )
}

// funzione che duplica un prodotto
export async function duplicateProduct(uuid) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda

  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/products/duplicate`,
    { uuid },
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

export async function returnAllProductsProjection(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/products`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}

/* eslint-disable react/no-deprecated*/

import axios from 'axios'
import ls from 'local-storage'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { parseDomain } from 'parse-domain'
import './index.css'
// import i18n config and component
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'

import configuration from './configuration'

let guidedLoaded = false

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof error.response === 'undefined') {
      return Promise.reject(new Error('No response'))
    }

    if (error.response.status === 401) {
      if (
        error.response.data &&
        Object.keys(error.response.data).length &&
        error.response.data.error &&
        Object.keys(error.response.data.error).length &&
        error.response.data.error.code === 'INVALID_OLD_PASSWORD'
      ) {
        // console.log('Error Response Data:', error.response.data)
        // do nothing
      } else {
        ls.clear()
        return window.location.reload()
      }
    }

    return Promise.reject(error)
  }
)

if (['production', 'test'].includes(configuration.environment)) {
  console.warn = function () {}
  Sentry.init({
    dsn: configuration.sentry.dsn,
    environment: configuration.environment
  })
  new Promise((resolve) => {
    const user = ls.get('user')
    if (user) {
      return resolve(user)
    }

    const onUser = (user) => {
      ls.off('user', onUser)
      resolve(user)
    }

    ls.on('user', onUser)
  }).then((user) => {
    axios
      .get('https://json.geoiplookup.io')
      .then((response) => {
        const networkInfo = response.data
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.uuid,
            username: user.fullName,
            email: user.email,
            ip_address: networkInfo.ip
          })
          Object.keys(networkInfo)
            .filter(
              (key) =>
                ![
                  'asn',
                  'continent_code',
                  'country_code',
                  'currency_code',
                  'ip',
                  'org',
                  'success'
                ].includes(key)
            )
            .forEach((key) => {
              scope.setExtra(key, networkInfo[key])
            })
        })
      })
      .catch(() => {})
  })
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/* in order to create the guiding process in case the domain is trusty */
if (parseDomain(window.location.host).type === 'LISTED') {
  if (parseDomain(window.location.host).domain === 'trusty') {
    guidedLoaded = false
    const title = document.getElementById('title-app')
    if (
      parseDomain(window.location.host).subDomains &&
      parseDomain(window.location.host).subDomains.length > 1
    ) {
      title.innerHTML = `${capitalize(
        parseDomain(window.location.host).subDomains[1]
      )} - Dashboard`
    } else {
      title.innerHTML = `${capitalize(
        parseDomain(window.location.host).domain
      )} - Dashboard`
    }
  } else {
    const title = document.getElementById('title-app')
    title.innerHTML = `${capitalize(
      parseDomain(window.location.host).domain
    )} - Dashboard`
  }
}
// } else {
//   guidedLoaded = true
// }

ReactDOM.render(
  <App guidedLoaded={guidedLoaded} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

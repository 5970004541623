import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import { useTranslation } from 'react-i18next'
import { FormControlLabel } from '@material-ui/core'

export default function CustomizedSwitchesIsClickable(props) {
  const { t } = useTranslation('productDetails')
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: props.checked !== false
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
    props.handleChange(event.target.checked)
  }

  return (
    <FormGroup>
      <FormControlLabel
        disabled={props.disabled}
        control={
          <Switch
            onChange={handleChange}
            checked={state.checkedB}
            name='checkedB'
          />
        }
        label={
          state.checkedB
            ? t('tabs.others.selectOption.yes')
            : t('tabs.others.selectOption.no')
        }
      />
    </FormGroup>
  )
}

import React from 'react'
import {
  Container,
  Box,
  Typography,
  withStyles,
  withWidth
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

const styles = (theme) => ({})
class NotFound extends React.Component {
  constructor(props) {
    super(props)
    if (window.userGuiding) {
      window.userGuiding.hideChecklist()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.bodySection}>
        <Container>
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Typography className={classes.h1Text} variant='h3' component='h1'>
              {this.props.t('domainError')}
            </Typography>
          </Box>
        </Container>
      </div>
    )
  }
}

export default withRouter(
  withStyles(styles)(withWidth()(withTranslation('notFound')(NotFound)))
)

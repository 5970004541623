import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddComponent from './AddComponent'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function TitleWithAddButton(props) {
  // const classes = useStyles()
  const { trackIndex, addComponent, isModifyEnabled, translation, position } =
    props

  const { t } = useTranslation(translation)
  const [title, setTitle] = React.useState('')

  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        if (position === 'LocationFrom') {
          setTitle(t('tabs.traceability.inputs.locationFromTitle'))
        } else if (position === 'LocationTo') {
          setTitle(t('tabs.traceability.inputs.locationToTitle'))
        } else if (position === 'Inputs') {
          setTitle(t('tabs.traceability.inputs.materialInputs.title'))
        } else if (position === 'Outputs') {
          setTitle(t('tabs.traceability.inputs.materialOutputs.title'))
        }
        break
      }
      case 'lotDetails': {
        if (position === 'LocationFrom') {
          setTitle(t('tabs.traceability.inputs.locationFromTitle'))
        } else if (position === 'LocationTo') {
          setTitle(t('tabs.traceability.inputs.locationToTitle'))
        } else if (position === 'Inputs') {
          setTitle(t('tabs.traceability.inputs.materialInputs.title'))
        } else if (position === 'Outputs') {
          setTitle(t('tabs.traceability.inputs.materialOutputs.title'))
        }
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='flex-start'
      spacing={2}
    >
      <Grid item md={6} xs={12}>
        <Typography variant='h6' style={{ marginRight: '10px' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <AddComponent
          trackIndex={trackIndex}
          addComponent={addComponent}
          isModifyEnabled={isModifyEnabled}
          translation={translation}
          position={position}
        ></AddComponent>
      </Grid>
    </Grid>
  )
}

TitleWithAddButton.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  addComponent: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired,
  position: PropTypes.string
}
export default TitleWithAddButton

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  TextField,
  withStyles,
  withWidth,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Box,
  Paper,
  Chip,
  Fab
  // Fab
} from '@material-ui/core'

import { isThisDomainIsIwine } from '../../utils/acl-organization.js'

import MaterialTable from 'material-table'

// import AddIcon from '@material-ui/icons/Add'
import {
  Lock,
  LockOpen,
  Close as CloseIcon,
  Delete as DeleteIcon,
  InsertDriveFile,
  Add as AddIcon
} from '@material-ui/icons'

// import axios from 'axios'
import ls from 'local-storage'
import AccountsService from '../../components/Accounts.service'

// import configuration from '../../configuration.js'
import { withTranslation } from 'react-i18next'
import { editCompany, editUrl, deleteCompany } from '../../utils/companies'
import {
  deepCloneObj,
  imageToBlobAndUrl,
  generateUuid,
  loadFile,
  checkFileSize,
  urlTobase64
} from '../../utils/utils'
import SocialInput from '../SocialInput/SocialInput.component'
import Map from '../Map/Map.component'
import ImageUploader from '../ImageUploader/ImageUploader.component'

const styles = (theme) => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    overflow: 'scroll'
  },
  progressStyle: {
    color: theme.primary
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 'auto',
    outline: 0
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  navBar: {
    color: 'white'
  },
  tableContainer: {
    marginTop: 16
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  paperDialog: {
    maxHeight: '70vh'
  },
  imgInput: {
    display: 'none'
  },
  imgCover: {
    // width: 'auto',
    // height: '250px',
    margin: '10px',
    width: 400,
    height: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  modifyImgButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  textfieldPrimary: {
    color: theme.primary
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '700px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModal extends React.Component {
  constructor(props) {
    super(props)

    this.user = ls.get('user') || {}

    const company = deepCloneObj(this.props.company)

    if (!company.ecommerce) {
      company.ecommerce = ''
    }
    if (!company.email) {
      company.email = ''
    }
    if (!company.website) {
      company.website = ''
    }
    if (
      !company.social ||
      Object.keys(company.social).length === 0 ||
      (Object.keys(company.social).length === 1 && company.social._id)
    ) {
      company.social = {
        facebook: '',
        instagram: '',
        linkedin: '',
        pinterest: '',
        tiktok: '',
        twitter: '',
        youtube: ''
      }
    }
    if (!company.geolocation) {
      company.geolocation = {
        lat: 41.896187,
        lng: 12.492046
      }
    }

    if (!company.geolocation.address) {
      company.geolocation.address = {
        country: '',
        city: '',
        postalCode: '',
        street: ''
      }
    }
    if (!company.certifications) {
      company.certifications = []
    }

    this.state = {
      filesToLoad: [],
      logosToLoad: [],
      showModify: false,
      isSavingUrl: false,
      imgScale: 1.0,
      canIAddAnotherAttribute: true,
      isConfirmDialogOpened: false,
      isSaveDialogOpen: false,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      tabNumber: 0,
      mapError: false,
      modified: false,
      saveFromClose: false,
      open: props.open,
      isWineDomain: isThisDomainIsIwine(),
      company: {
        ...company
      }
    }

    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.savePosition = this.savePosition.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deleteCompany = this.deleteCompany.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.fieldChanged = this.fieldChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.saveModify = this.saveModify.bind(this)
    this.saveNewFile = this.saveNewFile.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.addressChanged = this.addressChanged.bind(this)
    this.socialChanged = this.socialChanged.bind(this)
    this.geoChanged = this.geoChanged.bind(this)
    this.addField = this.addField.bind(this)
    this.attributeNameChanged = this.attributeNameChanged.bind(this)
    this.attributeValueChanged = this.attributeValueChanged.bind(this)
    this.changeLogo = this.changeLogo.bind(this)
    this.removeField = this.removeField.bind(this)
    this.confirmCrop = this.confirmCrop.bind(this)
    this.cancelCrop = this.cancelCrop.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.getKeyByValue = this.getKeyByValue.bind(this)
    this.coreFieldChanged = this.coreFieldChanged.bind(this)
  }

  /* controlli spostati nel costruttrore */
  // async componentDidMount () {
  // }

  /*
  componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        const company = Object.assign({}, this.props.company)
        // controllo se ha tutti i valori necessari altrimenti correggo
        if (!company.ecommerce) {
          company.ecommerce = ''
        }
        if (!company.email) {
          company.email = ''
        }
        if (!company.website) {
          company.website = ''
        }
        if (!company.social || Object.keys(company.social).length === 0 || (Object.keys(company.social).length === 1 && company.social._id)) {
          company.social = {
            facebook: '',
            instagram: '',
            linkedin: '',
            pinterest: '',
            tiktok: '',
            twitter: '',
            youtube: ''
          }
        }

        /* if (!company.attributes || company.attributes.length === 0) {
          company.attributes = [
            {
              name: '',
              value: ''
            }
          ]
        } */
  /* if (!company.documents || company.documents.length === 0) {
          company.documents = [
            {
              name: '',
              type: 'other', // award, certification, other
              description: '',
              websiteUrl: '',
              fileUrl: '',
              imageUrl: ''
            }
          ]
        } */
  /* if (!company.certifications || company.certifications.length === 0) {
          company.certifications = [
            {
              name: '',
              num: '',
              authority: '',
              description: '',
              websiteUrl: '',
              documents: [
                {
                  name: '',
                  type: 'certification',
                  description: '',
                  websiteUrl: '',
                  fileUrl: '',
                  imageUrl: ''
                }
              ]
            }
          ]
        } */
  /* if (!company.awards || company.awards.length === 0) {
          company.awards = [
            {
              name: '',
              num: '',
              authority: '',
              description: '',
              websiteUrl: '',
              documents: [
                {
                  name: '',
                  type: 'award',
                  description: '',
                  websiteUrl: '',
                  fileUrl: '',
                  imageUrl: ''
                }
              ]
            }
          ]
        } */

  // Valori di default per aziende generate dalla registrazione autonoma
  /*
        if (!company.geolocation) {
          company.geolocation = {
            lat: 41.896187,
            lng: 12.492046
          }
        }

        if (!company.address) {
          company.address = {
            country: '',
            city: '',
            postalCode: '',
            street: ''
          }
        }

        /* if (!company.geolocation || !Object.prototype.hasOwnProperty.call(company.geolocation, 'lat')) {
          company.geolocation.lat = 41.896187
        }
        if (!company.geolocation || !Object.prototype.hasOwnProperty.call(company.geolocation, 'lng')) {
          company.geolocation.lng = 12.492046
        } */
  /*
        const currCompany = Object.assign({}, company)
        console.log('currCompany => ', currCompany)

        this.setState({ company })
      }

      this.setState({ open: this.props.open })
    }
  }
*/

  closeConfirmDialog() {
    this.setState({ isConfirmDialogOpened: false })
  }

  closeSaveDialog() {
    this.setState({ isSaveDialogOpen: false })
  }

  async confirmSave() {
    try {
      await this.saveModify()
      this.setState({ tabNumber: 0, isModifyEnabled: false })
      if (this.state.saveFromClose) {
        this.setState({
          tabNumber: 0,
          open: false,
          isDeleting: false,
          isModifing: false,
          isModifyEnabled: false,
          isConfirmDialogOpened: false
        })
      }
      this.closeSaveDialog()
      await this.props.onCloseModal()
    } catch (e) {
      if (e.message === 'generalData') {
        this.closeSaveDialog()
        this.setState({ tabNumber: 0, isModifyEnabled: false })
        return this.props.setSnackbar(
          "Impossibile modificare: Completa l'inserimento di tutti i campi obbligatori",
          'error'
        )
      } else if (e.message === 'personalData') {
        this.closeSaveDialog()
        this.setState({ tabNumber: 2, isModifyEnabled: false })
        return this.props.setSnackbar(
          "Impossibile modificare: Completa l'inserimento di tutti i campi obbligatori",
          'error'
        )
      } else if (e.message === 'coordsData') {
        this.closeSaveDialog()
        this.setState({ tabNumber: 1, isModifyEnabled: false })
        return this.props.setSnackbar(
          "Impossibile modificare: Completa l'inserimento di tutti i campi obbligatori",
          'error'
        )
      } else {
        this.closeSaveDialog()
        this.setState({ isModifyEnabled: false })
        return this.props.setSnackbar(
          "Impossibile modificare l'azienda",
          'error'
        )
      }
    }
  }

  changeTab(event, tabNumber) {
    this.setState({ tabNumber })
  }

  async confirmClose() {
    this.setState({
      isModifyEnabled: false,
      open: false,
      isSavingUrl: false,
      isDeleting: false,
      isModifing: false,
      isConfirmDialogOpened: false
    })
    this.closeSaveDialog()
    await this.props.onCloseModal()
  }

  closeModal() {
    if (this.state.isModifyEnabled || this.state.modified) {
      this.setState({
        isSaveDialogOpen: true,
        saveFromClose: true,
        modified: false
      })
    } else {
      this.setState({
        open: false,
        isDeleting: false,
        isSavingUrl: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      this.props.onCloseModal()
    }
  }

  async deleteCompany() {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })
    // const updateObject = Object.keys(this.state.company).reduce((acc, k) => {
    //   acc[k] = this.state.company[k]
    //   return acc
    // }, {})
    const updateObject = deepCloneObj(this.state.company)

    delete updateObject._id
    updateObject.updatedAt = Date.now()

    // controllo website e ecommerce
    if (updateObject.website === '') {
      delete updateObject.website
    }
    if (updateObject.email === '') {
      delete updateObject.email
    }
    if (updateObject.ecommerce === '') {
      delete updateObject.ecommerce
    }

    try {
      await deleteCompany(updateObject)

      this.setState({ isDeleting: false })
      this.setState({
        open: false,
        isDeleting: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      await this.props.onCloseModal()
    } catch (e) {
      console.log('Unable to delete company: ', e)
      this.props.setSnackbar(
        this.props.t('notifications.errDeletingCompany'),
        'error'
      )
    }
  }

  enableOrDisableModify() {
    if (this.state.isModifyEnabled === true) {
      this.setState({
        isModifyEnabled: !this.state.isModifyEnabled,
        mapError: false,
        modified: true
      })
    } else {
      this.setState({
        isModifyEnabled: !this.state.isModifyEnabled,
        mapError: false
      })
    }
  }

  fieldChanged = ({ target }) => {
    const company = this.state.company
    company[target.name] = target.value
    this.setState({ company })
  }

  coreFieldChanged = ({ target }) => {
    const company = this.state.company
    company.core[target.name] = target.value
    this.setState({ company })
  }

  /* Potential instagram account validator */
  instagramValidator = (account) => {
    const pattern =
      /(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/g
    const isCorrect = account.match(pattern)
    return isCorrect
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
    // return values.map(value => Object.keys(object).find(key => object[key] === value))
  }

  socialChanged = ({ target }) => {
    const company = this.state.company
    // if (target.name == 'instagram') {
    //   console.log("siamo qua nell'instagram")
    //   const instagramProfile = this.instagramValidator(target.value)
    //   if (instagramProfile == null) {
    //     this.setState({ isCorrectInstagram: false })
    //   } else {
    //     console.log("sono nell' else, instagram validator è andato true")
    //     this.setState({ isCorrectInstagram: true })
    //   }
    // }
    company.social[target.name] = target.value
    this.setState({ company })
  }

  addressChanged = ({ target }) => {
    const company = this.state.company
    company.geolocation.address[target.name] = target.value
    this.setState({ company })
  }

  geoChanged = ({ target }) => {
    const company = this.state.company
    company.geolocation[target.name] = target.value
    this.setState({ company })
  }

  openConfirmDialog() {
    this.setState({ isConfirmDialogOpened: true })
  }

  async saveModify() {
    if (this.state.isModifing) {
      return
    }

    const company = deepCloneObj(this.state.company)
    console.log('save edits, company => ', company)
    // const { name, companyId, address, houseNumber, city, description } = this.state.company
    // const { cap, partitaIva, codiceFiscale, lat, lng } = this.state.company.additionalData

    // if (!(company && company.logo && company.logo.cropped && company.logo.original && (company.logo.cropped !== '' || company.logo.original !== ''))) {
    //   throw new Error('generalData')
    // }

    if (
      company.core.name === '' ||
      company.core.gs1 === '' ||
      company.geolocation.address.street === '' ||
      company.description === '' ||
      company.geolocation.address.country === '' ||
      company.geolocation.address.city === '' ||
      company.geolocation.address.postalCode === ''
    ) {
      throw new Error('generalData')
    }

    if (company.core.vatId === '' || company.core.taxId === '') {
      throw new Error('personalData')
    }

    if (company.geolocation.lat === '' || company.geolocation.lng === '') {
      throw new Error('coordsData')
    }

    if (company.logo && Object.keys(company.logo).length) {
      if (company.logo.original?.match(/^data:image\/[^;]+/)) {
        return this.props.setSnackbar(
          this.props.t('notifications.errEditingCompanyImageProblem'),
          'error'
        )
      }
      if (company.logo.cropped?.match(/^data:image\/[^;]+/)) {
        return this.props.setSnackbar(
          this.props.t('notifications.errEditingCompanyImageProblem'),
          'error'
        )
      }
    }

    this.setState({ isModifing: true })
    // const updateObject = Object.keys(company).reduce((acc, k) => {
    //   acc[k] = company[k]
    //   return acc
    // }, {})

    delete company._id
    company.updatedAt = Date.now()

    // controllo website, email e ecommerce
    // if (!company.website) {
    //   console.log('cancello la property website')
    //   delete company.website
    // }
    if (company.email === '') {
      delete company.email
    }
    // if (company.ecommerce === '') {
    //   delete company.ecommerce
    // }

    try {
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedCertifications = Object.assign([], company.certifications)
      // console.log('filesToLoad => ', filesToLoad)

      for (const file of filesToLoad) {
        const { blob, name, index, order, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `companies/${this.state.company.core.uuid}/${fileUuid}.${extention}`
        if (section === 'certification' && type === 'document') {
          const url = await loadFile(blob, filename, company.projectId)
          updatedCertifications[index].documents[order].fileUrl = url
          file.loaded = true
        }
      }

      company.certifications = updatedCertifications
      const companyToSend = JSON.parse(JSON.stringify(company))

      await editCompany(companyToSend)
      // const company = this.state.company
      if (!company.website) {
        company.website = ''
      }
      if (!company.email) {
        company.email = ''
      }
      if (!company.ecommerce) {
        company.ecommerce = ''
      }
      // aggiorno la mia variaible
      this.props.setSnackbar(
        this.props.t('notifications.okEditingCompany'),
        'success'
      )
      /* facendo in questo modo non si rompe il componente della multiple select in quanto l'array di
      supplychainsId rimane caricato in company e ciò che deve essere sostituito, come veniva fatto precedentemente,
      viene sostituito
      */
      this.setState({
        company: {
          ...this.state.company,
          company
        },
        isModifing: false,
        filesToLoad: []
      })
    } catch (e) {
      console.log('Unable to modify company: ', e)
      this.props.setSnackbar(
        this.props.t('notifications.errEditingCompany'),
        'error'
      )
    }
  }

  async openMedia(url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
      <head>
        <title>Trusty | Documenti</title>
      </head>
      <body style="margin: 0px;">
        <iframe width="100%" height="100%" src="${file}"></iframe>
      </body>
    </html>
    `)
    } catch (e) {
      this.props.setSnackbar(this.props.t('notifications.docNoView'), 'error')
    }
  }

  async saveNewFile() {
    if (this.state.isSavingUrl) {
      return
    }

    this.setState({ isModifyEnabled: false, isSavingUrl: true })

    this.props.setSnackbar(this.props.t('notifications.imgLoading'), 'warning')
    // NOTE: questo sotto non mi conviene farlo in quanto in questo modo mi andrei a salvare anche le modifiche pendenti
    const updateObject = deepCloneObj(this.state.company)

    console.log('product to save : ', updateObject)
    const logosToLoad = Object.assign([], this.state.logosToLoad)
    const updatedLogo = Object.assign({}, updateObject.logo)

    const data = {}
    data.logo = {}
    for (const file of logosToLoad) {
      const { blob, name, section, type } = file
      const fileUuid = generateUuid()
      const extention = name.slice(name.lastIndexOf('.') + 1)
      const filename = `companies/${this.state.company.core.uuid}/${fileUuid}.${extention}`
      if (section === 'logo' && type === 'original') {
        const url = await loadFile(blob, filename, updateObject.projectId)
        updatedLogo.original = url
        file.loaded = true
        data.logo = {
          original: url,
          cropped: url
        }
      } else if (section === 'logo' && type === 'cropped') {
        const url = await loadFile(blob, filename, updateObject.projectId)
        updatedLogo.cropped = url
        file.loaded = true
        data.logo = {
          original: updatedLogo.original,
          cropped: url,
          croppingRect: updateObject.logo.croppingRect
        }
      }
      data.attachmentType = 'logo'
    }
    updateObject.logo = updatedLogo
    const updateCompanyResponse = await editUrl(updateObject.uuid, data)
    const updateCompany = updateCompanyResponse.data.data
    console.log('updateCompany:::', updateCompany)
    this.setState({
      company: {
        ...this.state.company,
        logo: updateObject.logo
      },
      logosToLoad: [],
      isModifyEnabled: true,
      isSavingUrl: false
    })
  }

  async changeLogo({ target }) {
    const company = this.state.company

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (
        !(
          Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') &&
          Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') &&
          Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name')
        )
      ) {
        return this.props.setSnackbar(
          this.props.t('notifications.imgLoadingError'),
          'error'
        )
      }

      const logosToLoad = Object.assign([], this.state.logosToLoad)
      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'logo',
        type: 'original',
        loaded: false,
        name
      }
      logosToLoad.push(singleFile)

      if (!Object.prototype.hasOwnProperty.call(company, 'logo')) {
        company.logo = {
          originalImage: '',
          croppedImage: '',
          croppingRect: {}
        }
      }
      company.logo.original = url
      // this.setState({ company, showModify: true }, async () => await this.saveNewFile(singleFile))
      this.setState({ logosToLoad, company, showModify: true })
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(
          this.props.t('notifications.imgLoadingTooLarge'),
          'error'
        )
      } else if (e.message === 'error') {
        this.props.setSnackbar(
          this.props.t('notifications.imgLoadingError'),
          'error'
        )
      }
    }
  }

  returnImg(logo) {
    if (!logo) {
      return './images/img-placeholder.png'
    }
    return logo
  }

  addField(index) {
    const company = this.state.company
    if (!Object.prototype.hasOwnProperty.call(company, 'additionalData')) {
      company.additionalData = {}
    }

    if (!Array.isArray(company.additionalData.productions)) {
      company.additionalData.productions = []
    }

    const productions = company.additionalData.productions
    if (
      productions[index] &&
      (!productions[index].trait_type || !productions[index].value)
    ) {
      return this.setState({ canIAddAnotherAttribute: false })
    }

    productions.push({
      trait_type: '',
      value: ''
    })

    this.setState({ company, canIAddAnotherAttribute: true })
  }

  // funzione che cambia il 'name' degli attributi
  attributeNameChanged({ target }, index) {
    const company = this.state.company
    company.attributes[index].name = target.value
    this.setState({ company })
  }

  // funzione che cambia il 'value' degli attributi
  attributeValueChanged({ target }, index) {
    const company = this.state.company
    company.attributes[index].value = target.value
    this.setState({ company })
  }

  // funzione che rimuove un attributo
  removeField(index) {
    const company = this.state.company
    // const attributes = company.attributes
    company.attributes.splice(index, 1)

    this.setState({ company, canIAddAnotherAttribute: true })
  }

  // funzione che salva la nuova posizione dell'azienda
  savePosition(e) {
    const { lat, lng } = e.latlng
    const company = this.state.company
    if (!Object.prototype.hasOwnProperty.call(company, 'geolocation')) {
      company.geolocation = {}
    }

    company.geolocation.lat = lat
    company.geolocation.lng = lng

    this.setState({ company })
  }

  // Funzione che esce dall'editor di modifica e resetta l'immagine vuota
  cancelCrop() {
    const { company } = this.state
    company.logo.original = this.returnImg()
    company.logo.cropped = ''
    this.setState({ company, showModify: false })
  }

  // async addGeneralDocuments ({ target }, rowData) {
  //   const company = deepCloneObj(this.state.company)
  //   /*  fixare lenght opf undefined  */
  //   const documents = company.documents
  //   console.log('documents::::', documents)

  //   const fileName = target.files[0].name

  //   if (checkFileSize(target.files[0])) {
  //     try {
  //       const filesToLoad = Object.assign([], this.state.filesToLoad)

  //       const blob = target.files[0]

  //       const fileContent = await new Promise((resolve, reject) => {
  //         const reader = new FileReader()
  //         reader.onload = () => {
  //           resolve(reader.result)
  //         }

  //         reader.onerror = reject

  //         reader.readAsDataURL(target.files[0])
  //       })

  //       const singleFile = {
  //         blob,
  //         section: 'document',
  //         type: rowData.type,
  //         index: rowData.tableData.id,
  //         order: rowData.tableData.id,
  //         loaded: false,
  //         name: fileName
  //       }

  //       documents[rowData.tableData.id].fileUrl = fileContent
  //       filesToLoad.push(singleFile)
  //       console.log('documents', documents)
  //       this.setState({
  //         company: {
  //           ...this.state.company,
  //           documents: documents
  //         },
  //         filesToLoad
  //       }, () => {
  //         console.log('company', this.state.company)
  //       })
  //     } catch (e) {
  //       if (e.message === 'error') {
  //         this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
  //       }
  //     }
  //   } else {
  //     target.value = ''
  //     this.props.setSnackbar(this.props.t('notifications.docTooBig'), 'error')
  //   }
  // }

  // deleteGeneralDoc (rowData) {
  //   const company = this.state.company
  //   const filesToLoad = Object.assign([], this.state.filesToLoad)
  //   const filesToLoadIndex = filesToLoad.findIndex((file) => file.order === rowData.tableData.id && file.type === rowData.type  && file.index === rowData.tableData.id && file.section=== 'document')
  //   if (filesToLoadIndex > -1) {
  //     filesToLoad.splice(filesToLoadIndex, 1)
  //   }
  //   filesToLoad
  //     .filter((file, index) => (file.section === 'document'  && file.index === certificationIndex && documentIndex <= file.order))
  //     .forEach(file => {
  //       // console.log('doc in filest to load', file)
  //       file.order = file.order - 1
  //     })

  //   company.certifications[certificationIndex].documents.splice(documentIndex, 1)
  //   company.certifications[certificationIndex].documents
  //     .filter((doc, index) => documentIndex <= index)
  //     .forEach(doc => {
  //       // console.log('doc', doc)
  //       doc.order = doc.order - 1
  //     })

  //   this.setState({ company, filesToLoad: filesToLoad })
  // }
  // Function for add certifications documents
  async addCertificationDocument({ target }, index) {
    const company = deepCloneObj(this.state.company)
    /*  fixare lenght opf undefined  */
    const certifications = company.certifications
    console.log('certifications::::', certifications)

    if (
      certifications.length > 0 &&
      certifications[index] &&
      certifications[index].documents === undefined
    ) {
      certifications[index].documents = []
    }
    // const documents = company.certifications[index] && company.certifications[index].documents && company.certifications[index].documents.length > 0 ? company.certifications[index].documents : []

    const candidateMaxOrder = certifications[index].documents.length - 1
    const maxOrder = candidateMaxOrder

    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)

        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(target.files[0])
        })

        const singleFile = {
          blob,
          section: 'certification',
          type: 'document',
          index,
          order: maxOrder + 1,
          loaded: false,
          name: fileName
        }

        certifications[index].documents.push({
          fileUrl: fileContent,
          order: maxOrder + 1,
          name: fileName,
          type: 'certification',
          description: ''
        })
        filesToLoad.push(singleFile)
        console.log('documents', certifications[index].documents)
        this.setState(
          {
            company: {
              ...this.state.company,
              certifications: company.certifications
            },
            filesToLoad
          },
          () => {
            console.log('company', this.state.company)
          }
        )
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(
            this.props.t('notifications.docError'),
            'error'
          )
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooBig'), 'error')
    }
  }

  deleteCertificationDoc(certificationIndex, documentIndex) {
    const company = this.state.company
    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const filesToLoadIndex = filesToLoad.findIndex(
      (file) =>
        file.order === documentIndex &&
        file.type === 'document' &&
        file.index === certificationIndex
    )
    if (filesToLoadIndex > -1) {
      filesToLoad.splice(filesToLoadIndex, 1)
    }
    filesToLoad
      .filter(
        (file, index) =>
          file.type === 'document' &&
          file.index === certificationIndex &&
          documentIndex <= file.order
      )
      .forEach((file) => {
        // console.log('doc in filest to load', file)
        file.order = file.order - 1
      })

    company.certifications[certificationIndex].documents.splice(
      documentIndex,
      1
    )
    company.certifications[certificationIndex].documents
      .filter((doc, index) => documentIndex <= index)
      .forEach((doc) => {
        // console.log('doc', doc)
        doc.order = doc.order - 1
      })

    this.setState({ company, filesToLoad: filesToLoad })
  }

  // Funzione che conferma la modifica applicata alla foto
  confirmCrop(result) {
    const { company } = this.state

    const { imgObj, croppedFile } = result

    const logosToLoad = Object.assign([], this.state.logosToLoad)
    const name = `cropped.${croppedFile.type.slice(
      croppedFile.type.lastIndexOf('/') + 1
    )}`

    const singleFile = {
      blob: croppedFile,
      section: 'logo',
      type: 'cropped',
      loaded: false,
      name
    }

    logosToLoad.push(singleFile)

    imgObj.original = company.logo.original
    company.logo = imgObj

    this.setState(
      { logosToLoad, company, showModify: false },
      async () => await this.saveNewFile()
    )
  }

  isLatitude = (str) => {
    const latitudeRegex = /^(-?([1-8]?[0-9](\.\d+)?|90(\.0+)?))$/
    return latitudeRegex.test(str)
  }

  isLongitude = (str) => {
    const longitudeRegex =
      /^(-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?))$/
    return longitudeRegex.test(str)
  }

  render() {
    const { classes } = this.props
    if (this.props.open !== true) {
      return <div></div>
    }
    return (
      <React.Fragment>
        <Dialog
          className='companyModal'
          open={this.state.isConfirmDialogOpened}
          aria-labelledby='Comfirm delete'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.deleteCompany.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.deleteCompany.description')}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ textPrimary: classes.tabText }}
              onClick={this.deleteCompany}
              color='primary'
            >
              {this.state.isDeleting ? (
                <LogoLoader size='small'></LogoLoader>
              ) : (
                this.props.t('dialog.deleteCompany.buttons.confirm')
              )}
            </Button>
            <Button
              classes={{ textPrimary: classes.tabText }}
              onClick={this.closeConfirmDialog}
              color='secondary'
              autoFocus
            >
              {this.props.t('dialog.deleteCompany.buttons.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog confirm saved changes */}
        <Dialog
          className='companyModal'
          open={this.state.isSaveDialogOpen}
          aria-labelledby='Comfirm save'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.saveEdits.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.saveEdits.description')}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color='primary'>
              {this.props.t('dialog.saveEdits.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color='primary' autoFocus>
              {this.props.t('dialog.saveEdits.buttons.closeWithoutSave')}
            </Button>
            <Button
              classes={{ textPrimary: classes.tabText }}
              onClick={this.confirmSave}
              color='secondary'
              disabled={!!this.state.isModifing}
            >
              {this.state.isModifing ? (
                <LogoLoader size='small'></LogoLoader>
              ) : (
                this.props.t('dialog.saveEdits.buttons.closeAndSave')
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='companyModal'
          style={
            this.props.width === 'md' || this.props.width === 'lg'
              ? { position: 'absolute', top: 0, bottom: 'auto' }
              : {}
          }
          scroll='paper'
          classes={
            this.props.width === 'lg' || this.props.width === 'md'
              ? { root: classes.paperRootMiddleSize }
              : { root: classes.paperRoot }
          }
          PaperProps={{ square: true }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          {this.props.width === 'sm' || this.props.width === 'xs' ? (
            <DialogTitle
              classes={{ root: classes.titleMobile }}
              disableTypography={true}
              className={classes.navBar}
            >
              <Typography
                style={{ width: '95%' }}
                noWrap={true}
                variant='h6'
                component='h2'
              >
                {this.props.t('title')}
              </Typography>
              <IconButton
                disabled={this.state.isSavingUrl}
                style={{ justifySelf: 'flex-end', width: '5%' }}
                edge='end'
                color='inherit'
                aria-label='close modal'
                onClick={this.closeModal}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          ) : (
            <DialogTitle className={classes.navBar}>
              {this.props.t('title')}
              <Box
                display='flex'
                justifyContent='space-evenly'
                alignItems='center'
                className={classes.closeButton}
                style={{ gap: '5px' }}
              >
                {this.state.isModifyEnabled && (
                  <Button
                    variant='outlined'
                    title='disabilita modifiche'
                    onClick={this.enableOrDisableModify}
                    color='inherit'
                    aria-label='disallow modify'
                    style={{ color: 'black' }}
                  >
                    {this.props.t('buttons.disableEdit')}
                  </Button>
                )}
                {!this.state.isModifyEnabled && (
                  <Button
                    variant='outlined'
                    title='abilita modifiche'
                    disabled={this.state.isSavingUrl}
                    onClick={this.enableOrDisableModify}
                    color='inherit'
                    aria-label='allow modify'
                    style={{ color: 'black' }}
                  >
                    {this.props.t('buttons.enableEdit')}
                  </Button>
                )}

                <Button
                  variant='outlined'
                  disabled={this.state.isSavingUrl}
                  onClick={this.openConfirmDialog}
                  aria-label='disallow modify'
                  color='inherit'
                  style={{ color: 'black' }}
                >
                  {this.props.t('buttons.deleteCompany')}
                </Button>
                <Button
                  variant='outlined'
                  disabled={this.state.isSavingUrl}
                  edge='end'
                  color='inherit'
                  aria-label='close modal'
                  style={{ color: 'black' }}
                  onClick={this.closeModal}
                >
                  {this.props.t('buttons.close')}
                </Button>
              </Box>
            </DialogTitle>
          )}
          <Paper elevation={4} position='static' square>
            <Tabs
              classes={{ indicator: classes.tabIndicator }}
              value={this.state.tabNumber}
              onChange={this.changeTab}
              indicatorColor='primary'
              variant='scrollable'
              scrollButtons='on'
            >
              <Tab
                classes={{ selected: classes.tabText }}
                label={this.props.t('tabs.info.title')}
              />
              <Tab
                classes={{ selected: classes.tabText }}
                label={this.props.t('tabs.map.title')}
              />
              <Tab
                classes={{ selected: classes.tabText }}
                label={this.props.t('tabs.registry.title')}
              />
              <Tab
                style={this.state.isWineDomain ? { display: 'none' } : {}}
                classes={{ selected: classes.tabText }}
                label={this.props.t('tabs.certifications.title')}
              />
              {/* <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.certifications.title')} /> */}
              {/* <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.production.title')} /> */}
            </Tabs>
          </Paper>
          <DialogContent
            classes={
              this.props.width === 'sm' || this.props.width === 'xs'
                ? {}
                : { root: classes.paperDialog }
            }
          >
            {/* NUOVE INFO GENERALI */}
            <div
              hidden={this.state.tabNumber !== 0}
              className={`${classes.tabContainer} tabContent`}
              id='simple-tabpanel-0'
              aria-labelledby='info generali'
            >
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item xs={12} md={5}>
                  <Grid
                    container
                    direction='column'
                    justify='flex-start'
                    alignItems='center'
                  >
                    <Grid item style={{ width: '100%' }}>
                      <Typography variant='body2' component='p'>
                        {this.props.t('tabs.info.logo')}*
                      </Typography>
                    </Grid>
                    <ImageUploader
                      onCancel={this.cancelCrop}
                      onCrop={this.confirmCrop}
                      onChange={(e) => this.changeLogo(e)}
                      active={this.state.isModifyEnabled}
                      showModify={this.state.showModify}
                      company={this.state.company}
                    />
                    {!this.state.isWineDomain && (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: 'center', marginTop: '20px' }}
                      >
                        <Grid
                          container
                          direction='row'
                          justify='center'
                          alignItems='center'
                          spacing={2}
                        >
                          <Grid item xs>
                            <SocialInput
                              onChange={this.socialChanged}
                              value={this.state.company.social.facebook}
                              disabled={!this.state.isModifyEnabled}
                              social='facebook'
                              placeholder={this.props.t(
                                'tabs.info.inputs.placeholderFacebook'
                              )}
                            />
                          </Grid>
                          <Grid item xs>
                            <SocialInput
                              onChange={this.socialChanged}
                              value={this.state.company.social.twitter}
                              disabled={!this.state.isModifyEnabled}
                              social='twitter'
                              placeholder={this.props.t(
                                'tabs.info.inputs.placeholderTwitter'
                              )}
                            />
                          </Grid>
                          <Grid item xs>
                            <SocialInput
                              onChange={this.socialChanged}
                              value={this.state.company.social.linkedin}
                              disabled={!this.state.isModifyEnabled}
                              social='linkedin'
                              placeholder={this.props.t(
                                'tabs.info.inputs.placeholderLinkedin'
                              )}
                            />
                          </Grid>
                          <Grid item xs>
                            <SocialInput
                              onChange={this.socialChanged}
                              value={this.state.company.social.instagram}
                              disabled={!this.state.isModifyEnabled}
                              social='instagram'
                              placeholder={this.props.t(
                                'tabs.info.inputs.placeholderInstagram'
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Grid
                    container
                    direction='column'
                    justify='flex-start'
                    alignItems='center'
                  >
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='name'
                            name='name'
                            label={this.props.t('tabs.info.inputs.name')}
                            type='text'
                            fullWidth
                            required
                            value={this.state.company.core.name}
                            onChange={this.coreFieldChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              style: classes.tabIndex
                            }}
                            id='gs1'
                            name='gs1'
                            label={this.props.t('tabs.info.inputs.gs1')}
                            type='text'
                            fullWidth
                            required
                            value={this.state.company.core.gs1}
                            onChange={this.coreFieldChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='street'
                            name='street'
                            label={this.props.t('tabs.info.inputs.street')}
                            type='text'
                            required
                            fullWidth
                            value={
                              this.state.company.geolocation.address.street
                            }
                            onChange={this.addressChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='postalCode'
                            name='postalCode'
                            label={this.props.t('tabs.info.inputs.postalCode')}
                            type='text'
                            required
                            fullWidth
                            value={
                              this.state.company.geolocation.address.postalCode
                            }
                            onChange={this.addressChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='country'
                            name='country'
                            label={this.props.t('tabs.info.inputs.country')}
                            type='text'
                            required
                            fullWidth
                            value={
                              this.state.company.geolocation.address.country
                            }
                            onChange={this.addressChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='city'
                            name='city'
                            label={this.props.t('tabs.info.inputs.city')}
                            type='text'
                            required
                            fullWidth
                            value={this.state.company.geolocation.address.city}
                            onChange={this.addressChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='state'
                            name='state'
                            label={this.props.t('tabs.info.inputs.state')}
                            type='text'
                            fullWidth
                            value={this.state.company.geolocation.address.state}
                            onChange={this.addressChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='email'
                            name='email'
                            label={this.props.t('tabs.info.inputs.email')}
                            type='text'
                            fullWidth
                            value={this.state.company.email}
                            onChange={this.fieldChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='site'
                            name='website'
                            label={this.props.t('tabs.info.inputs.site')}
                            type='text'
                            placeholder='ex: https://www.trusty.id'
                            fullWidth
                            value={this.state.company.website}
                            onChange={this.fieldChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                        {!this.state.isWineDomain && (
                          <Grid item xs={12} md={6}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id='ecommerce'
                              name='ecommerce'
                              label={this.props.t('tabs.info.inputs.ecommerce')}
                              type='text'
                              fullWidth
                              value={this.state.company.ecommerce}
                              onChange={this.fieldChanged}
                              margin='normal'
                              disabled={!this.state.isModifyEnabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='description'
                            multiline
                            minRows='2'
                            maxRows='12'
                            name='description'
                            label={this.props.t('tabs.info.inputs.description')}
                            type='text'
                            required
                            fullWidth
                            value={this.state.company.description}
                            onChange={this.fieldChanged}
                            margin='normal'
                            disabled={!this.state.isModifyEnabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            {/* MAPPA */}
            <div
              hidden={this.state.tabNumber !== 1}
              id='simple-tabpanel-1'
              aria-labelledby='Mappa'
              className={classes.tabContainer}
            >
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    direction='column'
                    justify='flex-start'
                    alignItems='flex-start'
                  >
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='lat'
                        name='lat'
                        label={this.props.t('tabs.map.inputs.lat')}
                        type='text'
                        required
                        fullWidth
                        value={this.state.company.geolocation.lat}
                        onChange={this.geoChanged}
                        margin='normal'
                        error={
                          this.state.company.geolocation.lat !== '' &&
                          !this.isLatitude(this.state.company.geolocation.lat)
                        }
                        helperText={
                          this.state.company.geolocation.lat !== '' &&
                          !this.isLatitude(
                            this.state.company.geolocation.lat
                          ) &&
                          this.props.t('tabs.map.inputs.latError')
                        }
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <div>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          id='lng'
                          name='lng'
                          label={this.props.t('tabs.map.inputs.lng')}
                          type='text'
                          required
                          fullWidth
                          value={this.state.company.geolocation.lng}
                          onChange={this.geoChanged}
                          margin='normal'
                          error={
                            this.state.company.geolocation.lng !== '' &&
                            !this.isLongitude(
                              this.state.company.geolocation.lng
                            )
                          }
                          helperText={
                            this.state.company.geolocation.lng !== '' &&
                            !this.isLongitude(
                              this.state.company.geolocation.lng
                            ) &&
                            this.props.t('tabs.map.inputs.lngError')
                          }
                          disabled={!this.state.isModifyEnabled}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Map
                    onLocationFound={
                      this.state.isModifyEnabled
                        ? this.savePosition
                        : () =>
                            this.props.setSnackbar(
                              this.props.t('notifications.mapWarning'),
                              'warning'
                            )
                    }
                    lat={parseFloat(this.state.company.geolocation.lat) || null}
                    lng={parseFloat(this.state.company.geolocation.lng) || null}
                  />
                </Grid>
              </Grid>
            </div>

            {/* ANAGRAFICA */}
            <div
              hidden={this.state.tabNumber !== 2}
              id='simple-tabpanel-0'
              aria-labelledby='Anagrafica'
              className={classes.tabContainer}
            >
              <Typography variant='body1'>
                {this.props.t('tabs.registry.subtitle')} &quot;
                {this.state.company.core.name}&quot;.
              </Typography>
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id='vatId'
                    name='vatId'
                    label={this.props.t('tabs.registry.inputs.vatId')}
                    type='text'
                    required
                    fullWidth
                    value={this.state.company.core.vatId}
                    onChange={this.coreFieldChanged}
                    margin='normal'
                    disabled={!this.state.isModifyEnabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id='taxId'
                    name='taxId'
                    label={this.props.t('tabs.registry.inputs.taxId')}
                    type='text'
                    required
                    fullWidth
                    value={this.state.company.core.taxId}
                    onChange={this.coreFieldChanged}
                    margin='normal'
                    disabled={!this.state.isModifyEnabled}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id='phoneNumber'
                    name='phoneNumber'
                    label={this.props.t('tabs.registry.inputs.phone')}
                    type='text'
                    fullWidth
                    value={this.state.company.phoneNumber}
                    onChange={this.fieldChanged}
                    margin='normal'
                    disabled={!this.state.isModifyEnabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id='mobileNumber'
                    name='mobileNumber'
                    label={this.props.t('tabs.registry.inputs.cellphone')}
                    type='text'
                    fullWidth
                    value={this.state.company.mobileNumber}
                    onChange={this.fieldChanged}
                    margin='normal'
                    disabled={!this.state.isModifyEnabled}
                  />
                </Grid>
              </Grid>
              {/* <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField InputLabelProps={{ shrink: true }} id="foundationYear" name="foundationYear" label="Anno di fondazione" type="number" fullWidth value={this.state.company.additionalData.foundationYear}
                    onChange={this.additionalDataChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField InputLabelProps={{ shrink: true }} id="surface" name="surface" label="Superficie aziendale (ettari)" type="text" fullWidth value={this.state.company.additionalData.surface}
                    onChange={this.additionalDataChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                </Grid>
              </Grid> */}
            </div>

            {/* CERTIFICAZIONI */}
            <div
              hidden={this.state.tabNumber !== 3}
              id='simple-tabpanel-0'
              aria-labelledby='Certificazioni'
              className={classes.tabContainer}
            >
              {this.state.company.certifications && (
                <MaterialTable
                  columns={[
                    {
                      title: this.props.t('tabs.certifications.columns.name'),
                      field: 'name'
                    },
                    {
                      title: this.props.t(
                        'tabs.certifications.columns.description'
                      ),
                      field: 'description'
                    },
                    {
                      title: this.props.t(
                        'tabs.certifications.columns.authority'
                      ),
                      field: 'authority'
                    },
                    {
                      title: this.props.t('tabs.certifications.columns.num'),
                      field: 'num'
                    }
                  ]}
                  data={this.state.company.certifications}
                  title={this.props.t('tabs.certifications.title')}
                  localization={this.props.t(
                    'tabs.certifications.localization',
                    { returnObjects: true }
                  )}
                  options={{
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false
                  }}
                  icons={{
                    Add: () => (
                      <Button variant='contained' color='primary'>
                        {this.props.t(
                          'tabs.certifications.localization.body.addCertificationButton'
                        )}
                      </Button>
                    )
                  }}
                  editable={
                    !this.state.isModifyEnabled
                      ? {}
                      : {
                          onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                {
                                  const company = deepCloneObj(
                                    this.state.company
                                  )
                                  const data = company.certifications
                                    ? company.certifications
                                    : []
                                  data.push(newData)
                                  company.certifications = data
                                  this.setState({
                                    company: {
                                      ...this.state.company,
                                      certifications: company.certifications
                                    }
                                  })
                                }
                                resolve()
                              }, 1000)
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                {
                                  const company = deepCloneObj(
                                    this.state.company
                                  )
                                  const data = company.certifications
                                    ? company.certifications
                                    : []
                                  const index = data.findIndex(
                                    (x) =>
                                      x.tableData.id === oldData.tableData.id
                                  )
                                  // const index = data.indexOf(oldData)
                                  data[index] = newData
                                  company.certifications = data
                                  this.setState({
                                    company: {
                                      ...this.state.company,
                                      certifications: company.certifications
                                    }
                                  })
                                }
                                resolve()
                              }, 1000)
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                {
                                  const company = deepCloneObj(
                                    this.state.company
                                  )
                                  const data = company.certifications
                                    ? company.certifications
                                    : []
                                  const index = data.findIndex(
                                    (x) =>
                                      x.tableData.id === oldData.tableData.id
                                  )
                                  data.splice(index, 1)
                                  company.certifications = data
                                  this.setState({
                                    company: {
                                      ...this.state.company,
                                      certifications: company.certifications
                                    }
                                  })
                                }
                                resolve()
                              }, 1000)
                            })
                        }
                  }
                  detailPanel={(rowData) => {
                    return (
                      <div className={classes.tabContainer}>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={6}>
                            <Typography
                              style={{ marginBottom: '20px' }}
                              variant='h5'
                              component='h5'
                            >
                              {rowData.name}
                            </Typography>
                            <Typography variant='body2' component='span'>
                              {rowData.description}
                            </Typography>
                            <Typography
                              style={{ marginTop: '20px' }}
                              variant='body2'
                              component='p'
                            >
                              {rowData.authority}
                            </Typography>
                            <Typography variant='body2' component='p'>
                              {rowData.num}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={5}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap'
                            }}
                          >
                            {rowData.documents &&
                              rowData.documents.map((document, docIndex) => (
                                <Chip
                                  title={
                                    document.name ||
                                    this.props.t(
                                      'tabs.certifications.docDetails.docPlaceholder'
                                    )
                                  }
                                  style={{ margin: '4px 8px' }}
                                  size='small'
                                  key={`doc-${docIndex}`}
                                  icon={<InsertDriveFile />}
                                  classes={{
                                    colorPrimary: classes.chipPrimary
                                  }}
                                  color='primary'
                                  label={
                                    (
                                      <span
                                        style={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          maxWidth: '100px'
                                        }}
                                      >
                                        {document.name}
                                      </span>
                                    ) ||
                                    this.props.t(
                                      'tabs.certifications.docDetails.docPlaceholder'
                                    )
                                  }
                                  onClick={() =>
                                    this.openMedia(document.fileUrl)
                                  }
                                  onDelete={() =>
                                    this.state.isModifyEnabled
                                      ? this.deleteCertificationDoc(
                                          rowData.tableData.id,
                                          docIndex
                                        )
                                      : ''
                                  }
                                />
                              ))}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={1}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <input
                              className={classes.input}
                              id={`add-new-certification-${rowData.tableData.id}`}
                              type='file'
                              accept='image/png, image/jpeg, application/pdf'
                              multiple
                              onChange={(e) =>
                                this.addCertificationDocument(
                                  e,
                                  rowData.tableData.id
                                )
                              }
                              style={{ display: 'none' }}
                              disabled={!this.state.isModifyEnabled}
                            />
                            <label
                              htmlFor={`add-new-certification-${rowData.tableData.id}`}
                            >
                              <Fab
                                classes={{ primary: classes.fabPrimary }}
                                size='small'
                                component='span'
                                color='primary'
                                aria-label={this.props.t(
                                  'tabs.certifications.docDetails.addDocument'
                                )}
                                disabled={!this.state.isModifyEnabled}
                              >
                                <AddIcon />
                              </Fab>
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  }}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
              )}
            </div>

            {/* DOCUMENTI */}
            {/* <div component="div" hidden={this.state.tabNumber !== 4} id="simple-tabpanel-0" aria-labelledby="Documenti"
              className={classes.tabContainer}>
              {this.state.company.documents && (
                <MaterialTable
                  columns={[
                    { title: this.props.t('tabs.documents.columns.type'), field: 'type' },
                    { title: this.props.t('tabs.documents.columns.name'), field: 'name' },
                    { title: this.props.t('tabs.documents.columns.description'), field: 'description' },
                    { title: this.props.t('tabs.documents.columns.website'), field: 'website' }
                  ]}
                  data={this.state.company.documents}
                  title={this.props.t('tabs.documents.title')}
                  localization={ this.props.t('tabs.documents.localization', { returnObjects: true }) }
                  options={{
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false
                  }}
                  editable={!this.state.isModifyEnabled ? {} : {
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const company = deepCloneObj(this.state.company)
                            const data = company.documents ? company.documents : []
                            data.push(newData)
                            company.documents = data
                            this.setState({
                              company: {
                                ...this.state.company,
                                documents: company.documents
                              }
                            })
                          }
                          resolve()
                        }, 1000)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const company = deepCloneObj(this.state.company)
                            const data = company.documents ? company.documents : []
                            const index = data.indexOf(oldData)
                            data[index] = newData
                            company.documents = data
                            this.setState({
                              company: {
                                ...this.state.company,
                                documents: company.documents
                              }
                            })
                          }
                          resolve()
                        }, 1000)
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const company = deepCloneObj(this.state.company)
                            const data = company.documents ? company.documents : []
                            const index = data.indexOf(oldData)
                            data.splice(index, 1)
                            company.documents = data
                            this.setState({
                              company: {
                                ...this.state.company,
                                documents: company.documents
                              }
                            })
                          }
                          resolve()
                        }, 1000)
                      })
                  }}
                  detailPanel={(rowData) => {
                    return (
                      <div className={classes.tabContainer}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography style={{ marginBottom: '20px' }} variant="h5" component="h5">
                              {rowData.type}
                            </Typography>
                            <Typography variant="body2" component="span">{rowData.name}</Typography>
                            <Typography style={{ marginTop: '20px' }} variant="body2" component="p">
                              {rowData.description}
                            </Typography>
                            <Typography variant="body2" component="p">{rowData.website}</Typography>
                          </Grid>
                          <Grid item xs={10} md={5} style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                            {rowData.documents && <Chip
                              title={rowData.name || this.props.t('tabs.documents.docDetails.docPlaceholder')}
                              style={{ margin: '4px 8px' }}
                              size="small"
                              key={`doc-${rowData.tableData.id}`}
                              icon={<InsertDriveFile />}
                              classes={{ colorPrimary: classes.chipPrimary }}
                              color="primary"
                              label={<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100px' }}>{document.name}</span> || this.props.t('tabs.documents.docDetails.docPlaceholder')}
                              onClick={() => this.openMedia(rowData.fileUrl)}
                              onDelete={() => this.state.isModifyEnabled ? this.deleteCertificationDoc(rowData.tableData.id, docIndex) : ''}
                            />)}
                          </Grid>
                          <Grid item xs={2} md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <input className={classes.input} id={`add-new-certification-${rowData.tableData.id}`} type="file" accept="image/png, image/jpeg, application/pdf" multiple
                              onChange={(e) => this.addGeneralDocuments(e, rowData)} style={{ display: 'none' }} disabled={!this.state.isModifyEnabled} />
                            <label htmlFor={`add-new-certification-${rowData.tableData.id}`}>
                              <Fab classes={{ primary: classes.fabPrimary }} size="small" component="span" color="primary" aria-label={this.props.t('tabs.documents.docDetails.addDocument')} disabled={!this.state.isModifyEnabled}>
                                <AddIcon />
                              </Fab>
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  }}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
              )}

            </div> */}
            {/* PRODUZIONE */}
            {/* <div component="div" hidden={this.state.tabNumber !== 3} id="simple-tabpanel-0" aria-labelledby="Produzione" className={classes.tabContainer}>
              <Typography variant="body1">{ this.props.t('tabs.production.subtitle') }</Typography>
              {this.state.company.additionalData.productions.map((prod, index, ref) => (
                <Grid container direction="row" alignItems="center" justify="center" spacing={2} key={index}>
                  <Grid item xs>
                    <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
                      <Grid item xs={12} md={6}>
                        <TextField InputLabelProps={{ shrink: true }} margin="dense" value={prod.trait_type} label="Prodotto" onChange={(e) => this.attributeNameChanged(e, index)}
                          type="text" fullWidth disabled={!this.state.isModifyEnabled} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField InputLabelProps={{ shrink: true }} margin="dense" value={prod.value} label="Quantità" onChange={(e) => this.attributeValueChanged(e, index)}
                          type="text" fullWidth disabled={!this.state.isModifyEnabled} />
                      </Grid>
                      <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherAttribute}>
                        <Typography content="p" variant="body2" fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                            Definisci questo attributo prima di proseguire
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addField(index)} hidden={index !== ref.length - 1} size="small" color="primary" aria-label="aggiungi attributo"
                      disabled={!this.state.isModifyEnabled}>
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Fab onClick={() => this.removeField(index)} disabled={this.state.company.additionalData.productions.length < 2 || !this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 8 }} aria-label="elimina produzione">
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>
              ))}
            </div> */}
          </DialogContent>
          {this.props.width === 'sm' || this.props.width === 'xs' ? (
            <DialogActions classes={{ root: classes.mobileActions }}>
              <Button
                startIcon={<DeleteIcon />}
                onClick={this.openConfirmDialog}
                color='secondary'
                aria-label='delete company'
              >
                {this.props.t('buttons.deleteCompany')}
              </Button>
              {this.state.isModifyEnabled && (
                <Button
                  startIcon={<LockOpen />}
                  classes={{ textPrimary: classes.tabText }}
                  title='disabilita modifiche'
                  onClick={this.enableOrDisableModify}
                  color='primary'
                  aria-label='disallow modify'
                >
                  {this.props.t('buttons.lockEdits')}
                </Button>
              )}
              {!this.state.isModifyEnabled && (
                <Button
                  startIcon={<Lock />}
                  classes={{ textPrimary: classes.tabText }}
                  title='abilita modifiche'
                  onClick={this.enableOrDisableModify}
                  color='primary'
                  aria-label='allow modify'
                >
                  {this.props.t('buttons.unlockEdits')}
                </Button>
              )}
            </DialogActions>
          ) : (
            ''
          )}
        </Dialog>
      </React.Fragment>
    )
  }
}

CardModal.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  company: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setSnackbar: PropTypes.func
}

export default withRouter(
  withStyles(styles)(withWidth()(withTranslation('companyDetails')(CardModal)))
)

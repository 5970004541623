import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnFacilitiesCount() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(
    `${configuration.apiBaseUrl}/core/projects/${currOrganization.uuid}/facilities/count`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: {
        filter: JSON.stringify({ status: 'active' })
      }
    }
  )
}

// esport query per i prori ACL
export async function returnAllFacilities() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/facilities/`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: {
        limit: Number.MAX_SAFE_INTEGER
      }
    }
  )
}

// esport query per avere alcuni stabilimenti
export async function returnSomeFacilities(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllFacilities()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/facilities/`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}

// funzione che crea una nuova azienda
export async function createDefaultFacility(facility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  console.log('BEFORE QUERY newFacility => ', facility)
  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/facilities/`,
    facility,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}
// funzione che crea una nuova azienda
export async function returnNewFacility(newFacility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!newFacility) {
    return
  }
  console.log('BEFORE QUERY newFacility => ', newFacility)
  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/`,
    newFacility,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// funzione che modifica un'azienda
export async function editFacility(facility) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!facility) {
    return
  }

  let uuid
  if (facility.resourceId) {
    uuid = facility.uuid
  } else {
    uuid = facility.core.uuid
  }

  // mando la query
  const response = await axios.put(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/facilities/${uuid}`,
    facility,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}

// funzione che modifica un'azienda
export async function deleteFacility(facility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!facility) {
    return
  }
  let uuid
  if (facility.resourceId) {
    uuid = facility.uuid
  } else {
    uuid = facility.core.uuid
  }
  console.log('BEFORE QUERY delete facility => ', facility)
  // mando la query
  return axios.delete(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/facilities/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      data: {
        type: 'facility'
      }
    }
  )
}

export async function returnAllFacilitiesProjection(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllFacilities()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/facilities`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}

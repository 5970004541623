import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  Divider,
  ListItem,
  List,
  Tooltip,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import translationInput from '../../../translations/translationField.json'
import CustomizedSwitches from '../../SwitchLabels/CustomizedSwitches'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
function isLanguage(language, uuid) {
  console.log('Language', language)
  console.log('uuid', uuid)
  return language.uuid === uuid
}

export default function TranslationModalLot(props) {
  const classes = useStyles()
  const { t } = useTranslation('lotDetails')
  const {
    open,
    onCloseModal,
    onDeleteTranslation,
    lot,
    languages,
    onCloseModalWithSave
  } = props
  const translationToModify = JSON.parse(JSON.stringify(props.translation))
  const [translation, setTranslation] = React.useState(translationToModify)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = React.useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
  const language = languages.find((language) =>
    isLanguage(language, translation.languageId)
  )

  const closeModal = () => {
    setIsSaveDialogOpen(true)
  }

  const deleteModal = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleChangeSwitch = (checked) => {
    let { status } = translation
    if (!checked) {
      status = 'draft'
    } else {
      status = 'active'
    }
    setTranslation({
      ...translation,
      status
    })
  }
  const returnToModal = () => {
    setIsSaveDialogOpen(false)
    setIsDeleteDialogOpen(false)
  }

  const handleAttributeFieldChanged = ({ target }, index) => {
    const attributes = translation.schema.attributes.map((attr, i) => {
      if (i === index) {
        return { ...attr, [target.name]: target.value }
      } else {
        return attr
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        attributes
      }
    })
  }

  const removeAttribute = (index) => {
    const attributes = translation.schema.attributes.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        attributes
      }
    })
  }

  return (
    <>
      <Dialog
        className='lotModal'
        open={isSaveDialogOpen}
        aria-labelledby='Comfirm save'
      >
        <DialogTitle id='responsive-dialog-title'>
          {t('translation.closeDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.closeDialog.description')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnToModal} color='primary'>
            {t('translation.closeDialog.buttons.cancel')}
          </Button>
          <Button onClick={onCloseModal} color='primary' autoFocus>
            {t('translation.closeDialog.buttons.close')}
          </Button>
          <Button
            onClick={() => onCloseModalWithSave(translation)}
            classes={{ textPrimary: classes.tabText }}
            color='secondary'
          >
            {t('translation.closeDialog.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className='lotModal'
        open={isDeleteDialogOpen}
        aria-labelledby='Confirm delete'
      >
        <DialogTitle id='responsive-dialog-title'>
          {t('translation.deleteDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.deleteDialog.description')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnToModal} color='primary'>
            {t('translation.deleteDialog.buttons.cancel')}
          </Button>
          <Button
            onClick={onDeleteTranslation}
            classes={{ textPrimary: classes.tabText }}
            color='secondary'
          >
            {t('translation.deleteDialog.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={closeModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant='h6' className={classes.title}>
              {lot.lotNumber} - {language.fullName}
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={deleteModal}
              aria-label='close'
            >
              <DeleteIcon />
            </IconButton>
            <Tooltip title={t('translation.save')}>
              <IconButton color='inherit' onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Grid item xs={12}>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
          >
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
              <Grid item md={5}>
                <List>
                  <Grid item xs={12}>
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h4' gutterBottom>
                            {t('translation.defaultLanguage')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* ATTRIBUTES - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.features.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* DEFAULT */}
                          <Grid item xs={12}>
                            {lot.attributes.map((attr, index) => (
                              <>
                                <Grid
                                  container
                                  direction='row'
                                  spacing={2}
                                  alignItems='center'
                                >
                                  <Grid item md={12}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                        style={{ color: '#00000061' }}
                                      >
                                        <Typography>
                                          {attr.name} - {attr.value}
                                        </Typography>
                                      </AccordionSummary>
                                      <Grid container direction='column'>
                                        <Grid
                                          container
                                          direction='row'
                                          spacing={2}
                                        >
                                          <Grid item xs={6} md={6}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              fullWidth
                                              multiline
                                              required
                                              label={t(
                                                'translation.modal.features.name'
                                              )}
                                              value={attr.name}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={6} md={6}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id='description'
                                              name='description'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.features.value'
                                              )}
                                              value={attr.value}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Accordion>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </List>
              </Grid>
              <Divider orientation='vertical' flexItem />
              <Grid item md={6}>
                <List>
                  <Grid item xs={12}>
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid container direction='row'>
                          <Grid item xs={6}>
                            <Typography variant='h4' gutterBottom>
                              {language.fullName}
                            </Typography>
                          </Grid>
                          <Grid
                            style={{
                              display: 'flex',
                              'justify-content': 'center',
                              'align-items': 'center'
                            }}
                            item
                            xs={6}
                          >
                            <CustomizedSwitches
                              handleChange={handleChangeSwitch}
                              checked={translation.status}
                            ></CustomizedSwitches>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* ATTRIBUTI - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {translationInput[language.code[0]].features.title}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* TRANSLATION */}
                          <Grid item xs={12}>
                            {translation.schema.attributes.map(
                              (attr, index) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={11}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                        >
                                          <Typography>
                                            {attr.name} - {attr.value}
                                          </Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.attributes.${index}.name`}
                                                name='name'
                                                fullWidth
                                                multiline
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].features.name
                                                }
                                                value={attr.name}
                                                onChange={(event) =>
                                                  handleAttributeFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id={`translation.schema.attributes.${index}.value`}
                                                name='value'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].features.value
                                                }
                                                value={attr.value}
                                                onChange={(event) =>
                                                  handleAttributeFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                    {Array.isArray(
                                      translation.schema.attributes
                                    ) &&
                                      translation.schema.attributes.length >
                                        lot.attributes.length && (
                                        <Grid item md={1}>
                                          <Button
                                            onClick={() =>
                                              removeAttribute(index)
                                            }
                                            size='small'
                                          >
                                            <ClearIcon />
                                          </Button>
                                        </Grid>
                                      )}
                                  </Grid>
                                </>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

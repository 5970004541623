import React, { useEffect, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
  useTheme,
  makeStyles,
  Paper,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container
} from '@material-ui/core'
import axios from 'axios'
import classNames from 'classnames'
import ls from 'local-storage'
import configuration from '../../configuration'

export default function OpentimestampsPage() {
  const location = useLocation()
  // uuid del lotto passato come parametro nell'url
  const { lotNum, filename } = queryString.parse(location.search)

  const theme = useTheme()

  const baseRect = {
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px'
  }

  const styles = {
    hPaddingZero: {
      padding: 'auto 0'
    },
    root: {
      background: '#3C4858!important',
      color: 'white',
      textAlign: 'center'
    },
    content: {
      backgroundColor: 'white',
      color: '#333'
    },
    digestBox: {
      border: '1px solid #fafafa',
      backgroundColor: theme.primaryDark,
      borderRadius: '4px',
      [theme.breakpoints.only('xs')]: {
        width: '90%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word'
      },
      [theme.breakpoints.only('sm')]: {
        width: '80%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word'
      }
    },
    table: {
      width: '850px',
      padding: '10px',
      [theme.breakpoints.only('xs')]: {
        width: '300px'
      },
      [theme.breakpoints.only('sm')]: {
        width: '350px'
      }
    },
    tableBody: {
      width: '100%',
      borderTop: '1px solid #e0e0e0',
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0'
    },
    label: {
      width: '230px',
      borderRight: '1px solid #e0e0e0',
      [theme.breakpoints.down('sm')]: {
        width: '30px'
      }
    },
    noPadding: {
      [theme.breakpoints.only('xs')]: {
        padding: '8px'
      }
    },
    valueBox: {
      backgroundColor: '#f7f7f7',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      width: '600px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      [theme.breakpoints.only('xs')]: {
        width: '150px'
      },
      [theme.breakpoints.only('sm')]: {
        width: '300px'
      }
    },
    attestationBox: {
      width: '600px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      [theme.breakpoints.only('xs')]: {
        width: '150px'
      },
      [theme.breakpoints.only('sm')]: {
        width: '300px'
      }
    },
    textLight: {
      fontWeight: '300'
    },
    textNormal: {
      fontWeight: '500'
    },
    highlightText: {
      color: '#2db253'
    },
    forks: {
      ...baseRect,
      borderLeftColor: '#159dfc'
    },
    verify: {
      ...baseRect,
      borderLeftColor: '#2db253'
    },
    neutral: {
      paddingLeft: '20px'
    },
    transactionId: {
      ...baseRect,
      borderLeftColor: '#9c38e1'
    },
    bitcoinBlockMerkleRoot: {
      ...baseRect,
      borderLeftColor: '#ee8f13'
    },
    pathBox: {
      borderRadius: '4px 0 0 4px',
      color: 'white',
      backgroundColor: '#646464',
      width: '30px'
    },
    wrapperBox: {
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      width: '600px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      [theme.breakpoints.only('xs')]: {
        width: '150px'
      },
      [theme.breakpoints.only('sm')]: {
        width: '300px'
      }
    },
    hashBox: {
      borderRadius: '0 4px 4px 0',
      backgroundColor: '#f7f7f7',
      width: '570px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      [theme.breakpoints.only('xs')]: {
        width: '120px'
      },
      [theme.breakpoints.only('sm')]: {
        width: '270px'
      }
    },
    legendWrapper: {
      [theme.breakpoints.only('xs')]: {
        width: '90%',
        flexWrap: 'wrap'
      }
    }
  }

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // variabili di stato del componente
  const [opentimestamps, setOpentimestamps] = useState({})
  const [lotNumber, setLotNumber] = useState('')

  // funzione che prende le informazioni relative alle operazioni di opentimestamps, partendo dal parametro 'filename' che corrisponde all'url del file ots del lotto esaminato
  const getOpentimestampsInfo = useCallback(async (filename) => {
    try {
      const opentimestampsResponse = await axios.get(
        `${configuration.apiBaseUrl}/public/opentimestamps/info?filename=${filename}`,
        {
          headers: {
            authorization: `Bearer ${ls.get('token')}`
          }
        }
      )

      return opentimestampsResponse.data.data
    } catch (err) {
      console.log(err)
    }
  }, [])

  // funzione che setta nello stato i dati per la renderizzazione della pagina
  const fetchData = useCallback(async () => {
    const currInfos = await getOpentimestampsInfo(filename)
    setLotNumber(lotNum)
    setOpentimestamps(currInfos)
  }, [getOpentimestampsInfo, setOpentimestamps, filename, lotNum])

  // funzione che genera la legenda degli eventi
  const generateEventsLegend = () => {
    const events = [
      {
        key: 'forks',
        label: 'Fork',
        color: '#159dfc'
      },
      {
        key: 'verify',
        label: 'Bitcoin Attestation',
        color: '#2db253'
      },
      {
        key: 'transactionId',
        label: 'Transactions',
        color: '#9c38e1'
      },
      {
        key: 'bitcoinBlockMerkleRoot',
        label: 'Comments',
        color: '#ee8f13'
      }
    ]

    return events.map((event, index) => (
      <Box
        display='flex'
        alignItems='center'
        flexDirection='row'
        key={`event-${index}-key`}
      >
        <Box
          m={1}
          component='div'
          style={{
            backgroundColor: event.color,
            width: '20px',
            height: '20px'
          }}
        ></Box>
        <Typography>{event.label}</Typography>
      </Box>
    ))
  }

  // funzione ricorsiva che renderizza le righe degli eventi di opentimestamps
  const renderRows = (operation, index, pathNum = null) => {
    const { key } = operation
    switch (key) {
      case 'append': {
        return (
          <TableRow key={`info-row-${pathNum || ''}-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes.neutral, classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              {pathNum !== null ? (
                <Box display='flex' className={classes.wrapperBox}>
                  <Box p={1} className={classes.pathBox}>
                    {pathNum + 1}
                  </Box>
                  <Box p={1} className={classes.hashBox}>
                    <Typography component='span' variant='body2'>
                      {operation.value[0]}
                      <Typography
                        className={classes.highlightText}
                        component='span'
                        variant='body2'
                      >
                        {operation.value[1]}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  p={1}
                  style={{ display: 'inline-block' }}
                  className={classes.valueBox}
                >
                  <Typography component='span' variant='body2'>
                    {operation.value[0]}
                    <Typography
                      className={classes.highlightText}
                      component='span'
                      variant='body2'
                    >
                      {operation.value[1]}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </TableCell>
          </TableRow>
        )
      }
      case 'sha256': {
        return (
          <TableRow key={`iinfo-row-${pathNum || ''}-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes.neutral, classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              {pathNum !== null ? (
                <Box display='flex' className={classes.wrapperBox}>
                  <Box p={1} className={classes.pathBox}>
                    {pathNum + 1}
                  </Box>
                  <Box p={1} className={classes.hashBox}>
                    <Typography component='span' variant='body2'>
                      {operation.value[0]}
                      <Typography
                        className={classes.highlightText}
                        component='span'
                        variant='body2'
                      >
                        {operation.value[1]}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  p={1}
                  style={{ display: 'inline-block' }}
                  className={classes.valueBox}
                >
                  <Typography component='span' variant='body2'>
                    {operation.value[0]}
                    <Typography
                      className={classes.highlightText}
                      component='span'
                      variant='body2'
                    >
                      {operation.value[1]}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </TableCell>
          </TableRow>
        )
      }
      case 'prepend': {
        return (
          <TableRow key={`info-row-${pathNum || ''}-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes.neutral, classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              {pathNum !== null ? (
                <Box display='flex' className={classes.wrapperBox}>
                  <Box p={1} className={classes.pathBox}>
                    {pathNum + 1}
                  </Box>
                  <Box p={1} className={classes.hashBox}>
                    <Typography component='span' variant='body2'>
                      <Typography
                        className={classes.highlightText}
                        component='span'
                        variant='body2'
                      >
                        {operation.value[0]}
                      </Typography>
                      {operation.value[1]}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  p={1}
                  style={{ display: 'inline-block' }}
                  className={classes.valueBox}
                >
                  <Typography component='span' variant='body2'>
                    <Typography
                      className={classes.highlightText}
                      component='span'
                      variant='body2'
                    >
                      {operation.value[0]}
                    </Typography>
                    {operation.value[1]}
                  </Typography>
                </Box>
              )}
            </TableCell>
          </TableRow>
        )
      }
      case 'verify': {
        return (
          <TableRow key={`info-row-${pathNum || ''}-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes[key], classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              <Box p={1} className={classes.attestationBox}>
                <Typography component='span' variant='body2'>
                  {operation.value[0]}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        )
      }
      case 'transactionId': {
        return (
          <TableRow key={`info-row-${pathNum || ''}-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes[key], classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              {pathNum !== null ? (
                <Box display='flex' className={classes.wrapperBox}>
                  <Box p={1} className={classes.pathBox}>
                    {pathNum + 1}
                  </Box>
                  <Box p={1} className={classes.hashBox}>
                    <Typography component='span' variant='body2'>
                      {operation.value[0]}
                      <Typography
                        className={classes.highlightText}
                        component='span'
                        variant='body2'
                      >
                        {operation.value[1]}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  p={1}
                  style={{ display: 'inline-block' }}
                  className={classes.valueBox}
                >
                  <Typography component='span' variant='body2'>
                    {operation.value[0]}
                    <Typography
                      className={classes.highlightText}
                      component='span'
                      variant='body2'
                    >
                      {operation.value[1]}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </TableCell>
          </TableRow>
        )
      }
      case 'bitcoinBlockMerkleRoot': {
        return (
          <TableRow key={`info-row-${pathNum || ''}-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes[key], classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              {pathNum !== null ? (
                <Box display='flex' className={classes.wrapperBox}>
                  <Box p={1} className={classes.pathBox}>
                    {pathNum + 1}
                  </Box>
                  <Box p={1} className={classes.hashBox}>
                    <Typography component='span' variant='body2'>
                      {operation.value[0]}
                      <Typography
                        className={classes.highlightText}
                        component='span'
                        variant='body2'
                      >
                        {operation.value[1]}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  p={1}
                  style={{ display: 'inline-block' }}
                  className={classes.valueBox}
                >
                  <Typography component='span' variant='body2'>
                    {operation.value[0]}
                    <Typography
                      className={classes.highlightText}
                      component='span'
                      variant='body2'
                    >
                      {operation.value[1]}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </TableCell>
          </TableRow>
        )
      }
      case 'forks': {
        const forkNode = (
          <TableRow key={`fork-row-${index}`}>
            <TableCell
              classes={{ body: classes.noPadding }}
              className={classNames(classes[key], classes.label)}
            >
              <Typography component='span' variant='body2'>
                {operation.label}
              </Typography>
            </TableCell>
            <TableCell classes={{ body: classes.noPadding }}>
              <Box p={1}>
                <Typography component='span' variant='body2'>
                  {`Forked in ${operation.value.length} paths`}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        )

        return [forkNode]
          .concat(
            operation.value.map((fork, pathIndex) =>
              fork.map((forkOp, forkIndex) =>
                renderRows(forkOp, forkIndex, pathIndex)
              )
            )
          )
          .flat()
      }
      default: {
        return null
      }
    }
  }

  // funzione che genera le righe degli eventi di opentimestamps
  const createRows = () => {
    const elements = opentimestamps && opentimestamps.info
    return elements
      .slice(1)
      .map((operation, index) => renderRows(operation, index))
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return lotNumber &&
    opentimestamps &&
    opentimestamps.info &&
    opentimestamps.info.length > 0 ? (
    <Paper style={{ marginBottom: '-48px' }} elevation={4}>
      <Box
        pt={4}
        pb={4}
        className={classes.root}
        width='100%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Box mb={4} p={1}>
          <Typography
            className={classes.textLight}
            component='p'
            variant='h5'
          >{`Processo di notarizzazione del lotto ${lotNumber}`}</Typography>
        </Box>
        <Typography className={classes.textLight} component='p' variant='body2'>
          {opentimestamps.info[0].label}
        </Typography>
        <Box mt={1} p={1} className={classes.digestBox}>
          <Typography
            className={classes.textNormal}
            component='p'
            variant='body1'
          >
            {opentimestamps.info[0].value}
          </Typography>
        </Box>
      </Box>
      <Box pt={6} width='100%' display='flex' justifyContent='center'>
        <Box
          className={classes.legendWrapper}
          display='flex'
          flexDirection='row'
          justifyContent='space-evenly'
          alignItems='center'
        >
          {generateEventsLegend()}
        </Box>
      </Box>
      <Box
        pt={4}
        pb={4}
        className={classes.content}
        display='flex'
        alignItems='flex-start'
        justifyContent='center'
      >
        <Table className={classes.table}>
          <TableBody className={classes.tableBody}>{createRows()}</TableBody>
        </Table>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100px'
        className={classes.root}
        width='100%'
      >
        <Container maxWidth='md'>
          <Box width='100%' display='flex' alignItems='center'>
            <Typography component='div' variant='caption'>
              Powered by
            </Typography>
            <Box ml={1}>
              <a
                href='https://opentimestamps.org/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  alt='opentimestamps logo'
                  src='/images/opentimestamps.png'
                />
              </a>
            </Box>
          </Box>
        </Container>
      </Box>
    </Paper>
  ) : (
    ''
  )
}

import React from 'react'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  withStyles,
  TextField,
  Box,
  Typography,
  IconButton,
  withWidth,
  Button
} from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

import PropTypes from 'prop-types'

import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import { createDefaultFacility } from '../../utils/facilities'

// import configuration from '../../configuration'

const styles = (theme) => ({
  primary: {
    color: theme.primary
  },
  warn: {
    color: 'red'
  },
  progressStyle: {
    color: '#FFFFFF'
  },
  navBar: {
    color: '#FFFFFF'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFFFFF'
  }
})

class FormDialogFacility extends React.Component {
  constructor(props) {
    super(props)

    this._isMounted = false
    this.user = ls.get('user') || {}

    this.state = {
      confirmCloseOpen: false,
      // address: '',
      // houseNumber: '',
      // city: '',
      companies: [],
      // companyId: '',
      // country: '',
      // facilityId: '',
      isCreating: false,
      facility: {
        type: 'facility'
        // name, gln, companyId
      },
      open: props.open,
      owner: this.user.uuid,
      errors: {}
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.createFacility = this.createFacility.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  closeDialog() {
    this.setState({ isCreating: false, open: false })
    this.props.onCloseDialog()
    this.props.setSnackbar(
      this.props.t('notifications.okNewFacilityCreated'),
      'success'
    )
  }

  closeDialogWithoutRefresh() {
    this.props.onCloseDialogWithoutRefresh()
  }

  closeConfirmDialog() {
    this.setState({ confirmCloseOpen: false })
  }

  openConfirmDialog() {
    this.setState({ confirmCloseOpen: true })
  }

  async createFacility() {
    if (this.state.isCreating) {
      return
    }
    this.setState({ isCreating: true })

    try {
      await createDefaultFacility(this.state.facility)
      this.closeDialog()
    } catch (e) {
      console.log('sono nel catch!!!')
      // response.errorSupplychain="Resource cannot subscribe to supplychain"
      switch (e.response.status) {
        case 400: {
          this.props.setSnackbar(
            this.props.t('notifications.warningEmpyField'),
            'error'
          )
          this.props.onCloseDialogWithoutRefresh()
          break
        }
        default: {
          this.props.setSnackbar(
            this.props.t('notifications.errorNewFacility'),
            'error'
          )
          this.props.onCloseDialogWithoutRefresh()
          break
        }
      }
    }
  }

  valueChanged({ target }) {
    const facility = this.state.facility
    facility[target.name] = target.value
    this.setState({ facility })
  }

  // funzione che ritorna true se il parametro passato è una stringa vuota, altrimenti ritorna false
  isEmpty(value) {
    return value === ''
  }

  // funzione che si occupa della validazione inline degli input
  handleBlur({ target }) {
    this.setState({ errors: { [target.name]: this.isEmpty(target.value) } })
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        {/* dialog conferma chiusura */}
        <Dialog
          className='facilityModal'
          id='confirmDialog'
          open={this.state.confirmCloseOpen}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.confirmClose.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.closeConfirmDialog}
              color='primary'
              autoFocus
            >
              {this.props.t('dialog.confirmClose.inputs.back')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={() => {
                this.closeConfirmDialog()
                this.closeDialogWithoutRefresh()
              }}
              color='secondary'
            >
              {this.props.t('dialog.confirmClose.inputs.close')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog creazione */}
        <Dialog
          className='facilityModal'
          open={this.props.open}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          onClose={this.openConfirmDialog}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle className={classes.navBar} id='form-dialog-title'>
            {this.props.t('title')}
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={this.openConfirmDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputLabelProps={{ shrink: true }}
              autoFocus
              margin='dense'
              id='name'
              name='name'
              label={this.props.t('inputs.name')}
              onChange={this.valueChanged}
              type='text'
              fullWidth
            />
            <Box
              display='flex'
              justifyContent='flex-start'
              flexDirection='column'
              alignItems='flex-start'
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                margin='dense'
                id='gln'
                name='gln'
                label={this.props.t('inputs.gln')}
                value={this.state.facility.gln}
                onChange={this.valueChanged}
                type='text'
                fullWidth
              />
              <Typography style={{ color: '#737373' }} variant='body2'>
                {this.props.t('inputs.glnDescription')}.{' '}
                <i>
                  <b>{this.props.t('inputs.glnExample')}</b>
                </i>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              display='flex'
              justifyContent='space-between'
              style={{ width: '100%' }}
            >
              <Button
                classes={{ textPrimary: classes.warn }}
                onClick={this.openConfirmDialog}
                color='primary'
              >
                {this.props.t('buttons.cancel')}
              </Button>
              <Button
                classes={{ textPrimary: classes.primary }}
                onClick={this.createFacility}
                color='primary'
              >
                {this.state.isCreating ? (
                  <LogoLoader size='small'></LogoLoader>
                ) : (
                  this.props.t('buttons.add')
                )}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

FormDialogFacility.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  setSnackbar: PropTypes.func,
  onCloseDialogWithoutRefresh: PropTypes.func
}

export default withStyles(styles)(
  withWidth()(withTranslation('newFacility')(FormDialogFacility))
)

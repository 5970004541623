import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import configuration from '../../configuration'
import validate from 'validate.js'
import { Container, TextField, Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: '#3C4858!important'
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))

function ChangePasswordForm({
  className,
  changeStatusCode,
  email,
  token,
  ...rest
}) {
  const classes = useStyles()

  const { t } = useTranslation('changePassword')

  const schema = {
    password: {
      presence: {
        allowEmpty: false,
        message: t('inputs.validations.password')
      }
    },
    confirmPassword: {
      presence: {
        allowEmpty: false,
        message: t('inputs.validation.confirmPassword')
      },
      equality: {
        attribute: 'password',
        message: t('inputs.validations.equalPassword')
      }
    }
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {}
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { password: newPassword } = formState.values
    const body = { newPassword, email, token }
    try {
      const response = await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/accounts/resetPassword`,
        data: body
      })
      const successCode = response.status
      changeStatusCode(successCode)
    } catch (e) {
      const errorCode =
        e &&
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.statusCode
      changeStatusCode(errorCode)
    }
  }

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <Container>
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.field}>
          <TextField
            error={hasError('password')}
            fullWidth
            type='password'
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            label={t('inputs.password')}
            name='password'
            required
            onChange={handleChange}
            value={formState.values.password || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('confirmPassword')}
            fullWidth
            type='password'
            helperText={
              hasError('confirmPassword')
                ? formState.errors.confirmPassword[0]
                : null
            }
            label={t('inputs.confirmPassword')}
            name='confirmPassword'
            required
            onChange={handleChange}
            value={formState.values.confirmPassword || ''}
          />
        </div>
        <div className={classes.field}>
          <Button
            className={
              !formState.isValid
                ? classes.submitButton
                : clsx(classes.submitButton, classes.primaryGradient)
            }
            color='primary'
            type='submit'
            size='large'
            variant='contained'
            disabled={!formState.isValid}
          >
            {t('buttons.success')}
          </Button>
        </div>
      </form>
    </Container>
  )
}

export default ChangePasswordForm

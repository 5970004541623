import { useState, useEffect } from 'react'
import { returnCompaniesCount } from '../../utils/companies'
import { returnFacilitiesCount } from '../../utils/facilities'
import { returnProductsCount } from '../../utils/products'
// import { returnLotsCount } from '../../utils/oldLots'
import { returnLotsCount } from '../../utils/lots'

export const useHomeStats = () => {
  const [companies, setCompanies] = useState(0)
  const [facilities, setFacilities] = useState(0)
  const [products, setProducts] = useState(0)
  const [lots, setLots] = useState(0)

  useEffect(() => {
    async function fetchAllData() {
      try {
        const companiesResponse = await returnCompaniesCount()
        const { count } = companiesResponse.data.data
        const facilitiesResponse = await returnFacilitiesCount()
        const { count: countFac } = facilitiesResponse.data.data
        const productsResponse = await returnProductsCount()
        const { count: countPro } = productsResponse.data.data
        const lotsResponse = await returnLotsCount()
        const { count: countLot } = lotsResponse.data.data
        setCompanies(count)
        setFacilities(countFac)
        setProducts(countPro)
        setLots(countLot)
      } catch (e) {
        console.log('e:::', e)
        setCompanies(0)
        setFacilities(0)
        setProducts(0)
        setLots(0)
      }
    }
    fetchAllData()
  }, [])

  return [companies, facilities, products, lots]
}

import React from 'react'
// import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
// import { useTranslation } from 'react-i18next'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'

function isLanguage(language, uuid) {
  return language.uuid === uuid
}
function TranslationCard(props) {
  const { translation, classes, languages, openTranslateModal } = props
  const currentBreakPoint = props.width
  const isSmall = currentBreakPoint === 'xs'
  const language = languages.find((language) =>
    isLanguage(language, translation.languageId)
  )

  return (
    <Grid
      key={translation.uuid}
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      xl={3}
      zeroMinWidth
      className={classes.cardContainer}
    >
      <Card
        onClick={() => openTranslateModal(translation)}
        style={{ cursor: 'pointer' }}
      >
        <div
          className='innerCard'
          style={
            isSmall
              ? {}
              : {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }
          }
        ></div>
        <div className='borderFrame'>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color='textSecondary'
              gutterBottom
            >
              {language.fullName}
            </Typography>
            <Typography variant='h5' component='div'>
              {translation.schema.name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color='textSecondary'>
              {translation.schema.description}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </Grid>
  )
}

TranslationCard.propTypes = {}
export default TranslationCard

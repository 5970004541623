import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  createTranslation,
  returnTranslations,
  modifyTranslation,
  deleteTranslation
} from '../../utils/translate'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'

import {
  Divider,
  Typography,
  Grid,
  Box,
  Paper,
  Tooltip,
  Fab,
  Snackbar,
  SnackbarContent
} from '@material-ui/core'

import {
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  // Edit as EditIcon,
  Cancel
} from '@material-ui/icons'

import { green, amber } from '@material-ui/core/colors'
import TranslationForm from '../Translation/TranslationForm.component'
import TranslationCard from '../Translation/TranslationCard.component'
import { returnProductByUuid } from '../../utils/products'
import { returnLanguages } from '../../utils/utils'
import TranslationModal from '../Translation/TranslationModal.component'
const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  input: {
    display: 'none'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  },
  success: {
    backgroundColor: green[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  }
}))

function ProductTranslation(props) {
  const { uuid } = useParams()
  const [product, setProduct] = React.useState()
  const [translation, setTranslation] = React.useState()
  const [translations, setTranslations] = React.useState([])
  const { t } = useTranslation('productDetails')
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState()
  const [isTranslateModalOpened, setIsTranslateModalOpened] =
    React.useState(false)
  const [isTranslationsLoaded, setIsTranslationsLoaded] = React.useState(false)
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [snackbarType, setSnackbarType] = React.useState('error')
  const classes = useStyles()
  const [languages, setLanguages] = React.useState([])
  // const translations = [
  //   {
  //     uuid: 'bubbbba',
  //     resourceId: uuid,
  //     projectId: 'd7958a81-3e76-4489-9834-42b16f0af084',
  //     languageId: 'en',
  //     type: 'translation',
  //     resourceType: 'product',
  //     schema: {
  //       name: 'Mid Maniche',
  //       companyName: 'Company Demo',
  //       description: 'Description Pasta Demo',
  //       attributes: [
  //         {
  //           name: 'Provenience',
  //           value: 'Italy'
  //         },
  //         {
  //           name: 'Cooking time',
  //           value: '14 min'
  //         },
  //         {
  //           name: 'Wheat',
  //           value: 'Senatore Cappelli'
  //         },
  //         {
  //           name: 'Essication',
  //           value: '8 h'
  //         },
  //         {
  //           name: 'Ricet',
  //           value: 'xxxxxxxx'
  //         }
  //       ],
  //       certifications: [
  //         {
  //           name: 'Bio Demo',
  //           description: 'Certificato Bio 100% Demo',
  //           authority: 'Ente Demo',
  //           num: '000000000'
  //         }
  //       ],
  //       traceability: [
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Harvest',
  //           description: 'Desc Harvest',
  //           locationName: 'Field of wheat',
  //           inputs: [
  //             {
  //               productName: 'Wheat Durum',
  //               uom: 'Kg'
  //             }
  //           ]
  //         },
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Milling',
  //           locationName: 'Mill DEMO',
  //           description: 'Milling desc',
  //           eventType: 'transformation',
  //           inputs: [
  //             {
  //               productName: 'Wheat Durum',
  //               uom: 'kg'
  //             }
  //           ],
  //           outputs: [
  //             {
  //               productName: 'Semolina dem',
  //               uom: 'kg'
  //             }
  //           ]
  //         },
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Working',
  //           locationName: 'Pasta factory Demo',
  //           description: 'Working Desc',
  //           eventType: 'transformation',
  //           inputs: [
  //             {
  //               productName: 'Semolina dem',
  //               uom: 'kg'
  //             }
  //           ],
  //           outputs: [
  //             {
  //               productName: 'Mid Man',
  //               uom: 'kg'
  //             }
  //           ]
  //         },
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Conf',
  //           locationName: 'Pasta factory Demo',
  //           description: 'Conf desc',
  //           documents: [],
  //           eventType: 'observation',
  //           inputs: [
  //             {
  //               productName: 'Pasta',
  //               uom: 'g'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   },
  //   {
  //     uuid: 'bubba12',
  //     resourceId: uuid,
  //     projectId: 'd7958a81-3e76-4489-9834-42b16f0af084',
  //     languageId: 'fra',
  //     type: 'translation',
  //     resourceType: 'product',
  //     schema: {
  //       name: 'Mez Ma',
  //       companyName: 'Company Demo',
  //       description: 'Descritpiøn',
  //       attributes: [
  //         {
  //           name: 'Provenience',
  //           value: 'Italy'
  //         },
  //         {
  //           name: 'Cooking time',
  //           value: '14 min'
  //         },
  //         {
  //           name: 'Wheat',
  //           value: 'Senatore Cappelli'
  //         },
  //         {
  //           name: 'Essication',
  //           value: '8 h'
  //         },
  //         {
  //           name: 'Ricet',
  //           value: 'xxxxxxxx'
  //         }
  //       ],
  //       certifications: [
  //         {
  //           name: 'Bio Demo',
  //           description: 'Certificato Bio 100% Demo',
  //           authority: 'Ente Demo',
  //           num: '000000000'
  //         }
  //       ],
  //       traceability: [
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Harvest',
  //           description: 'Desc Harvest',
  //           locationName: 'Field of wheat',
  //           inputs: [
  //             {
  //               productName: 'Wheat Durum',
  //               uom: 'Kg'
  //             }
  //           ]
  //         },
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Milling',
  //           locationName: 'Mill DEMO',
  //           description: 'Milling desc',
  //           eventType: 'transformation',
  //           inputs: [
  //             {
  //               productName: 'Wheat Durum',
  //               uom: 'kg'
  //             }
  //           ],
  //           outputs: [
  //             {
  //               productName: 'Semolina dem',
  //               uom: 'kg'
  //             }
  //           ]
  //         },
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Working',
  //           locationName: 'Pasta factory Demo',
  //           description: 'Working Desc',
  //           eventType: 'transformation',
  //           inputs: [
  //             {
  //               productName: 'Semolina dem',
  //               uom: 'kg'
  //             }
  //           ],
  //           outputs: [
  //             {
  //               productName: 'Mid Man',
  //               uom: 'kg'
  //             }
  //           ]
  //         },
  //         {
  //           locationFromName: '',
  //           locationToName: '',
  //           name: 'Conf',
  //           locationName: 'Pasta factory Demo',
  //           description: 'Conf desc',
  //           documents: [],
  //           eventType: 'observation',
  //           inputs: [
  //             {
  //               productName: 'Pasta',
  //               uom: 'g'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   }]

  const handleClickOpen = () => {
    setOpen(true)
  }

  const closeTranslateModalWithSave = async (translation) => {
    console.log('closed edited company => ', translation)
    try {
      await modifyTranslation(translation)
      setIsTranslateModalOpened(false)
      setSnackbar(t('translation.notification.success'), 'success')
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 400: {
            setSnackbar(
              t('translation.notification.specificError', {
                error: e.response.data.message
              }),
              'error'
            )
            break
          }
          // case 401: {
          //   setSnackbar('Si è verificato un errore. Riprovare più tardi', 'error')
          //   break
          // }...
          default: {
            setSnackbar(t('translation.notification.generalError'), 'error')
          }
        }
      } else if (e.request) {
        setSnackbar(t('translation.notification.generalError'), 'error')
        console.log(e.request)
      } else {
        setSnackbar(t('translation.notification.generalError'), 'error')
        console.log('Error', e.message)
      }
    }
  }

  const deleteTranslationOnModal = async () => {
    try {
      await deleteTranslation(translation.uuid)
      setIsTranslateModalOpened(false)
      setSnackbar(t('translation.notification.deleteSuccess'), 'success')
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 500: {
            setSnackbar(t('translation.notification.generalError'), 'error')
            break
          }
          // case 401: {
          //   setSnackbar('Si è verificato un errore. Riprovare più tardi', 'error')
          //   break
          // }...
          default: {
            setSnackbar(t('translation.notification.generalError'), 'error')
          }
        }
      }
    }
  }

  // const deleteTranslationOnCard = async (uuid) => {
  //   try {
  //     console.log('Sono qua')
  //     await deleteTranslation(uuid)
  //   } catch (e) {
  //     console.log('e;', e)
  //   }
  // }
  const closeTranslateModal = async () => {
    setSnackbar(t('translation.notification.notSaved'), 'warning')
    setIsTranslateModalOpened(false)
  }
  const openTranslateModal = (translation) => {
    console.log('Apro la modal')
    setTranslation(translation)
    setIsTranslateModalOpened(true)
  }

  const setSnackbar = (message, type) => {
    setSnackbarMessage(message)
    setSnackbarType(type)
    setShowSnackbar(true)
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSnackbar(false)
  }

  const handleClose = (value) => {
    setOpen(false)
    setSelectedValue(value)
  }

  const closeNewLanguageSelectionWithSave = async (value, translation) => {
    try {
      await createTranslation(translation)
      setOpen(false)
      setSelectedValue(value)
      setSnackbar(t('translation.notification.translationCreate'), 'success')
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 400: {
            setSnackbar(
              t('translation.notification.success', {
                error: e.response.data.message
              }),
              'error'
            )
            break
          }
          // case 401: {
          //   setSnackbar('Si è verificato un errore. Riprovare più tardi', 'error')
          //   break
          // }...
          default: {
            setSnackbar(t('translation.notification.generalError'), 'error')
          }
        }
      } else if (e.request) {
        setSnackbar(t('translation.notification.generalError'), 'error')
        console.log(e.request)
      } else {
        setSnackbar(t('translation.notification.generalError'), 'error')
        console.log('Error', e.message)
      }
    }
  }
  useEffect(() => {
    function setClassTranslation() {
      const root = document.getElementById('root')
      root.className = ''
      root.classList.add('translationProduct')
    }
    async function fetchData() {
      // You can await here
      const productResponse = await returnProductByUuid(uuid)
      setProduct(productResponse.data.data)
      const translationsResponse = await returnTranslations({
        referenceId: productResponse.data.data.uuid
      })
      setTranslations(translationsResponse.data.data)
      const languageResponse = await returnLanguages()
      const languages = languageResponse.data.data
      setLanguages(languages)
      setSelectedValue(languages[1])
      setIsTranslationsLoaded(true)
    }
    fetchData()
    setClassTranslation()
    // TODO: it comes from API
  }, [isTranslateModalOpened, open])
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <SnackbarContent
          className={
            snackbarType === 'error'
              ? classes.error
              : snackbarType === 'warning'
                ? classes.warning
                : classes.success
          }
          aria-describedby={
            snackbarType === 'error' ? 'error-snackbar' : 'success-snackbar'
          }
          message={
            <span id='error-snackbar' className={classes.message}>
              {snackbarType === 'error' ? (
                <ErrorIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
              ) : (
                <SuccessIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
              )}
              {snackbarMessage}
              <Cancel
                onClick={() => setShowSnackbar(false)}
                className={classNames(classes.icon, classes.iconVariantClose)}
              />
            </span>
          }
        />
      </Snackbar>
      {isTranslateModalOpened ? (
        <TranslationModal
          setSnackbar={setSnackbar}
          open={isTranslateModalOpened}
          translation={translation}
          onCloseModal={closeTranslateModal}
          onDeleteTranslation={deleteTranslationOnModal}
          languages={languages}
          onCloseModalWithSave={closeTranslateModalWithSave}
          product={product}
        />
      ) : null}
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        className={`${classes.root} filterRoot`}
      >
        <Grid item xs={10} sm={10} md={11} lg={11}>
          <Box className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.formControl}
                variant='h6'
                component='div'
              >
                {t('translation.title')}
              </Typography>
              <Divider variant='middle' />
            </Paper>
          </Box>
        </Grid>

        <Grid item style={{ textAlign: 'right' }}>
          <Tooltip title={t('translation.add')}>
            <Fab
              size='medium'
              aria-label='add'
              variant='round'
              className={classes.addButton}
              onClick={handleClickOpen}
            >
              <AddCircleIcon />
            </Fab>
          </Tooltip>
          {open && (
            <TranslationForm
              referenceType='product'
              languages={languages}
              setSnackbar={setSnackbar}
              selectedValue={selectedValue}
              onCloseWithSave={closeNewLanguageSelectionWithSave}
              open={open}
              onClose={handleClose}
            ></TranslationForm>
          )}
        </Grid>
      </Grid>
      <Grid container direction='column'>
        <Grid item xs className={classes.cardListContainer}>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='center'
            className={classes.grid}
            spacing={4}
          >
            {languages.length > 0 && isTranslationsLoaded ? (
              translations.length > 0 ? (
                translations.map((t) => (
                  <TranslationCard
                    openTranslateModal={openTranslateModal}
                    key={t.uuid}
                    languages={languages}
                    translation={t}
                    classes={classes}
                  ></TranslationCard>
                ))
              ) : (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                  xs={12}
                >
                  <Grid item>
                    <p>{t('translation.emptyDataSourceMessage')}</p>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid
                item
                container
                direction='column'
                alignItems='center'
                justify='center'
                xs={12}
              >
                <Grid item>
                  <LogoLoader
                    size='large'
                    text={t('translation.loading')}
                  ></LogoLoader>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ProductTranslation

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import { AddCircle as AddCircleIcon } from '@material-ui/icons'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  }
}))

export default function FabWithLoader(props) {
  const classes = useStyles()

  if (props.loader) {
    console.log('apro il fabWithLoader', props.loader)
    return (
      <Tooltip title={props.title}>
        <LogoLoader size='medium'></LogoLoader>
      </Tooltip>
    )
  } else {
    console.log('loader ora è ', props.loader)
    return (
      <Tooltip title={props.title}>
        <Fab
          aria-label={props.ariaLabel}
          size='medium'
          variant={props.variant}
          className={classes.addButton}
          onClick={props.onClick}
        >
          <AddCircleIcon />
        </Fab>
      </Tooltip>
    )
  }
}

FabWithLoader.propTypes = {
  size: PropTypes.string,
  ariaLabel: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired
}

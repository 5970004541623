import React, { useEffect, useState } from 'react'
import {
  Typography,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import {
  Search as SearchIcon,
  Close,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon
} from '@material-ui/icons'

import Pagination from 'material-ui-flat-pagination'
import './CustomTable.css'

const CustomTable = ({
  title,
  lots,
  lotsColumns,
  lotsMobileColumns,
  pageSize,
  t,
  selectedProductId,
  isSmall,
  openLotModal,
  setSnackbar,
  location,
  history,
  frontEndUrl,
  offset,
  handlePageChange,
  searchLotsInTable,
  lastSearchCriteriaForTable,
  clearSearchBar,
  totalLotsrecords
}) => {
  console.log('lastSearchCriteriaForTable =>', lastSearchCriteriaForTable)
  console.log('totalLotsrecords =>', totalLotsrecords)
  const [totalRecords, setTotalRecords] = useState(0)

  useEffect(() => {
    console.log('useEffect totalLotsrecords')
    setTotalRecords(totalLotsrecords)
  }, [totalLotsrecords])

  console.log('totalRecords =>', totalRecords)

  const columns = isSmall ? lotsMobileColumns : lotsColumns

  const handleRowClick = (rowData) => {
    const currentLot = lots.find((lot) => lot.core.uuid === rowData.core.uuid)
    openLotModal(currentLot)
  }

  const handleActionClick = (action, rowData, event) => {
    event.stopPropagation()
    if (action === 'translate') {
      if (rowData.lotType === 'core') {
        setSnackbar(
          'Devi prima rendere il lotto modificabile. Clicca sul lotto e segui le istruzioni',
          'warning'
        )
        return
      }
      location.pathname = `/lots/${rowData.uuid}`
      history.push(`/lots/${rowData.uuid}`)
    } else if (action === 'open') {
      window.open(
        `${frontEndUrl}/01/${rowData.productGtin}/10/${encodeURIComponent(
          rowData.core.lotNumber
        )}`,
        '_blank'
      )
    }
  }

  const changeCursor = (e) => {
    e.target.style.cursor = 'pointer'
  }

  console.log('lots =>', lots)
  console.log('selectedProductId =>', selectedProductId)

  const totalPages = Math.ceil(totalRecords / pageSize)
  const lastOffset = (totalPages - 1) * pageSize

  return (
    <div
      style={{
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          component='h6'
          variant='h6'
          style={isSmall ? {} : { padding: '15px' }}
          noWrap={true}
        >
          {title}
        </Typography>

        <div>
          <Paper
            style={{ display: 'flex', alignItems: 'center', width: '100%' }}
          >
            <TextField
              value={lastSearchCriteriaForTable}
              placeholder='Search'
              style={{
                width: '100%',
                padding: '5px',
                paddingLeft: '10px'
              }}
              variant='standard'
              type='search'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <Close onClick={clearSearchBar} />
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                searchLotsInTable(e, selectedProductId)
              }}
            />
          </Paper>
        </div>
      </div>
      <table className='custom-table'>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.field}>{col.title}</th>
            ))}
            <th style={{ textAlign: 'center' }}>
              {t('lotTable.localization.header.actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {lots.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1} className='empty-message'>
                {selectedProductId !== ''
                  ? t('lotTable.localization.body.emptyDataSourceMessage')
                  : t('lotTable.localization.body.emptyDataSourceMessage2')}
              </td>
            </tr>
          ) : (
            lots.map((row, index) => (
              <tr
                key={row.core.uuid}
                style={{
                  backgroundColor: index % 2 === 0 ? '' : '#f9f9f9'
                }}
                onMouseOver={(e) => changeCursor(e)}
                onClick={() => handleRowClick(row)}
              >
                {columns.map((col) => (
                  <td key={col.field}>
                    {col.render ? col.render(row) : row[col.field]}
                  </td>
                ))}

                <td style={{ textAlign: 'center' }}>
                  <Button
                    variant='outlined'
                    onClick={(e) => handleActionClick('translate', row, e)}
                  >
                    {t('lotTable.translateLot')}
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={(e) => handleActionClick('open', row, e)}
                  >
                    {t('lotTable.goToPage')}
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          padding: '10px'
        }}
      >
        <FirstPageIcon
          onMouseOver={(e) => changeCursor(e)}
          onClick={() =>
            handlePageChange(
              0,
              1,
              selectedProductId,
              lastSearchCriteriaForTable
            )
          }
        />
        <Pagination
          limit={pageSize}
          offset={offset}
          total={totalRecords}
          onClick={(e, offset, value) =>
            handlePageChange(
              offset,
              value,
              selectedProductId,
              lastSearchCriteriaForTable
            )
          }
          style={{ textAlign: 'center', paddingBottom: 2 }}
        />
        <LastPageIcon
          onMouseOver={(e) => changeCursor(e)}
          onClick={() =>
            handlePageChange(
              lastOffset,
              totalPages,
              selectedProductId,
              lastSearchCriteriaForTable
            )
          }
        />
      </div>
    </div>
  )
}

export default CustomTable

import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  Divider,
  ListItem,
  List,
  Tooltip,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import translationInput from '../../translations/translationField.json'
import CustomizedSwitches from '../SwitchLabels/CustomizedSwitches'
import ls from 'local-storage'
import AccountsService from '../Accounts.service'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
function isLanguage(language, uuid) {
  return language.uuid === uuid
}

export default function TranslationModal(props) {
  const classes = useStyles()
  const user = ls.get('user') || {}
  const { t } = useTranslation('productDetails')
  const {
    open,
    onCloseModal,
    onDeleteTranslation,
    product,
    languages,
    onCloseModalWithSave
  } = props
  const translationToModify = JSON.parse(JSON.stringify(props.translation))
  const [translation, setTranslation] = React.useState(translationToModify)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = React.useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
  const language = languages.find((language) =>
    isLanguage(language, translation.languageId)
  )

  const closeModal = () => {
    setIsSaveDialogOpen(true)
  }

  const deleteModal = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleChangeSwitch = (checked) => {
    let { status } = translation
    if (!checked) {
      status = 'draft'
    } else {
      status = 'active'
    }
    setTranslation({
      ...translation,
      status
    })
  }
  const returnToModal = () => {
    setIsSaveDialogOpen(false)
    setIsDeleteDialogOpen(false)
  }
  const handleChange = ({ target }) => {
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        [target.name]: target.value
      }
    })
  }

  const handleAttributeFieldChanged = ({ target }, index) => {
    const attributes = translation.schema.attributes.map((attr, i) => {
      if (i === index) {
        return { ...attr, [target.name]: target.value }
      } else {
        return attr
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        attributes
      }
    })
  }
  const handleAdditionalAttributeFieldChanged = ({ target }, index) => {
    const additionalAttributes = translation.schema.additionalAttributes.map(
      (attr, i) => {
        if (i === index) {
          return { ...attr, [target.name]: target.value }
        } else {
          return attr
        }
      }
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        additionalAttributes
      }
    })
  }

  const handleMetadataInnerArrayFieldChanged = (
    { target },
    index,
    propertyName
  ) => {
    // const innerAttributes = translation.schema.metadata?.[propertyName]?.map(
    //   (attribute, i) => {
    //     if (i === index) {
    //       return { ...attribute, [target.name]: target.value }
    //     } else {
    //       return attribute
    //     }
    //   }
    // )
    const innerAttributes = [...translation.schema.metadata?.[propertyName]]
    if (innerAttributes.length) {
      innerAttributes[index][target.name] = target.value
    }

    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        metadata: {
          ...translation.schema.metadata,
          [propertyName]: innerAttributes
        }
      }
    })
  }
  // const handleMetadataIngredientFieldChanged = ({ target }, index) => {
  //   const ingredients = translation.schema.metadata?.ingredients?.map(
  //     (ingredient, i) => {
  //       if (i === index) {
  //         return { ...ingredient, [target.name]: target.value }
  //       } else {
  //         return ingredient
  //       }
  //     }
  //   )
  //   setTranslation({
  //     ...translation,
  //     schema: {
  //       ...translation.schema,
  //       metadata: {
  //         ...translation.schema.metadata,
  //         ingredients
  //       }
  //     }
  //   })
  // }
  // const handleMetadataIngredientFieldChanged = ({ target }, index) => {
  //   const ingredients = translation.schema.metadata?.ingredients?.map(
  //     (ingredient, i) => {
  //       if (i === index) {
  //         return { ...ingredient, [target.name]: target.value }
  //       } else {
  //         return ingredient
  //       }
  //     }
  //   )
  //   setTranslation({
  //     ...translation,
  //     schema: {
  //       ...translation.schema,
  //       metadata: {
  //         ...translation.schema.metadata,
  //         ingredients
  //       }
  //     }
  //   })
  // }

  const handleCertificationFieldChanged = ({ target }, index) => {
    const certifications = translation.schema.certifications.map((cert, i) => {
      if (i === index) {
        return { ...cert, [target.name]: target.value }
      } else {
        return cert
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        certifications
      }
    })
  }

  const handleTraceabilityFieldChange = ({ target }, index) => {
    const traceability = translation.schema.traceability.map((track, i) => {
      if (i === index) {
        return { ...track, [target.name]: target.value }
      } else {
        return track
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        traceability
      }
    })
  }

  const handleTraceabilityInputFieldChange = ({ target }, index, i) => {
    const traceability = translation.schema.traceability.map((track, ind) => {
      if (ind === index) {
        return {
          ...track,
          inputs: track.inputs.map((input, indice) => {
            if (indice === i) {
              console.log('Input:', input)
              return { ...input, [target.name]: target.value }
            } else {
              return input
            }
          })
        }
      } else {
        return track
      }
    })
    console.log('Dopo il map:', traceability)
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        traceability
      }
    })
  }

  const handleTraceabilityOutputFieldChange = ({ target }, index, i) => {
    const traceability = translation.schema.traceability.map((track, ind) => {
      if (ind === index) {
        return {
          ...track,
          outputs: track.outputs.map((output, indice) => {
            if (indice === i) {
              return { ...output, [target.name]: target.value }
            } else {
              return output
            }
          })
        }
      } else {
        return track
      }
    })

    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        traceability
      }
    })
  }

  const deleteInput = (trackIndex, inputIndex) => {
    const traceability = translation.schema.traceability.map((track, ind) => {
      if (ind === trackIndex) {
        if (track.inputs.length === 1) {
          return {
            ...track,
            inputs: [
              {
                productName: '',
                uom: ''
              }
            ]
          }
        } else {
          return {
            ...track,
            inputs: track.inputs.filter(
              (input, indice) => inputIndex !== indice
            )
          }
        }
      } else {
        return track
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        traceability
      }
    })
  }

  const deleteOutput = (trackIndex, outputIndex) => {
    const traceability = translation.schema.traceability.map((track, ind) => {
      if (ind === trackIndex) {
        if (track.outputs.length === 1) {
          return {
            ...track,
            outputs: [
              {
                productName: '',
                uom: ''
              }
            ]
          }
        } else {
          return {
            ...track,
            outputs: track.outputs.filter(
              (output, indice) => outputIndex !== indice
            )
          }
        }
      } else {
        return track
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        traceability
      }
    })
  }

  const removeAttribute = (index) => {
    const attributes = translation.schema.attributes.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        attributes
      }
    })
  }
  const removeAdditionalAttribute = (index) => {
    const additionalAttributes = translation.schema.additionalAttributes.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        additionalAttributes
      }
    })
  }
  const removeMetadataInnerArray = (index, propertyName) => {
    const innerAttributes = translation.schema.metadata?.[propertyName]?.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        metadata: {
          ...translation.schema.metadata,
          [propertyName]: innerAttributes
        }
      }
    })
  }
  const removeCertification = (index) => {
    const certifications = translation.schema.certifications.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        certifications
      }
    })
  }

  const removeTraceability = (index) => {
    const traceability = translation.schema.traceability.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        traceability
      }
    })
  }

  return (
    <>
      <Dialog
        className='productModal'
        open={isSaveDialogOpen}
        aria-labelledby='Comfirm save'
      >
        <DialogTitle id='responsive-dialog-title'>
          {t('translation.closeDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.closeDialog.description')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnToModal} color='primary'>
            {t('translation.closeDialog.buttons.cancel')}
          </Button>
          <Button onClick={onCloseModal} color='primary' autoFocus>
            {t('translation.closeDialog.buttons.close')}
          </Button>
          <Button
            onClick={() => onCloseModalWithSave(translation)}
            classes={{ textPrimary: classes.tabText }}
            color='secondary'
          >
            {t('translation.closeDialog.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className='productModal'
        open={isDeleteDialogOpen}
        aria-labelledby='Confirm delete'
      >
        <DialogTitle id='responsive-dialog-title'>
          {t('translation.deleteDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.deleteDialog.description')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnToModal} color='primary'>
            {t('translation.deleteDialog.buttons.cancel')}
          </Button>
          <Button
            onClick={onDeleteTranslation}
            classes={{ textPrimary: classes.tabText }}
            color='secondary'
          >
            {t('translation.deleteDialog.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={closeModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant='h6' className={classes.title}>
              {translation.schema.name} - {language.fullName}
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={deleteModal}
              aria-label='close'
            >
              <DeleteIcon />
            </IconButton>
            <Tooltip title={t('translation.save')}>
              <IconButton color='inherit' onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Grid item xs={12}>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
          >
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
              <Grid item md={5}>
                <List>
                  <Grid item xs={12}>
                    {/* NAME - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h4' gutterBottom>
                            {t('translation.defaultLanguage')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              required
                              label={t('translation.modal.name')}
                              value={product.name}
                              margin='normal'
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* DESCRIPTION - DEFAULT */}
                    <ListItem>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            multiline
                            required
                            label={t('translation.modal.description')}
                            value={product.description}
                            margin='normal'
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* ATTRIBUTES - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.features.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* DEFAULT */}
                          <Grid item xs={12}>
                            {product.attributes.map((attr, index) => (
                              <>
                                <Grid
                                  container
                                  direction='row'
                                  spacing={2}
                                  alignItems='center'
                                >
                                  <Grid item md={12}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                        style={{ color: '#00000061' }}
                                      >
                                        <Typography>
                                          {attr.name} - {attr.value}
                                        </Typography>
                                      </AccordionSummary>
                                      <Grid container direction='column'>
                                        <Grid
                                          container
                                          direction='row'
                                          spacing={2}
                                        >
                                          <Grid item xs={6} md={6}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              fullWidth
                                              multiline
                                              required
                                              label={t(
                                                'translation.modal.features.name'
                                              )}
                                              value={attr.name}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={6} md={6}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id='description'
                                              name='description'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.features.value'
                                              )}
                                              value={attr.value}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Accordion>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* ADDITIONAL ATTRIBUTES - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.additionalAttributes.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* DEFAULT */}
                          <Grid item xs={12}>
                            {product.additionalAttributes &&
                              product.additionalAttributes.map(
                                (attr, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={12}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            style={{ color: '#00000061' }}
                                          >
                                            <Typography>
                                              {attr.description}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={12}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={t(
                                                    'translation.modal.additionalAttributes.description'
                                                  )}
                                                  value={attr.description}
                                                  margin='normal'
                                                  disabled
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* CERTIFICAZIONI - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.certifications.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {product.certifications.map((cert) => (
                              <>
                                <Grid
                                  container
                                  direction='row'
                                  spacing={2}
                                  alignItems='center'
                                >
                                  <Grid item md={12}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                        style={{ color: '#00000061' }}
                                      >
                                        <Typography>{cert.name}</Typography>
                                      </AccordionSummary>
                                      <Grid container direction='column'>
                                        <Grid
                                          container
                                          direction='row'
                                          spacing={2}
                                        >
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              fullWidth
                                              multiline
                                              required
                                              label={t(
                                                'translation.modal.certifications.name'
                                              )}
                                              value={cert.name}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id='description'
                                              name='description'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.certifications.description'
                                              )}
                                              value={cert.description}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              fullWidth
                                              multiline
                                              required
                                              label={t(
                                                'translation.modal.certifications.authority'
                                              )}
                                              value={cert.authority}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id='description'
                                              name='description'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.certifications.num'
                                              )}
                                              value={cert.num}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Accordion>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* TRACCIABILITA - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.traceability.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* DEFAULT */}
                          <Grid item xs={12}>
                            {product.traceability.map((track, index) => (
                              <>
                                <Grid
                                  container
                                  direction='row'
                                  spacing={2}
                                  alignItems='center'
                                >
                                  <Grid item md={12}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                        style={{ color: '#00000061' }}
                                      >
                                        <Typography>
                                          {track.name} - FASE #{index + 1}
                                        </Typography>
                                      </AccordionSummary>

                                      <Grid container direction='column'>
                                        <Grid
                                          container
                                          direction='row'
                                          spacing={2}
                                        >
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              id={`product.traceability.${index}.name`}
                                              name='name'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.traceability.name'
                                              )}
                                              value={track.name}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              id={`product.traceability.${index}.locationFromName`}
                                              name='locationFromName'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.traceability.locationFromName'
                                              )}
                                              value={track.locationFrom}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id={`product.traceability.${index}.locationToName`}
                                              name='value'
                                              type='text'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.traceability.locationToName'
                                              )}
                                              value={track.locationTo}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              id={`product.traceability.${index}.locationName`}
                                              name='name'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.traceability.locationName'
                                              )}
                                              value={track.location}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={12}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id={`product.traceability.${index}.description`}
                                              name='value'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={t(
                                                'translation.modal.traceability.description'
                                              )}
                                              value={track.description}
                                              margin='normal'
                                              disabled
                                            />
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          alignItems='center'
                                          direction='row'
                                          spacing={2}
                                        >
                                          {track.inputs.map((inp, i) => (
                                            <>
                                              <Grid item md={5}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  id={`product.traceability.${index}.inputs.${i}.productName`}
                                                  name='value'
                                                  type='text'
                                                  fullWidth
                                                  required
                                                  label={t(
                                                    'translation.modal.traceability.inputs.productName'
                                                  )}
                                                  value={inp.productId}
                                                  margin='normal'
                                                  disabled
                                                />
                                              </Grid>
                                              <Grid item md={5}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  id={`product.traceability.${index}.inputs.${i}.uom`}
                                                  name='value'
                                                  type='text'
                                                  fullWidth
                                                  required
                                                  label={t(
                                                    'translation.modal.traceability.inputs.uom'
                                                  )}
                                                  value={inp.uom}
                                                  margin='normal'
                                                  disabled
                                                />
                                              </Grid>
                                            </>
                                          ))}
                                        </Grid>
                                        <Grid
                                          container
                                          direction='row'
                                          alignItems='center'
                                          spacing={2}
                                        >
                                          {track.outputs &&
                                            track.outputs.map((out, i) => (
                                              <>
                                                <Grid item md={5}>
                                                  <TextField
                                                    InputLabelProps={{
                                                      shrink: true
                                                    }}
                                                    id={`product.traceability.${index}.outputs.${i}.productName`}
                                                    name='productName'
                                                    type='text'
                                                    fullWidth
                                                    required
                                                    label={t(
                                                      'translation.modal.traceability.inputs.productName'
                                                    )}
                                                    value={out.productId}
                                                    margin='normal'
                                                    disabled
                                                  />
                                                </Grid>
                                                <Grid item md={5}>
                                                  <TextField
                                                    InputLabelProps={{
                                                      shrink: true
                                                    }}
                                                    id={`product.traceability.${index}.outputs.${i}.uom`}
                                                    name='value'
                                                    type='text'
                                                    fullWidth
                                                    required
                                                    label={t(
                                                      'translation.modal.traceability.inputs.uom'
                                                    )}
                                                    value={out.uom}
                                                    margin='normal'
                                                    disabled
                                                  />
                                                </Grid>
                                              </>
                                            ))}
                                        </Grid>
                                      </Grid>
                                    </Accordion>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* METADATA - INGREDIENTS - DEFAULT */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {t(
                                'translation.modal.metadata.ingredients.label'
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* ingredients */}
                            <Grid item xs={12}>
                              {product.metadata?.ingredients?.map(
                                (ingredient) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={12}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            style={{ color: '#00000061' }}
                                          >
                                            <Typography>
                                              {ingredient.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={t(
                                                    'translation.modal.metadata.ingredients.title'
                                                  )}
                                                  value={ingredient.title}
                                                  margin='normal'
                                                  disabled
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - FORMATS - DEFAULT */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {t('translation.modal.metadata.formats.label')}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* formats */}
                            <Grid item xs={12}>
                              {product.metadata?.formats?.map((format) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={12}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                          style={{ color: '#00000061' }}
                                        >
                                          <Typography>
                                            {format.title}
                                          </Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                fullWidth
                                                multiline
                                                required
                                                label={t(
                                                  'translation.modal.metadata.formats.title'
                                                )}
                                                value={format.title}
                                                margin='normal'
                                                disabled
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - QUALITIES - DEFAULT */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {t('translation.modal.metadata.qualities.label')}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* qualities */}
                            <Grid item xs={12}>
                              {product.metadata?.qualities?.map((qual) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={12}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                          style={{ color: '#00000061' }}
                                        >
                                          <Typography>{qual.title}</Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                fullWidth
                                                multiline
                                                required
                                                label={t(
                                                  'translation.modal.metadata.qualities.title'
                                                )}
                                                value={qual.title}
                                                margin='normal'
                                                disabled
                                              />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id='description'
                                                name='description'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={t(
                                                  'translation.modal.metadata.qualities.value'
                                                )}
                                                value={qual.value}
                                                margin='normal'
                                                disabled
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - RECIPES - DEFAULT */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {t('translation.modal.metadata.recipes.label')}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* recipes */}
                            <Grid item xs={12}>
                              {product.metadata?.recipes?.map((recipe) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={12}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                          style={{ color: '#00000061' }}
                                        >
                                          <Typography>
                                            {recipe.title}
                                          </Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                fullWidth
                                                multiline
                                                required
                                                label={t(
                                                  'translation.modal.metadata.recipes.title'
                                                )}
                                                value={recipe.title}
                                                margin='normal'
                                                disabled
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - VARIANTS - DEFAULT */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {t('translation.modal.metadata.variants.label')}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* variants */}
                            <Grid item xs={12}>
                              {product.metadata?.variants?.map((variant) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={12}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                          style={{ color: '#00000061' }}
                                        >
                                          <Typography>
                                            {variant.title}
                                          </Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                fullWidth
                                                multiline
                                                required
                                                label={t(
                                                  'translation.modal.metadata.variants.title'
                                                )}
                                                value={variant.title}
                                                margin='normal'
                                                disabled
                                              />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id='description'
                                                name='description'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={t(
                                                  'translation.modal.metadata.variants.value'
                                                )}
                                                value={variant.value}
                                                margin='normal'
                                                disabled
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - ATTRIBUTES - DEFAULT */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {t('translation.modal.metadata.attributes.label')}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* attributes */}
                            <Grid item xs={12}>
                              {product.metadata?.attributes?.map(
                                (attribute) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={12}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            style={{ color: '#00000061' }}
                                          >
                                            <Typography>
                                              {attribute.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={t(
                                                    'translation.modal.metadata.attributes.title'
                                                  )}
                                                  value={attribute.title}
                                                  margin='normal'
                                                  disabled
                                                />
                                              </Grid>
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  id='description'
                                                  name='description'
                                                  multiline
                                                  type='text'
                                                  fullWidth
                                                  required
                                                  label={t(
                                                    'translation.modal.metadata.attributes.value'
                                                  )}
                                                  value={attribute.value}
                                                  margin='normal'
                                                  disabled
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                  </Grid>
                </List>
              </Grid>
              <Divider orientation='vertical' flexItem />
              <Grid item md={6}>
                <List>
                  <Grid item xs={12}>
                    {/* NOME - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid container direction='row'>
                          <Grid item xs={6}>
                            <Typography variant='h4' gutterBottom>
                              {language.fullName}
                            </Typography>
                          </Grid>
                          <Grid
                            style={{
                              display: 'flex',
                              'justify-content': 'center',
                              'align-items': 'center'
                            }}
                            item
                            xs={6}
                          >
                            <CustomizedSwitches
                              handleChange={handleChangeSwitch}
                              checked={translation.status}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {translationInput[language.code[0]].title}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id='name'
                              name='name'
                              type='text'
                              fullWidth
                              label={translationInput[language.code[0]].name}
                              required
                              value={translation.schema.name}
                              onChange={handleChange}
                              margin='normal'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* DESCRIZIONE - TRADUZIONE */}
                    <ListItem>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='description'
                            name='description'
                            multiline
                            type='text'
                            fullWidth
                            required
                            label={
                              translationInput[language.code[0]].description
                            }
                            value={translation.schema.description}
                            onChange={handleChange}
                            margin='normal'
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* ATTRIBUTI - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {translationInput[language.code[0]].features.title}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* TRANSLATION */}
                          <Grid item xs={12}>
                            {translation.schema.attributes.map(
                              (attr, index) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={11}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                        >
                                          <Typography>
                                            {attr.name} - {attr.value}
                                          </Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.attributes.${index}.name`}
                                                name='name'
                                                fullWidth
                                                multiline
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].features.name
                                                }
                                                value={attr.name}
                                                onChange={(event) =>
                                                  handleAttributeFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id={`translation.schema.attributes.${index}.value`}
                                                name='value'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].features.value
                                                }
                                                value={attr.value}
                                                onChange={(event) =>
                                                  handleAttributeFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                    {Array.isArray(
                                      translation.schema.attributes
                                    ) &&
                                      translation.schema.attributes.length >
                                        product.attributes.length && (
                                        <Grid item md={1}>
                                          <Button
                                            onClick={() =>
                                              removeAttribute(index)
                                            }
                                            size='small'
                                          >
                                            <ClearIcon />
                                          </Button>
                                        </Grid>
                                      )}
                                  </Grid>
                                </>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* ATTRIBUTI ADDITIONALI - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {
                              translationInput[language.code[0]]
                                .additionalAttributes.title
                            }
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* TRANSLATION */}
                          <Grid item xs={12}>
                            {translation.schema.additionalAttributes &&
                              translation.schema.additionalAttributes.map(
                                (attr, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {attr.description}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={12}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.additionalAttributes.${index}.description`}
                                                  name='description'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].additionalAttributes
                                                      .description
                                                  }
                                                  value={attr.description}
                                                  onChange={(event) =>
                                                    handleAdditionalAttributeFieldChanged(
                                                      event,
                                                      index
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.attributes
                                      ) &&
                                        translation.schema.additionalAttributes
                                          .length >
                                          product.additionalAttributes
                                            .length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeAdditionalAttribute(index)
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* CERTIFICAZIONI - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {
                              translationInput[language.code[0]].certifications
                                .title
                            }
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {translation.schema.certifications.map(
                              (trans, index) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={11}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                        >
                                          <Typography>{trans.name}</Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.certifications.${index}.name`}
                                                name='name'
                                                fullWidth
                                                multiline
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].certifications.name
                                                }
                                                value={trans.name}
                                                onChange={(event) =>
                                                  handleCertificationFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id={`translation.schema.certifications.${index}.description`}
                                                name='description'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].certifications.description
                                                }
                                                value={trans.description}
                                                onChange={(event) =>
                                                  handleCertificationFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.certifications.${index}.authority`}
                                                name='authority'
                                                fullWidth
                                                multiline
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].certifications.authority
                                                }
                                                value={trans.authority}
                                                onChange={(event) =>
                                                  handleCertificationFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id={`translation.schema.certifications.${index}.num`}
                                                name='num'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].certifications.num
                                                }
                                                value={trans.num}
                                                onChange={(event) =>
                                                  handleCertificationFieldChanged(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                    <Grid item md={1}>
                                      {Array.isArray(
                                        translation.schema.certifications
                                      ) &&
                                        translation.schema.certifications
                                          .length >
                                          product.certifications.length && (
                                          <Button
                                            onClick={() =>
                                              removeCertification(index)
                                            }
                                            size='small'
                                          >
                                            <ClearIcon />
                                          </Button>
                                        )}
                                    </Grid>
                                  </Grid>
                                </>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* TRACCIABILITA  - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {
                              translationInput[language.code[0]].traceability
                                .title
                            }
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          {/* TRADUZIONE */}
                          <Grid item xs={12}>
                            {translation.schema.traceability.map(
                              (track, index) => (
                                <>
                                  <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='center'
                                  >
                                    <Grid item md={11}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1a-content'
                                          id='panel1a-header'
                                        >
                                          <Typography>
                                            {track.name} - FASE #{index + 1}
                                          </Typography>
                                        </AccordionSummary>
                                        <Grid container direction='column'>
                                          <Grid
                                            container
                                            direction='row'
                                            spacing={2}
                                          >
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.traceability.${index}.name`}
                                                name='name'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].traceability.name
                                                }
                                                value={track.name}
                                                onChange={(event) =>
                                                  handleTraceabilityFieldChange(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.traceability.${index}.locationFromName`}
                                                name='locationFromName'
                                                fullWidth
                                                required
                                                disabled
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].traceability
                                                    .locationFromName
                                                }
                                                value={track.locationFromName}
                                                onChange={(event) =>
                                                  handleTraceabilityFieldChange(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id={`translation.schema.traceability.${index}.locationToName`}
                                                name='locationToName'
                                                disabled
                                                type='text'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].traceability.locationToName
                                                }
                                                value={track.locationToName}
                                                onChange={(event) =>
                                                  handleTraceabilityFieldChange(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                type='text'
                                                id={`translation.schema.traceability.${index}.locationName`}
                                                name='locationName'
                                                disabled
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].traceability.locationName
                                                }
                                                value={track.locationName}
                                                onChange={(event) =>
                                                  handleTraceabilityFieldChange(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                              <TextField
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                id={`translation.schema.traceability.${index}.description`}
                                                name='description'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={
                                                  translationInput[
                                                    language.code[0]
                                                  ].traceability.description
                                                }
                                                value={track.description}
                                                onChange={(event) =>
                                                  handleTraceabilityFieldChange(
                                                    event,
                                                    index
                                                  )
                                                }
                                                margin='normal'
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            container
                                            alignItems='center'
                                            direction='row'
                                            spacing={2}
                                          >
                                            {track.inputs.map((inp, i) => (
                                              <>
                                                <Grid item md={5}>
                                                  <TextField
                                                    InputLabelProps={{
                                                      shrink: true
                                                    }}
                                                    id={`translation.schema.traceability.${index}.inputs.${i}.productName`}
                                                    name='productName'
                                                    type='text'
                                                    fullWidth
                                                    required
                                                    label={
                                                      translationInput[
                                                        language.code[0]
                                                      ].traceability.inputs
                                                        .productName
                                                    }
                                                    value={inp.productName}
                                                    onChange={(event) =>
                                                      handleTraceabilityInputFieldChange(
                                                        event,
                                                        index,
                                                        i
                                                      )
                                                    }
                                                    margin='normal'
                                                  />
                                                </Grid>
                                                <Grid item md={5}>
                                                  <TextField
                                                    InputLabelProps={{
                                                      shrink: true
                                                    }}
                                                    id={`translation.schema.traceability.${index}.inputs.${i}.uom`}
                                                    name='uom'
                                                    type='text'
                                                    fullWidth
                                                    required
                                                    label={
                                                      translationInput[
                                                        language.code[0]
                                                      ].traceability.inputs.uom
                                                    }
                                                    value={inp.uom}
                                                    onChange={(event) =>
                                                      handleTraceabilityInputFieldChange(
                                                        event,
                                                        index,
                                                        i
                                                      )
                                                    }
                                                    margin='normal'
                                                  />
                                                </Grid>
                                                <Grid item md={2}>
                                                  {Array.isArray(
                                                    translation.schema
                                                      .traceability
                                                  ) &&
                                                    translation.schema
                                                      .traceability[index] &&
                                                    Array.isArray(
                                                      translation.schema
                                                        .traceability[index]
                                                        .inputs
                                                    ) &&
                                                    Array.isArray(
                                                      product.traceability
                                                    ) &&
                                                    product.traceability[
                                                      index
                                                    ] &&
                                                    Array.isArray(
                                                      product.traceability[
                                                        index
                                                      ].inputs
                                                    ) &&
                                                    translation.schema
                                                      .traceability[index]
                                                      .inputs.length >
                                                      product.traceability[
                                                        index
                                                      ].inputs.length && (
                                                      <Button
                                                        onClick={() =>
                                                          deleteInput(index, i)
                                                        }
                                                        size='small'
                                                      >
                                                        <DeleteIcon />
                                                      </Button>
                                                    )}
                                                </Grid>
                                              </>
                                            ))}
                                          </Grid>
                                          <Grid
                                            container
                                            direction='row'
                                            alignItems='center'
                                            spacing={2}
                                          >
                                            {track.outputs &&
                                              track.outputs.map((out, i) => (
                                                <>
                                                  <Grid item md={5}>
                                                    <TextField
                                                      InputLabelProps={{
                                                        shrink: true
                                                      }}
                                                      id={`translation.schema.traceability.${index}.outputs.${i}.productName`}
                                                      name='productName'
                                                      type='text'
                                                      fullWidth
                                                      required
                                                      label={
                                                        translationInput[
                                                          language.code[0]
                                                        ].traceability.outputs
                                                          .productName
                                                      }
                                                      value={out.productName}
                                                      onChange={(event) =>
                                                        handleTraceabilityOutputFieldChange(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      margin='normal'
                                                    />
                                                  </Grid>
                                                  <Grid item md={5}>
                                                    <TextField
                                                      InputLabelProps={{
                                                        shrink: true
                                                      }}
                                                      id={`translation.schema.traceability.${index}.outputs.${i}.uom`}
                                                      name='uom'
                                                      type='text'
                                                      fullWidth
                                                      required
                                                      label={
                                                        translationInput[
                                                          language.code[0]
                                                        ].traceability.outputs
                                                          .uom
                                                      }
                                                      value={out.uom}
                                                      onChange={(event) =>
                                                        handleTraceabilityOutputFieldChange(
                                                          event,
                                                          index,
                                                          i
                                                        )
                                                      }
                                                      margin='normal'
                                                    />
                                                  </Grid>
                                                  <Grid item md={2}>
                                                    {Array.isArray(
                                                      translation.schema
                                                        .traceability
                                                    ) &&
                                                      translation.schema
                                                        .traceability[index] &&
                                                      Array.isArray(
                                                        translation.schema
                                                          .traceability[index]
                                                          .outputs
                                                      ) &&
                                                      Array.isArray(
                                                        product.traceability
                                                      ) &&
                                                      product.traceability[
                                                        index
                                                      ] &&
                                                      Array.isArray(
                                                        product.traceability[
                                                          index
                                                        ].outputs
                                                      ) &&
                                                      translation.schema
                                                        .traceability[index]
                                                        .outputs.length >
                                                        product.traceability[
                                                          index
                                                        ].outputs.length && (
                                                        <Button
                                                          onClick={() =>
                                                            deleteOutput(
                                                              index,
                                                              i
                                                            )
                                                          }
                                                          size='small'
                                                        >
                                                          <DeleteIcon />
                                                        </Button>
                                                      )}
                                                  </Grid>
                                                </>
                                              ))}
                                          </Grid>
                                        </Grid>
                                      </Accordion>
                                      <Divider />
                                    </Grid>
                                    <Grid item md={1}>
                                      {Array.isArray(
                                        translation.schema.traceability
                                      ) &&
                                        translation.schema.traceability.length >
                                          product.traceability.length && (
                                          <Button
                                            onClick={() =>
                                              removeTraceability(index)
                                            }
                                            size='small'
                                          >
                                            <ClearIcon />
                                          </Button>
                                        )}
                                    </Grid>
                                  </Grid>
                                </>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* METADATA - INGREDIENTS - TRADUZIONE */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {
                                translationInput[language.code[0]].metadata
                                  .ingredients.label
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* TRANSLATION */}
                            <Grid item xs={12}>
                              {translation.schema.metadata?.ingredients?.map(
                                (ingredient, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {ingredient.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.metadata.ingredients.${index}.title`}
                                                  name='title'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.ingredients.title
                                                  }
                                                  value={ingredient.title}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'ingredients'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.metadata?.ingredients
                                      ) &&
                                        translation.schema.metadata?.ingredients
                                          ?.length >
                                          product.metadata?.ingredients
                                            ?.length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeMetadataInnerArray(
                                                  index,
                                                  'ingredients'
                                                )
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - FORMATS - TRADUZIONE */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {
                                translationInput[language.code[0]].metadata
                                  .formats.label
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* TRANSLATION */}
                            <Grid item xs={12}>
                              {translation.schema.metadata?.formats?.map(
                                (format, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {format.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.metadata.formats.${index}.title`}
                                                  name='title'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.formats.title
                                                  }
                                                  value={format.title}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'formats'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.metadata?.formats
                                      ) &&
                                        translation.schema.metadata?.formats
                                          ?.length >
                                          product.metadata?.formats?.length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeMetadataInnerArray(
                                                  index,
                                                  'formats'
                                                )
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - QUALITIES - TRADUZIONE */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {
                                translationInput[language.code[0]].metadata
                                  .qualities.label
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* TRANSLATION */}
                            <Grid item xs={12}>
                              {translation.schema.metadata.qualities.map(
                                (quality, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {quality.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.metadata.qualities.${index}.title`}
                                                  name='title'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.qualities.title
                                                  }
                                                  value={quality.title}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'qualities'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  id={`translation.schema.metadata.qualities.${index}.value`}
                                                  name='value'
                                                  multiline
                                                  type='text'
                                                  fullWidth
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.qualities.value
                                                  }
                                                  value={quality.value}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'qualities'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.metadata?.qualities
                                      ) &&
                                        translation.schema.metadata?.qualities
                                          ?.length >
                                          product.metadata?.qualities
                                            ?.length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeMetadataInnerArray(
                                                  index,
                                                  'qualities'
                                                )
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - RECIPES - TRADUZIONE */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {
                                translationInput[language.code[0]].metadata
                                  .recipes.label
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* TRANSLATION */}
                            <Grid item xs={12}>
                              {translation.schema.metadata?.recipes?.map(
                                (recipe, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {recipe.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.metadata.recipes.${index}.title`}
                                                  name='title'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.recipes.title
                                                  }
                                                  value={recipe.title}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'recipes'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.metadata?.recipes
                                      ) &&
                                        translation.schema.metadata?.recipes
                                          ?.length >
                                          product.metadata?.recipes?.length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeMetadataInnerArray(
                                                  index,
                                                  'recipes'
                                                )
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - VARIANTS - TRADUZIONE */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {
                                translationInput[language.code[0]].metadata
                                  .variants.label
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* TRANSLATION */}
                            <Grid item xs={12}>
                              {translation.schema.metadata?.variants?.map(
                                (variant, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {variant.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.metadata.variants.${index}.title`}
                                                  name='title'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.variants.title
                                                  }
                                                  value={variant.title}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'variants'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                              {/* <Grid
                                              item
                                              xs={6} md={6}
                                            >
                                              <TextField
                                                InputLabelProps={{ shrink: true }}
                                                id={`translation.schema.metadata.variants.${index}.value`}
                                                name='value'
                                                multiline
                                                type='text'
                                                fullWidth
                                                required
                                                label={translationInput[language.code[0]].metadata.variants.value}
                                                value={variant.value}
                                                onChange={(event) => handleMetadataInnerArrayFieldChanged(event, index, 'variants')}
                                                margin='normal'
                                              />
                                            </Grid> */}
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.metadata?.variants
                                      ) &&
                                        translation.schema.metadata?.variants
                                          ?.length >
                                          product.metadata?.variants
                                            ?.length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeMetadataInnerArray(
                                                  index,
                                                  'variants'
                                                )
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {/* METADATA - ATTRIBUTES - TRADUZIONE */}
                    {AccountsService.isFelsineo(user) && (
                      <ListItem>
                        <Grid container direction='column'>
                          <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                              {
                                translationInput[language.code[0]].metadata
                                  .attributes.label
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='flex-start'
                            spacing={2}
                          >
                            {/* TRANSLATION */}
                            <Grid item xs={12}>
                              {translation.schema.metadata?.attributes?.map(
                                (attr, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item md={11}>
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                          >
                                            <Typography>
                                              {attr.title}
                                            </Typography>
                                          </AccordionSummary>
                                          <Grid container direction='column'>
                                            <Grid
                                              container
                                              direction='row'
                                              spacing={2}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <TextField
                                                  InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                  type='text'
                                                  id={`translation.schema.metadata.attributes.${index}.title`}
                                                  name='title'
                                                  fullWidth
                                                  multiline
                                                  required
                                                  label={
                                                    translationInput[
                                                      language.code[0]
                                                    ].metadata.attributes.title
                                                  }
                                                  value={attr.title}
                                                  onChange={(event) =>
                                                    handleMetadataInnerArrayFieldChanged(
                                                      event,
                                                      index,
                                                      'attributes'
                                                    )
                                                  }
                                                  margin='normal'
                                                />
                                              </Grid>
                                              <Grid item xs={6} md={6}>
                                                {attr.value != null && (
                                                  <TextField
                                                    InputLabelProps={{
                                                      shrink: true
                                                    }}
                                                    id={`translation.schema.metadata.attributes.${index}.value`}
                                                    name='value'
                                                    multiline
                                                    type='text'
                                                    fullWidth
                                                    required
                                                    label={
                                                      translationInput[
                                                        language.code[0]
                                                      ].metadata.attributes
                                                        .value
                                                    }
                                                    value={attr.value}
                                                    onChange={(event) =>
                                                      handleMetadataInnerArrayFieldChanged(
                                                        event,
                                                        index,
                                                        'attributes'
                                                      )
                                                    }
                                                    margin='normal'
                                                  />
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Accordion>
                                        <Divider />
                                      </Grid>
                                      {Array.isArray(
                                        translation.schema.metadata?.attributes
                                      ) &&
                                        translation.schema.metadata?.attributes
                                          ?.length >
                                          product.metadata?.attributes
                                            ?.length && (
                                          <Grid item md={1}>
                                            <Button
                                              onClick={() =>
                                                removeMetadataInnerArray(
                                                  index,
                                                  'attributes'
                                                )
                                              }
                                              size='small'
                                            >
                                              <ClearIcon />
                                            </Button>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

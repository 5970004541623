import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function Title(props) {
  // const classes = useStyles()
  const { translation } = props

  const { t } = useTranslation(translation)
  const [title, setTitle] = React.useState('')

  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setTitle(t('tabs.traceability.inputs.where'))
        break
      }
      case 'lotDetails': {
        setTitle(t('tabs.traceability.inputs.where'))
        break
      }
      default: {
        break
      }
    }
  }, [])

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='flex-start'
      spacing={2}
    >
      <Grid item md={6} xs={12}>
        <Typography variant='h6' style={{ marginRight: '10px' }}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

Title.propTypes = {
  translation: PropTypes.string.isRequired
}
export default Title

import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  withStyles,
  withWidth,
  Typography,
  IconButton,
  Paper,
  Snackbar,
  SnackbarContent
} from '@material-ui/core'

import { urlTobase64, deepCloneObj } from '../../utils/utils'
import { withTranslation } from 'react-i18next'
import { editProduct, deleteProduct } from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'

// import axios from 'axios'
import ls from 'local-storage'

import classNames from 'classnames'

// import configuration from '../../configuration.js'

import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteIcon from '@material-ui/icons/Delete'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'

import { amber } from '@material-ui/core/colors'
// import { relativeTimeRounding } from 'moment'

import SelectCompany from './CardModalProduct/GeneralViewComponent/SelectCompany.component'
import SelectProductType from './CardModalProduct/GeneralViewComponent/SelectProductType.component.jsx'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const styles = (theme) => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  title: {
    flexGrow: 1
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  paperDialog: {
    maxHeight: '70vh',
    minHeight: '70vh'
  },
  actionButtonContainer: {
    width: '10%',
    backgroundColor: '#fafafa'
  },
  arrowContainer: {
    width: '5%',
    backgroundColor: '#fafafa'
  },
  chipContainer: {
    width: '85%',
    backgroundColor: '#fafafa',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  navBar: {
    color: 'white'
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tableContainer: {
    marginTop: 16
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  icon: {
    fontSize: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  primary: {
    color: theme.primary
  },
  warningText: {
    color: amber[700]
  },
  primaryDark: {
    color: theme.primaryDark
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  deletedStyle: {
    backgroundColor: '#fff18c',
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModalProductCore extends React.Component {
  constructor(props) {
    super(props)

    const product = deepCloneObj(this.props.product)
    if (!product.productType) {
      product.productType = 'core'
    }
    this.user = ls.get('user') || {}

    this.returnDefaultState = this.returnDefaultState.bind(this)
    this.state = this.returnDefaultState(product)
    this.updateSelectionStart = this.updateSelectionStart.bind(this)

    this.changeTab = this.changeTab.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deleteProduct = this.deleteProduct.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.fieldChanged = this.fieldChanged.bind(this)
    this.coreFieldChanged = this.coreFieldChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.typeChanged = this.typeChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward() {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward() {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const product = this.state.product

    const items = reorder(
      product.traceability,
      result.source.index,
      result.destination.index
    )
    product.traceability = items

    this.setState({ product })
  }

  // funzione che torno lo stato di default per il reset
  returnDefaultState(product) {
    return {
      isConfirmDialogOpened: false,
      isLoadingInformation: true,
      isSaveDialogOpen: false,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      open: this.props.open,
      companies: [],
      product: product,
      inputRef: [],
      selectionStart: '',
      tabNumber: 0,
      phaseTabNumber: 0,
      saveFromClose: false,
      modified: false,
      phaseError: false
    }
  }

  async componentDidMount() {
    const product = this.state.product
    this.setState({
      isLoadingInformation: true
    })

    await this.prepareSelectChoice()
    this.props.setSnackbar(
      this.props.t('notifications.defineProductType'),
      'warning'
    )
    this.setState({ product, tabNumber: 0, isLoadingInformation: false })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ phaseError: false })
  }

  async prepareSelectChoice() {
    // mi prendo le aziende dell'organization active per poterle legare al prodotto
    const companiesResponse = await returnAllCompanies()
    const companies = companiesResponse.data.data
    /* Params commentati in quanto bisogna caricare tutti i prodotti di tutte le company delle org indipendentemente dalle company specifica che detiene il prodotto
    Infatti, motivo principale per cui è stato fatto, è perchè posso avere un prodotto in input per le mie fasi che appartiene ad un'altra aziende sempre interna alla mia organizazzione

    */

    /* per la select mi ritorno tutti i prodotti ma solo il nome e l'uuid, sia per l'azienda, sia per la supplychain */
    // console.log('CardModalProductCore::::prepareSelectChoice')
    this.setState({ companies })
  }

  updateSelectionStart(index) {
    this.setState({
      selectionStart: this.state.inputRef[index].current.selectionStart
    })
  }

  changeTab(event, tabNumber) {
    this.setState({ tabNumber })
  }

  closeConfirmDialog() {
    this.setState({ isConfirmDialogOpened: false })
  }

  closeSaveDialog() {
    this.setState({ isSaveDialogOpen: false })
  }

  async confirmSave() {
    await this.saveModification()
    this.setState({ isModifyEnabled: false })
    this.closeSaveDialog()

    // if (this.state.saveFromClose) {
    //   this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
    //   this.props.onCloseModal()
    // }
  }

  // funzione lanciata per chiudere senza salvare
  confirmClose() {
    this.setState({
      isModifyEnabled: false,
      open: false,
      isDeleting: false,
      isModifing: false,
      isConfirmDialogOpened: false
    })
    this.closeSaveDialog()
    this.props.onCloseModal()
  }

  closeModal() {
    if (this.state.isModifyEnabled || this.state.modified) {
      this.setState({
        isSaveDialogOpen: true,
        saveFromClose: true,
        modified: false,
        phaseTabNumber: 0
      })
    } else {
      this.setState({
        phaseTabNumber: 0,
        open: false,
        isDeleting: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      this.props.onCloseModal()
    }
  }

  async deleteProduct() {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    try {
      const product = deepCloneObj(this.state.product)

      product.status = 'disabled'

      product.updatedAt = Date.now()
      delete product._id
      await deleteProduct(product)
      this.props.setSnackbar(
        this.props.t('notifications.deleteSuccess'),
        'success'
      )
      this.setState({ isDeleting: false })
      this.closeModal()
    } catch (e) {
      console.error('Unable to delete product, e => ', e)
      this.props.setSnackbar(
        this.props.t('notifications.errDeleteProduct'),
        'error'
      )
    }
  }

  enableOrDisableModify() {
    if (this.state.isModifyEnabled === true) {
      this.setState({
        isModifyEnabled: !this.state.isModifyEnabled,
        modified: true
      })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
  }

  async fieldChanged({ target }) {
    // console.log('field changed', target)
    const product = this.state.product
    product[target.name] = target.value
    this.setState({ product })
  }

  coreFieldChanged = ({ target }) => {
    const product = this.state.product
    product.core[target.name] = target.value
    this.setState({ product })
  }

  openConfirmDialog() {
    this.setState({ isConfirmDialogOpened: true })
  }

  async saveModification() {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    // const updateObject = Object.keys(this.state.product).reduce((acc, k) => {
    //   acc[k] = this.state.product[k]
    //   return acc
    // }, {})
    const updateObject = deepCloneObj(this.state.product)

    delete updateObject._id
    updateObject.updatedAt = Date.now()
    try {
      console.log('product to save: ', updateObject)

      const updatedProduct = await editProduct(updateObject)
      console.log('updatedProduct => ', updatedProduct)

      const product = this.state.product
      product.updatedAt = updateObject.updatedAt

      this.setState({
        product: {
          ...this.state.product
        },
        open: false,
        isDeleting: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success')
      await this.props.onCloseModal()
    } catch (e) {
      this.setState({ isModifing: false })
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors.length > 0 &&
        e.response.data.errors[0].name === 'DuplicateValueError'
      ) {
        console.error(
          'Unable to modify product: ',
          e.response.data.errors[0].message
        )
        this.props.setSnackbar(
          this.props.t('notifications.errGtinExist'),
          'error'
        )
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        Object.keys(e.response.data.error).length &&
        e.response.data.error.message.indexOf(
          "`core` is not a valid enum value for path `productType`'"
        )
      ) {
        this.props.setSnackbar(
          this.props.t('notifications.errorProductType'),
          'error'
        )
      } else {
        console.error('Unable to modify product: ', e)
        this.props.setSnackbar(this.props.t('notifications.editsErr'), 'error')
      }
    }
  }

  typeChanged({ target }) {
    const product = this.state.product
    product.productType = target.value
    this.setState({ product })
  }

  async openMedia(url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
      <head>
        <title>Trusty | Documenti</title>
      </head>
      <body style="margin: 0px;">
        <iframe width="100%" height="100%" src="${file}"></iframe>
      </body>
    </html>
    `)
    } catch (e) {
      this.props.setSnackbar(this.props.t('notifications.docNoView'), 'error')
    }
  }

  render() {
    if (this.props.open !== true) {
      return <div></div>
    }
    const { classes } = this.props
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.phaseError}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={classes.warning}
            aria-describedby='error-snackbar'
            message={
              <span id='error-snackbar' className={classes.message}>
                <ErrorIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                Abilita le modifiche per eliminare la fase
              </span>
            }
            action={[
              <IconButton
                key='close'
                aria-label='close'
                color='inherit'
                onClick={this.closeSnackbar}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            ]}
          />
        </Snackbar>
        {/* dialog conferma eliminazione */}
        <Dialog
          className='productModal'
          open={this.state.isConfirmDialogOpened}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.deleteProduct.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.deleteProduct.description')}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ textPrimary: classes.tabText }}
              onClick={this.closeConfirmDialog}
              color='primary'
              autoFocus
            >
              {this.props.t('dialog.deleteProduct.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteProduct} color='secondary'>
              {this.state.isDeleting ? (
                <LogoLoader size='small'></LogoLoader>
              ) : (
                this.props.t('dialog.deleteProduct.buttons.confirm')
              )}
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog confirm saved changes */}
        <Dialog
          className='productModal'
          open={this.state.isSaveDialogOpen}
          aria-labelledby='Comfirm save'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.saveEdits.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.saveEdits.description')}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color='inherit' autoFocus>
              {this.props.t('dialog.saveEdits.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color='primary'>
              {this.props.t('dialog.saveEdits.buttons.closeWithoutSave')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.confirmSave}
              color='secondary'
              disabled={!!this.state.isModifing}
            >
              {this.state.isModifing ? (
                <LogoLoader size='small'></LogoLoader>
              ) : (
                this.props.t('dialog.saveEdits.buttons.closeAndSave')
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        {!this.state.isLoadingInformation ? (
          <Dialog
            className='productModal'
            style={
              this.props.width === 'md' || this.props.width === 'lg'
                ? { position: 'absolute', top: 0, bottom: 'auto' }
                : {}
            }
            scroll='paper'
            classes={
              this.props.width === 'lg' || this.props.width === 'md'
                ? { root: classes.paperRootMiddleSize }
                : { root: classes.paperRoot }
            }
            PaperProps={{ square: true }}
            open={this.state.open}
            onClose={this.closeModal}
            fullWidth={true}
            fullScreen={this.props.width === 'sm' || this.props.width === 'xs'}
            maxWidth={'lg'}
          >
            {this.props.width === 'sm' || this.props.width === 'xs' ? (
              <DialogTitle
                classes={{ root: classes.titleMobile }}
                disableTypography={true}
                className={classes.navBar}
              >
                <Typography
                  style={{ width: '95%' }}
                  noWrap={true}
                  variant='h6'
                  component='h2'
                >
                  {this.state.product.core.name
                    ? `${this.props.t('title')} "${this.state.product.core.name}"`
                    : this.props.t('noNameTitle')}
                </Typography>
                <IconButton
                  style={{ justifySelf: 'flex-end', width: '5%' }}
                  edge='end'
                  color='inherit'
                  aria-label='close modal'
                  onClick={this.closeModal}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            ) : (
              <DialogTitle disableTypography={true} className={classes.navBar}>
                <Typography noWrap={true} variant='h6' component='h2'>
                  {this.state.product.core.name
                    ? `${this.props.t('title')} "${this.state.product.core.name}"`
                    : this.props.t('noNameTitle')}
                </Typography>
                <Box
                  display='flex'
                  justifyContent='space-evenly'
                  alignItems='center'
                  className={classes.closeButton}
                >
                  {this.state.isModifyEnabled && (
                    <IconButton
                      color='inherit'
                      aria-label='allow-modify'
                      title='disabilita modifiche'
                      onClick={() => this.enableOrDisableModify()}
                    >
                      <LockOpen />
                    </IconButton>
                  )}
                  {/* - MOSTRA ALLERGENI A QUESTO LIVELLO */}
                  {!this.state.isModifyEnabled && (
                    <IconButton
                      color='inherit'
                      aria-label='disallow-modify'
                      title='abilita modifiche'
                      onClick={() => this.enableOrDisableModify()}
                    >
                      <Lock />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => this.openConfirmDialog()}
                    color='secondary'
                    aria-label='delete product'
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    edge='end'
                    color='inherit'
                    aria-label='close modal'
                    onClick={this.closeModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
            )}
            <Paper elevation={4} square>
              <Tabs
                classes={{ indicator: classes.tabIndicator }}
                variant='scrollable'
                scrollButtons='on'
                value={this.state.tabNumber}
                onChange={this.changeTab}
                indicatorColor='primary'
              >
                <Tab
                  classes={{ selected: classes.tabText }}
                  label={this.props.t('tabs.general.title')}
                />
              </Tabs>
            </Paper>
            <DialogContent
              classes={
                this.props.width === 'sm' || this.props.width === 'xs'
                  ? {}
                  : { root: classes.paperDialog }
              }
            >
              {/* GENERALE */}
              <div
                component='div'
                hidden={this.state.tabNumber !== 0}
                id='simple-tabpanel-0'
                aria-labelledby='simple-tabpanel-0'
                className={`${classes.tabContainer} tabContent`}
              >
                <Grid
                  container
                  direction='row'
                  justify='flex-start'
                  alignItems='center'
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <Grid
                      container
                      direction='column'
                      alignItems='flex-start'
                      justify='flex-start'
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ width: '100%', marginTop: '20px' }}
                      >
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={6}>
                            {/* NAME */}
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id='name'
                              name='name'
                              label={this.props.t('tabs.general.inputs.name')}
                              type='text'
                              fullWidth
                              value={this.state.product.core.name}
                              onChange={this.coreFieldChanged}
                              disabled={!this.state.isModifyEnabled}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {/* GTIN */}
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id='gtin'
                              name='gtin'
                              label={this.props.t('tabs.general.inputs.gtin')}
                              type='text'
                              fullWidth
                              value={this.state.product.core.gtin}
                              onChange={this.coreFieldChanged}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ width: '100%', marginTop: '20px' }}
                      >
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={6}>
                            <SelectCompany
                              product={this.state.product}
                              companies={this.state.companies}
                              isModifyEnabled={this.state.isModifyEnabled}
                              coreFieldChanged={this.coreFieldChanged}
                              translation={'productDetails'}
                            ></SelectCompany>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <SelectProductType
                              product={this.state.product}
                              typeChanged={this.typeChanged}
                              translation={'productDetails'}
                              disabled={!this.state.isModifyEnabled}
                            ></SelectProductType>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            {this.props.width === 'sm' || this.props.width === 'xs' ? (
              <DialogActions classes={{ root: classes.mobileActions }}>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => this.openConfirmDialog()}
                  color='secondary'
                  aria-label='delete product'
                >
                  {this.props.t('buttons.delete')}
                </Button>
                {this.state.isModifyEnabled && (
                  <Button
                    classes={{ textPrimary: classes.tabText }}
                    color='primary'
                    startIcon={<LockOpen />}
                    aria-label='allow-modify'
                    title='disabilita modifiche'
                    onClick={() => this.enableOrDisableModify()}
                  >
                    {this.props.t('buttons.lock')}
                  </Button>
                )}
                {!this.state.isModifyEnabled && (
                  <Button
                    classes={{ textPrimary: classes.tabText }}
                    color='primary'
                    startIcon={<Lock />}
                    aria-label='allow-modify'
                    title='disabilita modifiche'
                    onClick={() => this.enableOrDisableModify()}
                  >
                    {this.props.t('buttons.edit')}
                  </Button>
                )}
              </DialogActions>
            ) : (
              ''
            )}
          </Dialog>
        ) : null}
      </React.Fragment>
    )
  }
}

CardModalProductCore.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func,
  open: PropTypes.bool,
  product: PropTypes.object
}

export default withRouter(
  withStyles(styles)(
    withWidth()(withTranslation('productDetails')(CardModalProductCore))
  )
)

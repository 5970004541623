import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import './style.css'
import { wasteSchema } from '../../waste'
import {
  Typography,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'

import CheckboxType from './CheckboxTypeComponent'

import { CSSTransition } from 'react-transition-group'
import SelectMaterialComponent from './SelectMaterialComponent'
import SelectWasteCollection from './SelectWasteCollection'

// const useStyles = makeStyles((theme) => ({
//   tabContainer: {
//     padding: theme.spacing(2)
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   mainButton: {
//     background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
//     color: 'white',
//     width: '100%'
//   },
//   input: {
//     display: 'none'
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   },
//   chipPrimary: {
//     margin: '0px 5px',
//     backgroundColor: theme.primary,
//     '&:hover, &:active, &:focus': {
//       backgroundColor: theme.primaryDark
//     }
//   }
// }))

function WasteComponent(props) {
  // const classes = useStyles()
  const {
    translation,
    index,
    isModifyEnabled,
    waste,
    changeValueOnParent,
    changeInnerValueOnParent
  } = props
  const { t } = useTranslation(translation)
  // const [showCheckbox, setShowCheckbox] = useState(false)
  const [showOnEffect, setShowOnEffect] = useState(false)
  const [componentType, setComponentType] = useState(waste.componentType || '')
  const monoMaterials = wasteSchema.data.filter(
    (elem) =>
      elem.id === 1 ||
      elem.id === 2 ||
      elem.id === 3 ||
      elem.id === 4 ||
      elem.id === 5 ||
      elem.id === 6 ||
      elem.id === 19 ||
      elem.id === 21 ||
      elem.id === 60 ||
      elem.id === 61
  )

  const multiMaterials = wasteSchema.data.filter(
    (elem) =>
      elem.id !== 1 &&
      elem.id !== 2 &&
      elem.id !== 3 &&
      elem.id !== 4 &&
      elem.id !== 5 &&
      elem.id !== 6 &&
      elem.id !== 19 &&
      elem.id !== 21 &&
      elem.id !== 60 &&
      elem.id !== 61
  )

  const wasteCollections = [
    'glass',
    'paper',
    'plastic',
    'organic_waste',
    'metals',
    'dry_undifferentiated',
    'regional_disposition'
  ]

  const componentTypes = [
    'other',
    'case',
    'jar',
    'base',
    'stick',
    'bigBottle',
    'spray',
    'bagReusable',
    'bagBiodegradablePlastic',
    'bagUltralightPlastic',
    'bottle',
    'envelope',
    'carton',
    'capsule',
    'cartonBeverage',
    'cassette',
    'sealingWax',
    'closure',
    'container',
    'dispenser',
    'label',
    'labelCovering',
    'vial',
    'film',
    'littleBottle',
    'sheet',
    'cage',
    'shellac',
    'paperClip',
    'can',
    'handle',
    'adhesiveTape',
    'package',
    'thinFilm',
    'strap',
    'retina',
    'faucet',
    'pocket',
    'bag',
    'box',
    'bucket',
    'shopper',
    'string',
    'tableware',
    'canister',
    'cap',
    'tube',
    'dipTube',
    'valve',
    'tray',
    'littleJar',
    'bigTray'
  ]

  // FUNCTIONS

  const selectValueChanged = (e, index) => {
    changeValueOnParent(e, index)
    setShowOnEffect(true)
  }

  const selectMaterialValueChanged = (e, index) => {
    changeValueOnParent(e, index)
  }

  const selectInnerMaterialValueChanged = (e, index) => {
    changeInnerValueOnParent(e, index)
  }

  const checkboxValueChanged = (e, type) => {
    setComponentType(type)
    changeValueOnParent(e, index, type)
  }

  useEffect(() => {
    // setComponentType(waste.componentType || '')
    // setShowOnEffect(false)
    if (waste.component) {
      setShowOnEffect(true)
    } else if (
      waste.component === '' &&
      waste.material === '' &&
      waste.componentType === '' &&
      waste.wasteCollection === ''
    ) {
      setComponentType('')
      setShowOnEffect(false)
      console.log('ciao')
    }
  }, [waste.component]) // Only re-run the effect if count changes

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justify='center'
      spacing={2}
      key={`product-waste-${index}`}
    >
      <Grid item xs>
        <Grid container direction='row' alignItems='flex-end' spacing={1}>
          <Grid item xs={12} md={6}>
            <InputLabel
              style={{ fontSize: '12px', marginBottom: '15px' }}
              id='demo-simple-select-label'
            >
              {t('tabs.waste.inputs.type')}
            </InputLabel>
            <Select
              label={t('tabs.waste.inputs.type')}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='component'
              value={waste.component}
              onChange={(e) => {
                selectValueChanged(e, index)
              }}
              disabled={!isModifyEnabled}
              fullWidth
            >
              {componentTypes.map((element) => {
                return (
                  <MenuItem key={element} value={element}>
                    {t(`tabs.waste.inputs.componentsType.${element}`)}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={4}>
            <CSSTransition
              in={showOnEffect}
              timeout={500}
              classNames='move'
              unmountOnExit
              // onEnter={() => setShowCheckbox(true)}
              // onExit={() => setShowCheckbox(false)}
            >
              <CheckboxType
                valueFromChild={checkboxValueChanged}
                waste={waste}
                isModifyEnabled={isModifyEnabled}
              />
            </CSSTransition>
          </Grid>
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4}>
            {waste.componentType === 'multicomponent' && (
              <SelectMaterialComponent
                waste={waste}
                index={index}
                valueFromChild={selectMaterialValueChanged}
                valueFromChildInnerMaterial={selectInnerMaterialValueChanged}
                isModifyEnabled={isModifyEnabled}
                materials={multiMaterials}
                componentType='tabs.waste.inputs.multicomponent'
              />
            )}
            {waste.componentType === 'monocomponent' && (
              <SelectMaterialComponent
                waste={waste}
                index={index}
                valueFromChild={selectMaterialValueChanged}
                valueFromChildInnerMaterial={selectInnerMaterialValueChanged}
                isModifyEnabled={isModifyEnabled}
                materials={monoMaterials}
                componentType='tabs.waste.inputs.monocomponent'
              />
            )}
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={6}>
            {waste.material &&
              Object.keys(waste.material).length > 0 &&
              waste.material.materials.length > 0 && (
                <Typography>
                  {' '}
                  {t('tabs.waste.inputs.disposalCode')}:{' '}
                  {waste.material.materials[0].code}
                </Typography>
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {waste.material && (
              <SelectWasteCollection
                waste={waste}
                index={index}
                valueFromChild={selectMaterialValueChanged}
                isModifyEnabled={isModifyEnabled}
                materials={wasteCollections}
                componentType='tabs.waste.inputs.wasteCollectionType'
              ></SelectWasteCollection>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

WasteComponent.propTypes = {
  waste: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  changeValueOnParent: PropTypes.func.isRequired,
  changeInnerValueOnParent: PropTypes.func.isRequired
}
export default WasteComponent

/* Componente Padre che forma il blocco productId,Uom */
import React from 'react'

import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import ProductComponent from '../IOComponents/ProductComponent'
import UomComponent from '../IOComponents/UomComponent'
import DeleteComponentSimple from '../AdditionalBlocks/DeleteComponentSimple'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function InputProductComponent(props) {
  // const classes = useStyles()
  const {
    inputIndex,
    trackIndex,
    input,
    isModifyEnabled,
    product,
    products,
    inputChanged,
    deleteInput,
    translation,
    type
  } = props

  return (
    <div key={`input-${inputIndex}`}>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <ProductComponent
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          component={input}
          isModifyEnabled={isModifyEnabled}
          componentChanged={inputChanged}
          product={product}
          products={products}
          translation={translation}
          type={type}
        ></ProductComponent>
        <UomComponent
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          component={input}
          isModifyEnabled={isModifyEnabled}
          componentChanged={inputChanged}
          type={type}
          translation={translation}
        ></UomComponent>
        <DeleteComponentSimple
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          deleteComponent={deleteInput}
          isModifyEnabled={isModifyEnabled}
          translation={translation}
        ></DeleteComponentSimple>
      </Grid>
    </div>
  )
}

InputProductComponent.propTypes = {
  inputIndex: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  input: PropTypes.object.isRequired,
  deleteInput: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  inputChanged: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}
export default InputProductComponent

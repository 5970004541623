import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { CardMedia, Paper, IconButton } from '@material-ui/core'

import Edit from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  imgInput: {
    display: 'none'
  },
  tabText: {
    color: theme.primary
  }
}))

function SelectCompany(props) {
  const classes = useStyles()
  const { product, isModifyEnabled, changeImage, translation } = props
  const { t } = useTranslation(translation)

  return (
    <Paper
      style={{
        marginTop: '-140px',
        marginLeft: 'calc(50% - 80px)',
        borderRadius: '50%',
        width: '160px',
        height: '160px'
      }}
    >
      <CardMedia
        style={{
          width: '160px',
          height: '160px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        image={product.profileImage?.original || '/images/img-placeholder.png'}
        title={t('tabs.general.inputs.profileImage')}
      >
        <input
          className={classes.imgInput}
          id='add-new-image-profile'
          type='file'
          accept='image/png, image/jpeg'
          onChange={(e) => changeImage(e, 'profileImage')}
          disabled={!isModifyEnabled}
        />
        <label htmlFor='add-new-image-profile'>
          <Paper elevation={6} style={{ borderRadius: '50%' }}>
            <IconButton
              classes={{ colorPrimary: classes.tabText }}
              disabled={!isModifyEnabled}
              component='span'
              color='primary'
              style={isModifyEnabled ? {} : { display: 'none' }}
            >
              <Edit />
            </IconButton>
          </Paper>
        </label>
      </CardMedia>
    </Paper>
  )
}

SelectCompany.propTypes = {
  product: PropTypes.object.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  changeImage: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default SelectCompany

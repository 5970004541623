import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnLotsCount() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(
    `${configuration.apiBaseUrl}/core/projects/${currOrganization.uuid}/lots/count`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: {
        filter: JSON.stringify({ status: 'active' })
      }
    }
  )
}

export async function returnLotsCountWithParams(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(
    `${configuration.apiBaseUrl}/core/projects/${currOrganization.uuid}/lots/count`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}
// esport query per i lotti
export async function returnAllLots() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/lots`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// esport query per avere alcuni lotti
export async function returnSomeLots(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllLots()
  }
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/lots`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}

// funzione che crea una nuovo lotto
export async function returnNewLot(newLot) {
  /*
    questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
    Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è il prodotto
  if (!newLot) {
    return
  }

  console.log('BEFORE QUERY newLot => ', newLot)
  // mando la query
  const response = await axios.post(
    `${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/`,
    newLot,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}
// funzione che crea una nuovo lotto
export async function createDefaultLot(productId) {
  if (!productId) {
    return
  }

  const response = await axios.post(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/lots`,
    { productId, type: 'lot' },
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}
// funzione che modifica un lotto
export async function editLot(lot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  /* TODO: adattare parte supplychain anche per light lot */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  let uuid
  if (lot.resourceId) {
    uuid = lot.uuid
  } else {
    uuid = lot.core.uuid
  }

  console.log('BEFORE QUERY edit lot => ', lot)
  // mando la query
  const response = await axios.put(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/lots/${uuid}`,
    lot,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}

// funzione che rimpiazza un lotto
export async function replaceLot(lot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  /* TODO: adattare parte supplychain anche per light lot */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }

  /* cancello verified così da non mandarlo alla replace del Lot ma poi lo ricopio perché in chiusura della modal lo utilizzo
     per la snackbar
  */
  console.log('BEFORE QUERY replace lot => ', lot)

  const response = await axios.put(
    `${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}/replace/`,
    lot,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}

// funzione che elimina un light lot
export async function deleteLot(lot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  let uuid
  if (lot.resourceId) {
    uuid = lot.uuid
  } else {
    uuid = lot.core.uuid
  }
  console.log('BEFORE QUERY delete lot => ', lot)
  // mando la query
  return axios.delete(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/lots/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      data: {
        type: 'lot'
      }
    }
  )
}

// export funzione che mi tira fuori ultimi valori del lotto -> NOTE: dopo che abbiamo levato la tracciabilità dal lotto, questa non viene più usata.
// export const getLastInputOrOutputInformation = (lot, product, productsIdNameMap) => {
//   const traceability = lot.traceability
//   if (!traceability || !traceability.length) {
//     return
//   }
//   if (!Object.keys(productsIdNameMap).length) {
//     return
//   }
//   console.log('productsIdNameMap', productsIdNameMap, 'product', product)
//   const inputs = traceability[traceability.length - 1].inputs
//   // const productLastTrack = product.traceability[product.traceability.length - 1]

//   const lastLot = {
//     trackName: traceability[traceability.length - 1].name,
//     productName: inputs.length > 0 ? productsIdNameMap[inputs[inputs.length - 1].productId] : '',
//     quantity: inputs.length > 0 ? inputs[inputs.length - 1].quantity : '',
//     lotNumber: inputs.length > 0 ? lot.lotNumber || inputs[inputs.length - 1].lotNumber : '',
//     gtin: product.core.gtin || null
//   }
//   // console.log('lastLot => ', lastLot)
//   // console.log('productLastTrack.outputs => ', productLastTrack.outputs)
//   if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length && traceability[traceability.length - 1].outputs.length > 0) {
//     const outputs = traceability[traceability.length - 1].outputs
//     lastLot.productName = productsIdNameMap[outputs[outputs.length - 1].productId]
//     lastLot.quantity = outputs[outputs.length - 1].quantity
//     lastLot.lotNumber = lot.lotNumber || outputs[outputs.length - 1].lotNumber
//   }

//   return lastLot
// }

// esport query per un lot tramite uuid
export async function returnLotByUuid(uuid) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!uuid) {
    return
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/lots/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

/* TODO: Trattare caso supplychain */

// export const getLastInputOrOutputLotInformationSupplychainField = (lot) => {
//   const traceability = lot.traceability
//   if (!traceability || !traceability.length) {
//     return
//   }
//   const inputs = traceability[traceability.length - 1].inputs
//   let quantity = inputs[inputs.length - 1].quantity
//   if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
//     const outputs = traceability[traceability.length - 1].outputs
//     quantity = outputs[outputs.length - 1].quantity
//   }
//   return quantity
// }

/* TODO: Trattare caso supplychain */

export async function createLabelFromLot(uuid, data) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.post(
    `${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${uuid}/label/create`,
    data,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      responseType: 'arraybuffer'
    }
  )
  return response
}

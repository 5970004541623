/*  ERROR
{
  "status": false,
  "error": {
    "statusCode": 403,
    "type": "Forbidden",
    "message": "You must sign-in on your domain",
    "name": "Forbidden",
    "code": "Forbidden"
  }
}

  OK
  {data: {…}, status: 200, statusText: "OK", headers: {…}, config: {…}, …}
config: {url: "https://testapi.trusty.id/accounts/invite", method: "post", data: "{"email":"dutrito95@live.com","locale":"it-IT","domain":"filierapuntozero"}", headers: {…}, transformRequest: Array(1), …}
data: {status: true, data: {}}
headers:
content-length: "20"
content-type: "application/json; charset=utf-8"
__proto__: Object
request: XMLHttpRequest {__sentry_xhr__: {…}, readyState: 4, timeout: 0, withCredentials: false, onreadystatechange: ƒ, …}
status: 200
statusText: "OK"
__proto__: Object
*/

import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, TextField, Container, Button } from '@material-ui/core'
import validate from 'validate.js'
import { useTranslation } from 'react-i18next'
// import ls from 'local-storage'
import { inviteWL } from '../../utils/accounts'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: '#3C4858!important'
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))

function SignupForm({ className, changeStatusCode, domain, ...rest }) {
  const classes = useStyles()
  const { t } = useTranslation('signup')

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {}
  })

  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('validations.email') },
      email: {
        message: t('validations.validEmail')
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email } = formState.values
    // const currentLanguage = ls.get('i18nextLng')

    try {
      const response = await inviteWL(email, 'it-IT', domain.domain)

      // const response = await Axios({
      //   method: 'POST',
      //   url: `${configuration.apiBaseUrl}/onboardings`,
      //   data: {
      //     locale: currentLanguage,
      //     email,
      //     companyName,
      //     vatId
      //   }
      // })

      const successCode =
        response && response.data && response.data.status === true
          ? response.status
          : null
      console.log(successCode)
      changeStatusCode(successCode)
    } catch (error) {
      console.log('error response::: ', error.response)
      const errorCode = error && error.response && error.response.status
      changeStatusCode(errorCode)
    }
  }

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <Container>
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.field}>
          <TextField
            error={hasError('email')}
            fullWidth
            helperText={hasError('email') ? formState.errors.email[0] : null}
            label={t('inputs.email')}
            name='email'
            required
            onChange={handleChange}
            value={formState.values.email || ''}
          />
        </div>
        <Button
          className={
            !formState.isValid
              ? classes.submitButton
              : clsx(classes.submitButton, classes.primaryGradient)
          }
          color='primary'
          type='submit'
          size='large'
          variant='contained'
          disabled={!formState.isValid}
        >
          {t('buttons.confirm')}
        </Button>
      </form>
    </Container>
  )
}

export default SignupForm

import ls from 'local-storage'
const AccountsService = {
  getCurrentAccount: () => {
    return ls.get('user')
  },
  setCurrentAccount: (data) => {
    return ls.set('user', data)
  },
  removeCurrentAccount: () => {
    return ls.remove('user')
  },
  removeAll: () => {
    return ls.clear()
  },
  isFelsineo: (user) => {
    return user.email === 'ced@felsineo.com'
  },
  isFelsineoVeg: (user) => {
    return user.email === 'info@felsineoveg.com'
  },
  isFoodItaliae: (user) => {
    return user.email === 'info@fooditaliae.com'
  }
}

export default AccountsService

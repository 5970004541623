import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

export let currOrganization = ls.get('currOrganization') || null

// esport query per i prori ACL
export async function returnTranslations(params = {}) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/translations`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params
    }
  )
}

// funzione che crea una nuova azienda
export async function createTranslation(translation) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  console.log('BEFORE QUERY newCompany => ', translation)
  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/translations/`,
    translation,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// funzione che crea una nuova azienda
export async function modifyTranslation(translation) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  console.log('BEFORE QUERY newCompany => ', translation)
  // mando la query
  return axios.put(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/translations/${translation.uuid}`,
    translation,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

export async function deleteTranslation(uuid) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // mando la query
  return axios.delete(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/translations/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

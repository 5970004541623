import React from 'react'
import './style.css'
import logoImg from '../../assets/images/loghi/trusty-logo-black.svg'

const SIZES = ['small', 'medium', 'large']

const COLORS = ['dark', 'light']

export const LogoLoader = ({ size, color, text }) => {
  const loaderSize = SIZES.includes(size) ? size : SIZES[0]
  const loaderColor = COLORS.includes(color) ? color : COLORS[0]
  return (
    <div className='logoLoaderContainer'>
      <img
        className={`logoLoader ${loaderSize} ${loaderColor}`}
        src={logoImg}
        alt=''
      />
      {text != null ? <h3>{text}</h3> : null}
    </div>
  )
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  withStyles,
  Typography,
  TextField,
  IconButton,
  Paper,
  Tooltip,
  Tab,
  Tabs,
  Fab,
  withWidth
} from '@material-ui/core'

import ls from 'local-storage'
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react'
import { withTranslation } from 'react-i18next'
import { deleteLot, editLot } from '../../utils/lots'
import { deepCloneObj, ItLocalizedUtils, locale } from '../../utils/utils'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Lock,
  LockOpen,
  GetApp as GetAppIcon,
  ExitToApp as ExitToAppIcon
} from '@material-ui/icons'
import AccountsService from '../Accounts.service'

const getLastLotnumber = (lot) => {
  const { traceability } = lot

  if (!traceability || !traceability.length) {
    return
  }

  if (
    Array.isArray(traceability[traceability.length - 1].outputs) &&
    traceability[traceability.length - 1].outputs.length
  ) {
    const outputs = traceability[traceability.length - 1].outputs
    return outputs[outputs.length - 1].lotNumber
  }

  const inputs = traceability[traceability.length - 1].inputs
  return inputs[inputs.length - 1].lotNumber
}

const styles = (theme) => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiFormLabel-root': {
      fontSize: '16px'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    margin: 'auto',
    width: '80%',
    height: '70%',
    outline: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primary: {
    color: theme.primary
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  // chipPrimary: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primary,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  // chipPrimaryDark: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primaryDark,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModalLotCore extends React.Component {
  constructor(props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.deleteLot = this.deleteLot.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.deadlineTimestampChanged = this.deadlineTimestampChanged.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.toogleAlreadyCompletedDialog =
      this.toogleAlreadyCompletedDialog.bind(this)
    this.lotNumberChange = this.lotNumberChange.bind(this)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward() {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward() {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  returnDefaultState() {
    return {
      isConfirmDialogOpened: false,
      isSaveDialogOpen: false,
      canIAddAnotherTrack: true,
      isAlreadyCompletedDialogOpen: false,
      canIAddAnotherAttribute: true,
      tabNumber: 0,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      modified: false,
      open: this.props.open,
      lot: this.props.lot
    }
  }

  changeTab(event, tabNumber) {
    this.setState({ tabNumber })
  }

  closeSaveDialog() {
    if (!this.state.isModifing) {
      this.setState({ isSaveDialogOpen: false })
    }
  }

  async componentDidMount() {
    const lot = JSON.parse(JSON.stringify(this.state.lot))
    // console.log('lot:::number::::', this.props.lotNumber)
    this.props.setSnackbar(
      this.props.t('notifications.defineLotToModifyTranslatiosAndAttributes'),
      'warning'
    )
    this.setState({
      lot
    })
  }

  /* funzione che mi prepara tutti gli input per le company, le facilities e i prodotti */

  closeConfirmDialog() {
    this.setState({ isConfirmDialogOpened: false })
  }

  // funzione che viene lanciata alla chiusara della dialog
  closeModal(forceClose, shouldIForceRefresh) {
    if (this.state.isModifyEnabled || this.state.modified) {
      // console.log('dentro primo if')
      if (forceClose === true) {
        // console.log('dentro primo force clse, forceClose => ', forceClose)
        this.setState(this.returnDefaultState())
        this.props.onCloseModal(shouldIForceRefresh)
      } else {
        this.setState({
          isSaveDialogOpen: true,
          saveFromClose: true,
          modified: false
        })
      }
    } else {
      this.setState(this.returnDefaultState())
      // this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.onCloseModal(shouldIForceRefresh)
    }
  }

  lotNumberChange({ target }) {
    const lot = this.state.lot
    lot.core.lotNumber = target.value
    this.setState({ lot })
  }

  async deleteLot() {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    const lot = this.state.lot
    if (Array.isArray(lot.attributes)) {
      /* rimuovo il mock se non è stato compilato così da poter salvare la risorsa */
      if (lot.core.attributes.length === 1) {
        if (
          lot.core.attributes[0].name === '' &&
          lot.core.attributes[0].value === ''
        ) {
          lot.core.attributes = []
        }
      }
      /* ritorno errore se non stati compilati o il tipo o l'url -> l'utente deve cancellarli e poi salvare */
      if (lot.core.attributes && lot.core.attributes.length > 1) {
        if (
          lot.core.attributes.some(
            (attr) => attr.name === '' || attr.value === ''
          )
        ) {
          this.setState({ isDeleting: false })
          return this.props.setSnackbar(
            this.props.t('notifications.errorAdditionalAttributes'),
            'error'
          )
        }
      }
    }
    try {
      await deleteLot(lot)
      // await axios.put(`${configuration.apiBaseUrl}/lots/${this.state.lot.uuid}`, { status: 'deleted' }, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      this.setState({ isDeleting: false })
      this.closeModal(true, true)
    } catch (e) {
      this.setState({ isDeleting: false })
      console.error(`Unable to delete lot with uuid ${lot.uuid}: `, e)
      this.props.setSnackbar(
        this.props.t('notifications.deleteLotError'),
        'error'
      )
      // alert('Impossibile eliminare il lotto')
    }
  }

  enableOrDisableModify() {
    if (this.state.isModifyEnabled === true) {
      this.setState({
        isModifyEnabled: !this.state.isModifyEnabled,
        modified: true
      })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
  }

  // funzione che cambia i valori dei campi dgli output

  openConfirmDialog() {
    this.setState({ isConfirmDialogOpened: true })
  }

  // funzione che salva le modifiche
  async saveModification() {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    const updateObject = deepCloneObj(this.state.lot)
    // const updateObject = Object.keys(this.state.lot).reduce((acc, k) => {
    //   acc[k] = this.state.lot[k]
    //   return acc
    // }, {})

    try {
      // controllo l'expirationDate
      if (
        !updateObject.core.expirationDate ||
        updateObject.core.expirationDate === ''
      ) {
        delete updateObject.core.expirationDate
      }

      console.log('updateObject => ', updateObject)
      const responseObject = await editLot(updateObject)
      // const responseObject = {}
      // responseObject.data = {}
      // responseObject.data.data = updateObject
      const lot = responseObject.data.data
      console.log('lot::edit', lot)
      lot.updatedAt = updateObject.updatedAt
      await this.props.onCloseModal(true)
      this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success')
      // alert('Modifica avvenuta con successo')
    } catch (e) {
      this.setState({ isModifing: false })
      console.error('Unable to modify lot, error => ', e)
      this.props.setSnackbar(this.props.t('notifications.editsError'), 'error')
      // alert('Impossibile modificare il lotto')
    }
  }

  // funzione che viene lanciata alla chiusura della dialog salvando le modifiche
  async confirmSave() {
    await this.saveModification()
    this.setState({
      isModifyEnabled: false,
      open: false,
      isDeleting: false,
      isModifing: false,
      isConfirmDialogOpened: false
    })
    this.closeSaveDialog()
    // this.props.onCloseModal(true)
  }

  confirmClose() {
    if (!this.state.isModifing) {
      this.setState({
        isModifyEnabled: false,
        open: false,
        isDeleting: false,
        isModifing: false,
        isConfirmDialogOpened: false
      })
      this.closeSaveDialog()
      this.props.onCloseModal(false)
    }
  }

  deadlineTimestampChanged(date) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      date.setHours(0, 0, 0, 0)
      lot.core.expirationDate = date.toISOString()
    } else {
      lot.core.expirationDate = null
    }

    this.setState({ lot })
  }

  preventDefault = (event) => event.preventDefault()

  // funzione che apre il link del qr code
  openQrcodeUrl(url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  downloadDocument(name, url) {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode(gtin) {
    // creo link fittizio
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = `${gtin}_QRCode.png`
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre la modal dell'impossibilità di lasciare campi vuoti
  toogleAlreadyCompletedDialog() {
    const isAlreadyCompletedDialogOpen = this.state.isAlreadyCompletedDialogOpen
    this.setState({
      isAlreadyCompletedDialogOpen: !isAlreadyCompletedDialogOpen
    })
  }

  render() {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <React.Fragment>
        {/* dialog conferma eliminazione */}
        <Dialog
          className='lotModal'
          open={this.state.isConfirmDialogOpened}
          aria-labelledby='lot-dialog-title'
        >
          <DialogTitle id='lot-dialog-title'>
            {this.props.t('dialog.confirmDelete.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmDelete.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ textPrimary: classes.tabText }}
              onClick={this.closeConfirmDialog}
              color='secondary'
              autoFocus
            >
              {this.props.t('dialog.confirmDelete.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteLot} color='primary'>
              {this.state.isDeleting ? (
                <LogoLoader size='small' color='light'></LogoLoader>
              ) : (
                this.props.t('dialog.confirmDelete.buttons.confirm')
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog confirm saved changes */}
        <Dialog
          className='lotModal'
          open={this.state.isSaveDialogOpen}
          aria-labelledby='Confirm save'
        >
          <DialogTitle id='lot-dialog-title'>
            {this.props.t('dialog.confirmClose.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color='primary' autoFocus>
              {this.props.t('dialog.confirmClose.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color='primary'>
              {this.props.t('dialog.confirmClose.buttons.closeWithoutSave')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.confirmSave}
              color='secondary'
              disabled={!!this.state.isModifing}
            >
              {this.state.isModifing ? (
                <LogoLoader size='small' color='light'></LogoLoader>
              ) : (
                this.props.t('dialog.confirmClose.buttons.closeAndSave')
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog warning can't save a complete lot as draft */}
        {/* <Dialog className='lotModal'
          open={this.state.isAlreadyCompletedDialogOpen}
          aria-labelledby="Impossibile procedere"
          disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="lot-dialog-title">
            {this.props.t('dialog.cannotProceed.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.cannotProceed.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }}
              onClick={this.toogleAlreadyCompletedDialog}
              color="primary">
              {this.props.t('dialog.cannotProceed.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog> */}

        {/* MAIN DIALOG */}
        <Dialog
          className='lotModal'
          style={
            this.props.width === 'md' || this.props.width === 'lg'
              ? { position: 'absolute', top: 0, bottom: 'auto' }
              : {}
          }
          PaperProps={{ square: true }}
          scroll='paper'
          classes={
            this.props.width === 'lg' || this.props.width === 'md'
              ? { root: classes.paperRootMiddleSize }
              : { root: classes.paperRoot }
          }
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          {this.props.width === 'sm' || this.props.width === 'xs' ? (
            <DialogTitle
              classes={{ root: classes.titleMobile }}
              disableTypography={true}
              className={classes.navBar}
            >
              <Typography
                style={{ width: '95%' }}
                noWrap={true}
                variant='h6'
                component='h2'
              >
                {this.state.lot.core.lotNumber
                  ? `${this.props.t('title')} ${this.state.lot.core.lotNumber}`
                  : this.props.t('titleNoNum')}{' '}
                di &quot;{this.state.lot.productName}&quot;
              </Typography>
              <IconButton
                edge='end'
                color='inherit'
                aria-label='close modal'
                onClick={this.closeModal}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          ) : (
            <DialogTitle disableTypography={true} className={classes.navBar}>
              <Typography noWrap={true} variant='h6' component='h2'>
                {this.state.lot.core.lotNumber
                  ? `${this.props.t('title')} ${this.state.lot.core.lotNumber}`
                  : this.props.t('titleNoNum')}{' '}
                di &quot;{this.state.lot.productName}&quot;
              </Typography>
              <Box
                display='flex'
                justifyContent='space-evenly'
                alignItems='center'
                className={classes.closeButton}
              >
                {this.state.isModifyEnabled && (
                  <Tooltip title='Disabilita modifiche'>
                    <IconButton
                      color='inherit'
                      aria-label='allow-modify'
                      onClick={() => this.enableOrDisableModify()}
                    >
                      <LockOpen />
                    </IconButton>
                  </Tooltip>
                )}
                {!this.state.isModifyEnabled && (
                  <Tooltip title='Modifica dati'>
                    <IconButton
                      color='inherit'
                      aria-label='disallow-modify'
                      onClick={() => this.enableOrDisableModify()}
                    >
                      <Lock />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title='Elimina lotto'>
                  <IconButton
                    onClick={() => this.openConfirmDialog()}
                    aria-label='delete product'
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Chiudi / Salva modifiche'>
                  <IconButton
                    edge='end'
                    color='inherit'
                    aria-label='close modal'
                    onClick={this.closeModal}
                    style={{ marginRight: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </DialogTitle>
          )}
          <Paper elevation={4} square>
            <Tabs
              classes={{ indicator: classes.tabIndicator }}
              variant='scrollable'
              value={this.state.tabNumber}
              onChange={this.changeTab}
              indicatorColor='primary'
            >
              <Tab
                classes={{ selected: classes.tabText }}
                label={this.props.t('tabs.general.title')}
              />
            </Tabs>
          </Paper>
          <DialogContent
            classes={
              this.props.width === 'xs' || this.props.width === 'sm'
                ? {}
                : { root: classes.paperDialog }
            }
          >
            {/* GENERALE */}
            <div
              hidden={this.state.tabNumber !== 0}
              id='simple-tabpanel-0'
              aria-labelledby='simple-tabpanel-0'
              className={`${classes.tabContainer} tabContent`}
            >
              <Grid
                style={{ height: '100%' }}
                container
                direction='row'
                alignItems='center'
                justify='center'
                spacing={2}
              >
                <Grid item md={6} sm={10} xs={10}>
                  <Typography
                    variant='body1'
                    style={{
                      marginBottom: 20,
                      marginTop: !isSmall ? '-16px' : ''
                    }}
                  >
                    {this.props.t('tabs.general.description')}.
                  </Typography>
                  <TextField
                    style={{ marginTop: '2px !important', marginLeft: 16 }}
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                    label={this.props.t('lotNumber')}
                    type='text'
                    value={this.state.lot.core.lotNumber}
                    onChange={(e) => this.lotNumberChange(e)}
                    disabled={!this.state.isModifyEnabled}
                  />
                  <MuiPickersUtilsProvider
                    utils={ItLocalizedUtils}
                    locale={locale}
                  >
                    <KeyboardDatePicker
                      style={{ marginTop: '2px !important', marginLeft: 16 }}
                      className={classes.w100}
                      margin='normal'
                      id='deadlineTimestamp'
                      label={this.props.t('tabs.general.inputs.expirationDate')}
                      format='dd/MM/yyyy'
                      cancelLabel={this.props.t(
                        'tabs.general.inputs.dateCancel'
                      )}
                      value={
                        this.state.lot.core.expirationDate
                          ? this.state.lot.core.expirationDate
                          : null
                      }
                      onChange={(date) => this.deadlineTimestampChanged(date)}
                      KeyboardButtonProps={{
                        'aria-label': this.props.t(
                          'tabs.general.inputs.expirationDate'
                        )
                      }}
                      disabled={!this.state.isModifyEnabled}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography variant='body2' style={{ paddingLeft: 16 }}>
                    {this.props.t('tabs.general.inputs.expirationDateInfo')}
                  </Typography>
                  <Typography variant='body2' style={{ paddingLeft: 16 }}>
                    <b>
                      {this.props.t(
                        'tabs.general.inputs.modifyTranslationsAndAttributes'
                      )}
                    </b>
                  </Typography>
                  <Button
                    variant='outlined'
                    disabled={!this.state.isModifyEnabled}
                    onClick={this.saveModification}
                  >
                    {this.props.t('buttons.modifyLotAttributesAndTranslation')}
                  </Button>
                </Grid>
                {/*  CREARE INPUT PER INSERIRE IL NUMERO DI LOTTO */}

                <Grid item md={4} sm={12} xs={12}>
                  {!AccountsService.isFelsineo(this.user) &&
                    !AccountsService.isFelsineoVeg(this.user) && (
                      <Grid
                        container
                        direction='column'
                        justify='flex-start'
                        alignItems='center'
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ width: '100%', textAlign: 'center' }}
                        >
                          <QRCodeSVG
                            size={180}
                            value={`${this.props.frontEndUrl}/01/${this.state.lot.productGtin}/10/${encodeURIComponent(this.state.lot.core.lotNumber)}`}
                          />
                          <QRCodeCanvas
                            value={`${this.props.frontEndUrl}/01/${this.state.lot.productGtin}/10/${encodeURIComponent(this.state.lot.core.lotNumber)}`}
                            size={512}
                            style={{ display: 'none' }}
                            id='hiddenCanvas'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ width: '100%', textAlign: 'center' }}
                        >
                          <Grid
                            container
                            direction='row'
                            alignItems='center'
                            justify='flex-start'
                          >
                            <Grid item xs={12}>
                              <Tooltip title='Apri pagina pubblica'>
                                <Fab
                                  classes={{ primary: classes.fabPrimary }}
                                  onClick={() =>
                                    this.openQrcodeUrl(
                                      `${this.props.frontEndUrl}/01/${this.state.lot.productGtin}/10/${encodeURIComponent(this.state.lot.core.lotNumber)}`
                                    )
                                  }
                                  color='primary'
                                  size='small'
                                  style={{ margin: '10px 5px' }}
                                >
                                  <ExitToAppIcon />
                                </Fab>
                              </Tooltip>
                              <Tooltip title='Scarica QR code'>
                                <Fab
                                  classes={{ primary: classes.fabPrimary }}
                                  onClick={() =>
                                    this.downloadQrcode(
                                      this.state.lot.productGtin
                                    )
                                  }
                                  color='primary'
                                  size='small'
                                  style={{ margin: '10px 5px' }}
                                >
                                  <GetAppIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          {this.props.width === 'sm' || this.props.width === 'xs' ? (
            <DialogActions classes={{ root: classes.mobileActions }}>
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => this.openConfirmDialog()}
                color='white'
                aria-label='delete lot'
              >
                {this.props.t('buttons.delete')}
              </Button>
              {this.state.isModifyEnabled && (
                <Button
                  startIcon={<LockOpen />}
                  classes={{ textPrimary: classes.tabText }}
                  color='primary'
                  aria-label='allow-modify'
                  title={this.props.t('buttons.lockEdits')}
                  onClick={() => this.enableOrDisableModify()}
                >
                  {this.props.t('buttons.close')}
                </Button>
              )}
              {!this.state.isModifyEnabled && (
                <Button
                  startIcon={<Lock />}
                  classes={{ textPrimary: classes.tabText }}
                  color='primary'
                  aria-label='disallow-modify'
                  title={this.props.t('buttons.unlockEdits')}
                  onClick={() => this.enableOrDisableModify()}
                >
                  {this.props.t('buttons.edit')}
                </Button>
              )}
            </DialogActions>
          ) : (
            ''
          )}
        </Dialog>
      </React.Fragment>
    )
  }
}

CardModalLotCore.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withRouter(
  withStyles(styles)(
    withWidth()(withTranslation('lotDetails')(CardModalLotCore))
  )
)

import axios from 'axios'
export const requestEmbededUrl = async function (data) {
  // const data = JSON.stringify({
  //   landingPageUuid: 'c2451648-2e0d-11ee-b2a3-731b0699c4f9',
  //   groupIds: [1891387, 1877059],
  //   externalIdentifier: '7c2b7d8d-5738-4ff9-99f3-eb19c25e3bdd',
  //   userInfo: {
  //     firstName: 'Trusty',
  //     lastName: 'Integrations',
  //     email: 'trustyintegrations@trustyteam.it'
  //   }
  // })

  // const config = {
  //   method: 'post',
  //   url: 'https://miniapp.trusty.id/api/embed-url/external-user',
  //   headers: {
  //     Authorization: 'Bearer retool_01h7048r5vzqggr0m4j1538spb',
  //     'Content-Type': 'application/json'
  //   },
  //   data
  // }
  // const config = {
  //   method: 'post',
  //   url: 'https://eospuyfe0trgfbf.m.pipedream.net'
  // }

  // axios(config)
  //   .then(function (response) {
  //     console.log(response.data)
  //     return response.data
  //   })
  //   .catch(function (error) {
  //     console.log(error)
  //   })
  let response
  try {
    response = await axios.post(
      // 'https://eospuyfe0trgfbf.m.pipedream.net', //gmail lavoro alepallante
      // 'https://eom3p8ep6dcfplt.m.pipedream.net', // apio a.pallante
      'https://eoyynmv7afdtcc3.m.pipedream.net',
      data,
      {
        // //     headers: {
        //     Authorization: 'Bearer retool_01h7048r5vzqggr0m4j1538spb',
        //     'Content-Type': 'application/json'
        //   }
      }
    )
    return response.data
  } catch (error) {
    console.error(
      'Error:',
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.error) ||
        error
    )
    throw (
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.error) ||
      error
    )
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  Divider,
  ListItem,
  List,
  Tooltip,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  TextField
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import translationInput from '../../../translations/translationField.json'
import CustomizedSwitches from '../../SwitchLabels/CustomizedSwitches'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
function isLanguage(language, uuid) {
  return language.uuid === uuid
}

export default function TranslationModalCompany(props) {
  const classes = useStyles()
  const { t } = useTranslation('companyDetails')
  const {
    open,
    onCloseModal,
    onDeleteTranslation,
    company,
    languages,
    onCloseModalWithSave
  } = props
  const translationToModify = JSON.parse(JSON.stringify(props.translation))
  const [translation, setTranslation] = React.useState(translationToModify)
  const [isSaveDialogOpen, setIsSaveDialogOpen] = React.useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
  const language = languages.find((language) =>
    isLanguage(language, translation.languageId)
  )

  const closeModal = () => {
    setIsSaveDialogOpen(true)
  }

  const deleteModal = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleChangeSwitch = (checked) => {
    let { status } = translation
    if (!checked) {
      status = 'draft'
    } else {
      status = 'active'
    }
    setTranslation({
      ...translation,
      status
    })
  }
  const returnToModal = () => {
    setIsSaveDialogOpen(false)
    setIsDeleteDialogOpen(false)
  }
  const handleChange = ({ target }) => {
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        [target.name]: target.value
      }
    })
  }

  const handleCertificationFieldChanged = ({ target }, index) => {
    const certifications = translation.schema.certifications.map((cert, i) => {
      if (i === index) {
        return { ...cert, [target.name]: target.value }
      } else {
        return cert
      }
    })
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        certifications
      }
    })
  }

  const removeCertification = (index) => {
    const certifications = translation.schema.certifications.filter(
      (attr, ind) => index !== ind
    )
    setTranslation({
      ...translation,
      schema: {
        ...translation.schema,
        certifications
      }
    })
  }

  return (
    <>
      <Dialog
        className='companyModal'
        open={isSaveDialogOpen}
        aria-labelledby='Confirm save'
      >
        <DialogTitle id='responsive-dialog-title'>
          {t('translation.closeDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.closeDialog.description')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnToModal} color='primary'>
            {t('translation.closeDialog.buttons.cancel')}
          </Button>
          <Button onClick={onCloseModal} color='primary' autoFocus>
            {t('translation.closeDialog.buttons.close')}
          </Button>
          <Button
            onClick={() => onCloseModalWithSave(translation)}
            classes={{ textPrimary: classes.tabText }}
            color='secondary'
          >
            {t('translation.closeDialog.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className='companyModal'
        open={isDeleteDialogOpen}
        aria-labelledby='Confirm delete'
      >
        <DialogTitle id='responsive-dialog-title'>
          {t('translation.deleteDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('translation.deleteDialog.description')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnToModal} color='primary'>
            {t('translation.deleteDialog.buttons.cancel')}
          </Button>
          <Button
            onClick={onDeleteTranslation}
            classes={{ textPrimary: classes.tabText }}
            color='secondary'
          >
            {t('translation.deleteDialog.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={closeModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant='h6' className={classes.title}>
              {translation.schema.name} - {language.fullName}
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={deleteModal}
              aria-label='close'
            >
              <DeleteIcon />
            </IconButton>
            <Tooltip title={t('translation.save')}>
              <IconButton color='inherit' onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Grid item xs={12}>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
          >
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
              <Grid item md={5}>
                <List>
                  <Grid item xs={12}>
                    {/* NAME - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h4' gutterBottom>
                            {t('translation.defaultLanguage')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              required
                              label={t('translation.modal.name')}
                              value={company.name}
                              margin='normal'
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* DESCRIPTION - DEFAULT */}
                    <ListItem>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            multiline
                            required
                            label={t('translation.modal.description')}
                            value={company.description}
                            margin='normal'
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* CERTIFICAZIONI - DEFAULT */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {t('translation.modal.certifications.title')}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {company.certifications.map((cert, index) => (
                              <Grid
                                key={`cert.${index}}`}
                                container
                                direction='row'
                                spacing={2}
                                alignItems='center'
                              >
                                <Grid item md={12}>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls='panel1a-content'
                                      id='panel1a-header'
                                      style={{ color: '#00000061' }}
                                    >
                                      <Typography>{cert.name}</Typography>
                                    </AccordionSummary>
                                    <Grid container direction='column'>
                                      <Grid
                                        container
                                        direction='row'
                                        spacing={2}
                                      >
                                        <Grid item xs={3} md={3}>
                                          <TextField
                                            InputLabelProps={{ shrink: true }}
                                            type='text'
                                            fullWidth
                                            multiline
                                            required
                                            label={t(
                                              'translation.modal.certifications.name'
                                            )}
                                            value={cert.name}
                                            margin='normal'
                                            disabled
                                          />
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                          <TextField
                                            InputLabelProps={{ shrink: true }}
                                            id='description'
                                            name='description'
                                            multiline
                                            type='text'
                                            fullWidth
                                            required
                                            label={t(
                                              'translation.modal.certifications.description'
                                            )}
                                            value={cert.description}
                                            margin='normal'
                                            disabled
                                          />
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                          <TextField
                                            InputLabelProps={{ shrink: true }}
                                            type='text'
                                            fullWidth
                                            multiline
                                            required
                                            label={t(
                                              'translation.modal.certifications.authority'
                                            )}
                                            value={cert.authority}
                                            margin='normal'
                                            disabled
                                          />
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                          <TextField
                                            InputLabelProps={{ shrink: true }}
                                            id='description'
                                            name='description'
                                            multiline
                                            type='text'
                                            fullWidth
                                            required
                                            label={t(
                                              'translation.modal.certifications.num'
                                            )}
                                            value={cert.num}
                                            margin='normal'
                                            disabled
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Accordion>
                                  <Divider />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </List>
              </Grid>
              <Divider orientation='vertical' flexItem />
              <Grid item md={6}>
                <List>
                  <Grid item xs={12}>
                    {/* NOME - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid container direction='row'>
                          <Grid item xs={6}>
                            <Typography variant='h4' gutterBottom>
                              {language.fullName}
                            </Typography>
                          </Grid>
                          <Grid
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            item
                            xs={6}
                          >
                            <CustomizedSwitches
                              handleChange={handleChangeSwitch}
                              checked={translation.status}
                            ></CustomizedSwitches>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {translationInput[language.code[0]].title}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12} md={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id='name'
                              name='name'
                              type='text'
                              fullWidth
                              label={translationInput[language.code[0]].name}
                              required
                              value={translation.schema.name}
                              onChange={handleChange}
                              margin='normal'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* DESCRIZIONE - TRADUZIONE */}
                    <ListItem>
                      <Grid
                        container
                        direction='row'
                        justify='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='description'
                            name='description'
                            multiline
                            type='text'
                            fullWidth
                            required
                            label={
                              translationInput[language.code[0]].description
                            }
                            value={translation.schema.description}
                            onChange={handleChange}
                            margin='normal'
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* CERTIFICAZIONI - TRADUZIONE */}
                    <ListItem>
                      <Grid container direction='column'>
                        <Grid item xs={12}>
                          <Typography variant='h6' component='h6'>
                            {
                              translationInput[language.code[0]].certifications
                                .title
                            }
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            {translation.schema.certifications.map(
                              (trans, index) => (
                                <Grid
                                  container
                                  key={`cert.translated.${index}}`}
                                  direction='row'
                                  spacing={2}
                                  alignItems='center'
                                >
                                  <Grid item md={11}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                      >
                                        <Typography>{trans.name}</Typography>
                                      </AccordionSummary>
                                      <Grid container direction='column'>
                                        <Grid
                                          container
                                          direction='row'
                                          spacing={2}
                                        >
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              id={`translation.schema.certifications.${index}.name`}
                                              name='name'
                                              fullWidth
                                              multiline
                                              required
                                              label={
                                                translationInput[
                                                  language.code[0]
                                                ].certifications.name
                                              }
                                              value={trans.name}
                                              onChange={(event) =>
                                                handleCertificationFieldChanged(
                                                  event,
                                                  index
                                                )
                                              }
                                              margin='normal'
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id={`translation.schema.certifications.${index}.description`}
                                              name='description'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={
                                                translationInput[
                                                  language.code[0]
                                                ].certifications.description
                                              }
                                              value={trans.description}
                                              onChange={(event) =>
                                                handleCertificationFieldChanged(
                                                  event,
                                                  index
                                                )
                                              }
                                              margin='normal'
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              type='text'
                                              id={`translation.schema.certifications.${index}.authority`}
                                              name='authority'
                                              fullWidth
                                              multiline
                                              required
                                              label={
                                                translationInput[
                                                  language.code[0]
                                                ].certifications.authority
                                              }
                                              value={trans.authority}
                                              onChange={(event) =>
                                                handleCertificationFieldChanged(
                                                  event,
                                                  index
                                                )
                                              }
                                              margin='normal'
                                            />
                                          </Grid>
                                          <Grid item xs={3} md={3}>
                                            <TextField
                                              InputLabelProps={{ shrink: true }}
                                              id={`translation.schema.certifications.${index}.num`}
                                              name='num'
                                              multiline
                                              type='text'
                                              fullWidth
                                              required
                                              label={
                                                translationInput[
                                                  language.code[0]
                                                ].certifications.num
                                              }
                                              value={trans.num}
                                              onChange={(event) =>
                                                handleCertificationFieldChanged(
                                                  event,
                                                  index
                                                )
                                              }
                                              margin='normal'
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Accordion>
                                    <Divider />
                                  </Grid>
                                  <Grid item md={1}>
                                    {Array.isArray(
                                      translation.schema.certifications
                                    ) &&
                                      translation.schema.certifications.length >
                                        company.certifications.length && (
                                        <Button
                                          onClick={() =>
                                            removeCertification(index)
                                          }
                                          size='small'
                                        >
                                          <ClearIcon />
                                        </Button>
                                      )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

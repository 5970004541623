import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Container, CardHeader, Box } from '@material-ui/core'
import ResetPasswordForm from './ResetPasswordForm'
import Message from '../Message'
import DomainLogo from '../../components/DomainLogo/DomainLogo'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  emailSent: {
    width: '180px'
  }
}))

function ResetPassword(props) {
  const classes = useStyles()

  const { t } = useTranslation('resetPassword')
  const [statusCode, setStatusCode] = useState(null)

  document.getElementById('root').className = ''
  document.getElementById('root').classList.add('passwordReset')

  const selectErrorCase = (code) => {
    switch (code) {
      case 200:
        return (
          <>
            <Box mb={8} width='100%' display='flex' justifyContent='center'>
              <img
                className={classes.emailSent}
                src={'/images/mail_sent.svg'}
                alt='email sent'
              />
            </Box>
            <Box width='100%' mb={3}>
              <Message>{t('notifications.success')}</Message>
            </Box>
          </>
        )
      case 400:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.button')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.badRequest')}
            </Message>
          </Box>
        )
      case 500:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.button')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.applicationError')}
            </Message>
          </Box>
        )
      case 404:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.button')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.notFound')}
            </Message>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
        <DomainLogo url={window.location.host}></DomainLogo>
        {statusCode ? (
          <Container>{selectErrorCase(statusCode)}</Container>
        ) : (
          <>
            <CardHeader
              className='signInHeader'
              title={t('title')}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={t('subtitle')}
            />
            <ResetPasswordForm
              changeStatusCode={setStatusCode}
              domain={props.domain}
            />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ResetPassword

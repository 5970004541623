import React from 'react'
import PropTypes from 'prop-types'
import logoImg from '../../assets/images/loghi/trusty-logo-black.svg'

function TrustyLogoBlack(props) {
  return (
    // <svg width={props.width} height={props.height} viewBox="0 0 10338 14617" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 1.5 }} >
    //   <g id="Livello2">
    //     <path d="M5689.65,9609.38c-261.59,176.774 -1175.07,938.468 -1407.47,1138.34c-122.024,104.942 -245.492,211.882 -389.003,290.407c-137.918,75.464 -292.057,123.329 -449.781,142.48c-121.898,14.802 -246.205,26.243 -368.92,11.972c-97.873,-11.383 -164.831,-22.905 -257.884,-54.784c-102.313,-35.052 -167.901,-67.315 -257.065,-126.505c-286.895,-190.451 -436.693,-506.911 -561.925,-805.704c-62.362,-148.791 -122.519,-298.417 -181.969,-448.281c-140.156,-353.315 -245.98,-634.415 -377.006,-990.868c-198.898,-541.096 -387.944,-1085.99 -542.079,-1640.36c-17.45,-62.761 -32.031,-126.229 -46.134,-189.745c-28.637,-128.973 -56.246,-302.56 69.797,-396.031c100.253,-74.345 214.407,36.756 275.472,104.417c17.115,18.964 33.507,38.578 48.533,59.078c14.736,20.104 28.547,40.902 40.62,62.551c38.662,69.335 73.824,140.385 111.481,210.217c52.729,97.783 107.654,194.496 159.672,292.622c149.316,281.669 297.912,563.69 444.316,846.736c146.421,283.078 289.433,567.737 434.144,851.609c116.473,228.476 233.708,499.365 496.766,607.88c66.399,27.39 150.294,46.138 220.9,59.392c364.238,68.378 687.046,-166.032 987.223,-321.14c310.328,-160.354 621.806,-318.699 933.862,-475.994c449.302,-226.476 912.139,-438.393 1405.39,-563.431c279.053,-70.739 564.002,-117.668 847.516,-168.47c148.396,-26.591 302.227,-56.678 452.829,-24.786c230.544,48.821 408.458,324.98 280.129,531.867c-15.983,25.767 -44.303,63.412 -68.493,83.065c-184.651,150.015 -440.349,156.929 -667.496,195.548c-357.757,60.825 -695.035,201.857 -1013.59,364.186c-212.163,108.112 -423.315,220.934 -619.829,353.733Z" style={{ fill: '#3C4858', stroke: '#000', strokeWidth: '8.33px' }}/>
    //     <g>
    //       <g id="Livello3">
    //         <path d="M3453.72,8560.15c18.396,-78.219 -1.057,-160.35 -17.084,-236.424c-49.732,-236.051 -62.317,-294.371 -127.636,-562.642c-73.504,-301.886 -161.755,-599.349 -245.063,-898.237c-83.307,-298.884 -168.985,-596.989 -254.774,-895.048c-41.72,-144.948 -85.397,-289.225 -127.748,-433.957c-1.742,-5.952 5.015,11.434 5.914,17.6c0.358,2.455 -3.422,-3.551 -4.207,-5.887c-1.249,-3.715 -0.533,-7.97 -1.707,-11.713c-19.98,-63.664 -39.922,-127.365 -61.699,-190.33c-22.412,-64.8 -46.109,-129.087 -70.841,-192.883c-55.372,-142.833 -182.675,-250.802 -323.724,-268.687c-74.301,-9.421 -150.106,4.013 -219.067,34.307c-35.237,15.479 -67.647,33.695 -96.821,60.682c-32.432,30 -62.596,68.325 -82.778,109.47c-86.904,177.178 -11.593,384.603 53.695,554.437c27.811,72.342 57.12,143.994 86.075,215.803c29.492,73.143 60.269,145.672 89.265,219.049c124.517,315.108 248.183,630.614 371.269,946.387c123.084,315.769 245.657,631.775 367.237,948.232c38.955,101.396 75.736,203.766 114.362,305.31c50.531,132.841 60.23,161.462 126.551,286.017c12.884,24.196 42.587,65.083 62.647,82.782c106.65,94.1 313.272,77.299 356.134,-84.268Z" style={{ fill: '#3C4858', stroke: '#000', strokeWidth: '8.33px' }}/>
    //         <path d="M4468.65,8278.78c40.874,-75.181 46.499,-165.086 53.625,-247.797c22.109,-256.646 27.284,-320.138 43.947,-614.304c18.75,-331.025 22.003,-661.674 30.434,-992.391c8.43,-330.712 14.349,-661.294 20.147,-991.86c2.82,-160.756 3.558,-321.376 5.706,-482.089c0.089,-6.609 1.437,13.169 0.474,19.76c-0.383,2.624 -2.239,-4.618 -2.302,-7.24c-0.1,-4.171 1.849,-8.341 1.828,-12.52c-0.353,-71.075 -0.66,-142.178 -2.951,-213.043c-2.357,-72.931 -6.103,-145.699 -10.989,-218.253c-10.939,-162.444 -101.405,-309.398 -231.787,-367.662c-68.683,-30.693 -145.585,-38.322 -220.899,-26.69c-38.483,5.943 -75.058,15.5 -111.119,34.984c-40.088,21.659 -80.462,52.515 -112.068,89.093c-136.1,157.507 -125.121,391.967 -112.643,584.962c5.315,82.209 12.277,164.131 18.851,246.115c6.696,83.506 14.809,166.746 20.958,250.353c26.405,359.037 51.872,718.244 76.702,1077.56c24.83,359.312 49.098,718.723 72.277,1078.32c7.427,115.217 12.473,230.82 19.539,346.097c9.244,150.806 10.092,182.961 36.981,329.717c5.224,28.509 21.681,78.927 35.733,102.787c74.708,126.861 278.448,168.022 367.556,14.104Z" style={{ fill: '#3C4858', stroke: '#000', strokeWidth: '8.33px' }}/>
    //         <path d="M5494.13,8118.66c57.466,-46.604 85.935,-118.583 113.942,-184.069c86.902,-203.2 108.127,-253.581 199.657,-489.747c102.999,-265.759 191.48,-537.029 284.796,-806.409c93.315,-269.378 184.259,-539.591 275.087,-809.836c44.17,-131.422 86.368,-263.514 129.902,-395.152c1.79,-5.414 -2.067,11.392 -4.666,16.462c-1.034,2.018 -0.891,-4.647 -0.271,-6.831c0.985,-3.475 3.876,-6.18 4.937,-9.631c18.04,-58.707 36.13,-117.419 52.313,-176.681c16.655,-60.988 31.976,-122.364 46.18,-183.992c31.803,-137.981 -14.401,-293.07 -120.671,-390.063c-55.981,-51.094 -125.57,-86.269 -198.66,-104.974c-37.346,-9.557 -73.851,-15.42 -112.443,-12.909c-42.902,2.791 -88.446,13.054 -127.31,31.325c-167.356,78.679 -217.733,276.025 -256,439.762c-16.3,69.746 -30.993,139.874 -46.064,209.907c-15.351,71.334 -29.314,142.98 -45.2,214.191c-68.219,305.806 -137.355,611.4 -207.112,916.843c-69.755,305.441 -140.06,610.752 -211.425,915.805c-22.866,97.741 -48.047,194.907 -71.264,292.562c-30.373,127.753 -37.893,154.572 -50.8,285.615c-2.507,25.457 -0.222,73.189 6.687,98.131c36.733,132.611 215.686,243.065 338.385,149.691Z" style={{ fill: '#3C4858', stroke: '#000', strokeWidth: '8.33px' }}/>
    //       </g>
    //     </g>
    //   </g>
    // </svg>
    <div>
      <img
        style={{ width: props.width, height: props.height }}
        src={logoImg}
        alt=''
      />
      {/* <img style={{ fontSize: props.fontSize }} src={logoImg} alt='' /> */}
    </div>
  )
}
TrustyLogoBlack.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
}

export default TrustyLogoBlack

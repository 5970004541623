import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import InputProductComponent from '../StepUIProductComponent/InputComponents/InputProductComponent'
import TitleWithAddButton from '../StepUIProductComponent/AdditionalBlocks/TitleWithAddButton'
import FacilityFromComponent from '../StepUIProductComponent/FacilityFromComponent/FacilityFromComponent'
import FacilityToComponent from '../StepUIProductComponent/FacilityToComponent/FacilityToComponent'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   tabContainer: {
//     padding: theme.spacing(2),
//     height: '100%'
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function DeliverStepProduct(props) {
  const {
    track,
    trackIndex,
    isModifyEnabled,
    addInput,
    deleteInput,
    addFacilityFrom,
    addFacilityTo,
    inputChangedNewSelect,
    inputChanged,
    facilities,
    product,
    products,
    locationToChanged,
    locationFromChanged,
    deleteFacilityFrom,
    deleteFacilityTo,
    translation
  } = props
  // const classes = useStyles()

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='flex-start'
      spacing={2}
    >
      <Grid item xs={12} style={{ width: '100%' }}>
        <TitleWithAddButton
          trackIndex={trackIndex}
          isModifyEnabled={isModifyEnabled}
          addComponent={addFacilityFrom}
          translation={translation}
          position={'LocationFrom'}
        ></TitleWithAddButton>
        {track.locationFrom.map((locationFrom, locationIndex) => {
          return (
            <FacilityFromComponent
              key={`${locationFrom.facilityId}-${locationIndex}-${trackIndex}`}
              track={track}
              trackIndex={trackIndex}
              locationFrom={locationFrom}
              locationIndex={locationIndex}
              locationFromChanged={locationFromChanged}
              isModifyEnabled={isModifyEnabled}
              facilities={facilities}
              deleteFacilityFrom={deleteFacilityFrom}
              translation={translation}
            ></FacilityFromComponent>
          )
        })}
        <TitleWithAddButton
          translation={translation}
          trackIndex={trackIndex}
          isModifyEnabled={isModifyEnabled}
          addComponent={addFacilityTo}
          position={'LocationTo'}
        ></TitleWithAddButton>
        {track.locationTo.map((locationTo, locationIndex) => {
          return (
            <FacilityToComponent
              key={`${locationTo.facilityId}-${locationIndex}-${trackIndex}`}
              track={track}
              trackIndex={trackIndex}
              locationTo={locationTo}
              locationIndex={locationIndex}
              locationToChanged={locationToChanged}
              isModifyEnabled={isModifyEnabled}
              facilities={facilities}
              deleteFacilityTo={deleteFacilityTo}
              translation={translation}
            ></FacilityToComponent>
          )
        })}
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <TitleWithAddButton
          translation={translation}
          trackIndex={trackIndex}
          isModifyEnabled={isModifyEnabled}
          addComponent={addInput}
          position={'Inputs'}
        ></TitleWithAddButton>
        {track.inputs.map((input, inputIndex) => {
          return (
            <InputProductComponent
              key={`${input.productId}-${inputIndex}-${trackIndex}`}
              track={track}
              trackIndex={trackIndex}
              inputIndex={inputIndex}
              input={input}
              deleteInput={deleteInput}
              isModifyEnabled={isModifyEnabled}
              inputChangedNewSelect={inputChangedNewSelect}
              inputChanged={inputChanged}
              product={product}
              translation={translation}
              products={products}
              type={'materialInputs'}
            ></InputProductComponent>
          )
        })}
      </Grid>
    </Grid>
  )
}

DeliverStepProduct.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  inputChanged: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  addInput: PropTypes.func.isRequired,
  addFacilityFrom: PropTypes.func.isRequired,
  addFacilityTo: PropTypes.func.isRequired,
  deleteFacilityFrom: PropTypes.func.isRequired,
  deleteFacilityTo: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
}
export default DeliverStepProduct

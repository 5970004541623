/* eslint-disable no-undef*/
import loadImage from 'blueimp-load-image'
import axios from 'axios'
import ls from 'local-storage'
import { v4 as uuidv4 } from 'uuid'
import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import itLocale from 'date-fns/locale/it'
import currLang from './translations.js'
import validate from 'validate.js'
import mime from 'mime-types'
import configuration from '../configuration'

export const currOrganization = ls.get('currOrganization') || null
const REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

// limite di Bytes per i file da caricare
const BYTES_LIMIT = 3000000
// funzione che controlla le dimensioni di un file che deve essere caricato
export function checkFileSize(file) {
  return file.size <= BYTES_LIMIT
}

export function uuidValidate(uuid) {
  return typeof uuid === 'string' && REGEX.test(uuid)
}
export function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  var d = new Date(str)
  return d.toISOString() === str
}
// funzione che controlla se un'immagine ha degli attributi exif
async function checkExif(file) {
  const otherLoadImageOptions = await new Promise((resolve) => {
    loadImage.parseMetaData(file, (data) => {
      const o = {}
      if (data.exif && data.exif.get('Orientation')) {
        // se trova l'attributo orientamento negli exif allora li aggiunge alle opzioni da passare all'immagine
        o.orientation = data.exif.get('Orientation')
      }

      resolve(o)
    })
  })
  const loadImageOptions = { canvas: true }

  Object.assign(loadImageOptions, otherLoadImageOptions)

  return loadImageOptions
}

// Funzione che da una immagine caricata estrae il base64 ed il blob
export async function imageToBlobAndUrl(file) {
  if (!checkFileSize(file)) {
    throw new Error('imageTooLarge')
  }

  try {
    const loadImageOptions = await checkExif(file)

    const { blob, url, name } = await new Promise((resolve, reject) => {
      loadImage(
        file,
        (canvas) => {
          if (canvas.type === 'error') {
            return reject(canvas)
          }

          canvas.toBlob((blob) => {
            resolve({
              blob,
              url: canvas.toDataURL(file.type),
              name: file.name
            })
          }, file.type)
        },
        loadImageOptions
      )
    })

    return { blob, url, name }
  } catch (e) {
    console.log(e)
    throw new Error('error')
  }
}

// Funzione che dato un url ritorna un base64

export async function urlTobase64(url) {
  if (validate({ website: url }, { website: { url: true } })) {
    return url
  }
  try {
    const { data: blobResponse } = await axios.get(url, {
      responseType: 'blob'
    })
    const { file } = await new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.readAsDataURL(blobResponse)
      fr.addEventListener('loadend', () => {
        const base64File = fr.result
        const ext = url.slice(url.lastIndexOf('.') + 1)
        const type = mime.lookup(ext)
        if (type) {
          const file = base64File.replace('application/octet-stream', type)
          resolve({ file })
        } else {
          reject(type)
        }
      })
    })

    return file
  } catch (e) {
    throw new Error('error')
  }
}

// funzione che genera un id univoco
export function generateUuid() {
  return uuidv4()
}

// funzione che si occupa del caricamento di un file tramite il worker file-loader
export async function loadFile(blob, filename, organizationId) {
  console.log('function filename: ', filename)
  const formData = new FormData()
  // const stream = blob.stream()
  formData.set('filename', filename)
  formData.append('filecontent', blob)

  try {
    const response = await axios.post(
      `${configuration.apiBaseUrl}/marketing/projects/${organizationId}/files/`,
      formData,
      {
        headers: {
          authorization: `Bearer ${ls.get('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    const { url } = response.data.data
    console.log('function url: ', url)

    return url
  } catch (e) {
    console.error('loadFile error: ', e)
    throw new Error('loadingError')
  }
}

// oggetto per calendario in lingua italiana
export const locale = itLocale

// Classe che estende con l'italiano la lingua ed il formato dei calendari
export class ItLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'dd MMM yyyy', { locale: itLocale })
  }
}

// funzione che clona oggetto
export function deepCloneObj(obj) {
  return JSON.parse(JSON.stringify(obj))
}

// esport di tutte le lingue
export async function returnLanguages() {
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/marketing/languages`)
}

// esport di tutte le lingue
export async function returnTags() {
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/core/tags`)
}
// funzione per configurare dati onBoarding
export function getOnboardingConfig() {
  let onboardingConfig
  try {
    onboardingConfig = require(`../translations/${currLang}/onboardingUserguide.json`)
  } catch (e) {
    onboardingConfig = require('../translations/it/onboardingUserguide.json')
  }

  return onboardingConfig
}

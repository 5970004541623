import React from 'react'
import PropTypes from 'prop-types'
import ls from 'local-storage'

import {
  Typography,
  Popover,
  withStyles,
  Box,
  List,
  MenuItem,
  ListItemIcon,
  Select,
  FormControl
} from '@material-ui/core'

import { VpnKey, ExitToApp } from '@material-ui/icons'

import { withTranslation } from 'react-i18next'
import {
  changeLanguage,
  returnLangName,
  currLang,
  returnInClassicFormat
} from '../../utils/translations'

const styles = (theme) => ({
  popoverContainer: {
    width: '250px',
    height: '250px'
  },
  logoutItem: {
    borderTop: '1px solid rgba(193, 39, 45, 0.2)',
    padding: 0,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  listItem: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    transition: 'background-color .3s',
    '&:hover': {
      backgroundColor: 'rgba(193, 39, 45, 0.2)',
      transition: 'background-color .33s'
    }
  },
  secondary: {
    color: theme.secondary
  },
  primary: {
    color: theme.primary
  }
})

class Menu extends React.Component {
  constructor(props) {
    const userData = ls.get('user')
    super(props)
    // console.log('menu props => ', props)
    this.state = {
      userData: userData,
      currLang: returnInClassicFormat(currLang)
    }

    if (this.state.currLang === 'en-GB') {
      this.state.currLang = 'en-US'
    }

    this.handleLangChange = this.handleLangChange.bind(this)
  }

  // funzione che cambia lingua
  handleLangChange(event) {
    console.log('lingua cambiata, valore => ', event.target.value)
    this.setState({ currLang: event.target.value })
    changeLanguage(event.target.value)
  }

  render() {
    const {
      classes,
      anchorEl,
      handleClose,
      openChangePasswordModal,
      logout,
      anchorOrigin,
      transformOrigin
    } = this.props
    return (
      <Popover
        classes={{ paper: classes.popoverContainer }}
        transitionDuration={350}
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <React.Fragment>
          <Box width='100%' pl={2} pr={2} pt={2} pb={1}>
            <Typography variant='h6' component='h2'>
              {this.props.t('title')}
            </Typography>
          </Box>
          <Box width='100%' pl={2} pr={2}>
            <Typography
              className={classes.primary}
              variant='body2'
              component='h2'
            >
              {this.state.userData && this.state.userData.email !== ''
                ? this.state.userData.email
                : ''}
            </Typography>
          </Box>
          <List>
            <MenuItem onClick={openChangePasswordModal}>
              <Box display='flex' alignItems='center' pt={1} pb={1}>
                <VpnKey fontSize='small' />
                <Box ml={1}>
                  <Typography
                    color='inherit'
                    variant='body2'
                    component='span'
                    noWrap={true}
                  >
                    {this.props.t('changePassword')}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem>
              <Box
                display='flex'
                style={{ width: '100%' }}
                alignItems='center'
                pt={1}
                pb={1}
              >
                <FormControl style={{ width: '100%' }}>
                  {/* <InputLabel id="language-select-label">Age</InputLabel> */}
                  <Select
                    labelId='language-select-label'
                    id='language-select'
                    value={this.state.currLang}
                    onChange={this.handleLangChange}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={'it-IT'}>
                      {returnLangName('it-IT')}
                    </MenuItem>
                    <MenuItem value={'en-US'}>
                      {returnLangName('en-US')}
                    </MenuItem>
                    <MenuItem value={'fr-FR'}>
                      {returnLangName('fr-FR')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {/* <LanguageIcon fontSize="small" />
                <Box ml={1}>
                  <Typography color="inherit" variant="body2" component="span" noWrap={true}>
                    { returnLangName(this.state.currLang) }
                  </Typography>
                </Box> */}
              </Box>
            </MenuItem>
          </List>
          {/* <MenuLang
            id="lang-menu"
            anchorEl={this.state.anchorLang}
            keepMounted
            open={Boolean(this.state.anchorLang)}
            onClose={() => { this.closeLang(null) }}
          >
            <MenuItem onClick={() => { this.closeLang('it-IT') }}>{ returnLangName('it-IT') }</MenuItem>
            <MenuItem onClick={() => { this.closeLang('en-US') }}>{ returnLangName('en-US') }</MenuItem>
          </MenuLang> */}
          <List className={classes.logoutItem}>
            <MenuItem className={classes.listItem} onClick={logout}>
              <ListItemIcon>
                <Box display='flex' alignItems='center' pt={1} pb={1}>
                  <ExitToApp
                    classes={{ colorSecondary: classes.secondary }}
                    color='secondary'
                    fontSize='small'
                  />
                  <Box ml={1}>
                    <Typography
                      classes={{ colorSecondary: classes.secondary }}
                      color='secondary'
                      variant='body2'
                      component='span'
                      noWrap={true}
                    >
                      {this.props.t('logout')}
                    </Typography>
                  </Box>
                </Box>
              </ListItemIcon>
            </MenuItem>
          </List>
        </React.Fragment>
      </Popover>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  openChangePasswordModal: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  transformOrigin: PropTypes.object.isRequired,
  anchorOrigin: PropTypes.object.isRequired,
  anchorEl: PropTypes.object
}

export default withStyles(styles, { withTheme: true })(
  withTranslation('menu')(Menu)
)

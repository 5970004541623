import configuration from '../configuration'
import axios from 'axios'

export function authenticate(email, password, domain) {
  return axios.post(`${configuration.apiBaseUrl}/accounts/authenticate`, {
    email,
    password,
    domain
  })
}

/* utilizzata solo dai whitelabel per richiedere il token per la convalidazione della registrazione */
export function inviteWL(email, locale, domain) {
  return axios.post(
    `${configuration.apiBaseUrl}/accounts/invite`,
    {
      email,
      locale,
      domain
    },
    {
      headers: {
        contentType: 'application/json'
      }
    }
  )
}

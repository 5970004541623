import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import FbIcon from '@material-ui/icons/Facebook'
import LnIcon from '@material-ui/icons/LinkedIn'
import TwIcon from '@material-ui/icons/Twitter'
import IgIcon from '@material-ui/icons/Instagram'
import LanguageIcon from '@material-ui/icons/Language'

import {
  TextField,
  withStyles,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'

const styles = (theme) => ({
  '@global': {
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  wrapper: {
    margin: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonBase: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%'
  },
  primary: {
    color: theme.primary
  }
})

const socials = {
  facebook: {
    icon: <FbIcon style={{ color: 'white', width: '20px', height: '20px' }} />,
    color: '#4267B2',
    label: 'facebook',
    name: 'facebook'
  },
  twitter: {
    icon: <TwIcon style={{ color: 'white', width: '20px', height: '20px' }} />,
    color: '#1DA1F2',
    label: 'twitter',
    name: 'twitter'
  },
  linkedin: {
    icon: <LnIcon style={{ color: 'white', width: '20px', height: '20px' }} />,
    color: '#2867B2',
    label: 'linkedIn',
    name: 'linkedin'
  },
  instagram: {
    icon: <IgIcon style={{ color: 'white', width: '20px', height: '20px' }} />,
    color: '#F56040',
    label: 'instagram',
    name: 'instagram'
  },
  site: {
    icon: (
      <LanguageIcon style={{ color: 'white', width: '20px', height: '20px' }} />
    ),
    color: '#BDBDBD',
    label: 'sito ufficiale',
    name: 'site'
  }
}

class SocialInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showTextfield: false
    }

    this.toggleTextfield = this.toggleTextfield.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  toggleTextfield() {
    this.setState({ showTextfield: !this.state.showTextfield })
  }

  handleClose() {
    this.setState({ showTextfield: false })
  }

  render() {
    const { classes, social, disabled, value, onChange } = this.props
    return (
      <div className={classes.wrapper}>
        <ButtonBase
          disabled={disabled}
          title={`${socials[social || 'facebook'].label} link`}
          onClick={this.toggleTextfield}
          className={classes.buttonBase}
          style={
            disabled === true
              ? { backgroundColor: 'grey' }
              : { backgroundColor: socials[social || 'facebook'].color }
          }
        >
          {socials[social || 'facebook'].icon}
        </ButtonBase>

        <Dialog
          open={this.state.showTextfield}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogContent>
            <TextField
              fullWidth
              id={socials[social || 'facebook'].label}
              name={socials[social || 'facebook'].name}
              label={
                <span
                  style={{
                    color: disabled
                      ? 'inherit'
                      : socials[social || 'facebook'].color
                  }}
                >
                  {socials[social || 'facebook'].label}
                </span>
              }
              disabled={disabled}
              placeholder={this.props.placeholder}
              type='text'
              value={value}
              onChange={onChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.handleClose}
              color='primary'
            >
              Annulla
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.handleClose}
              color='primary'
            >
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

SocialInput.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  social: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

export default withRouter(withStyles(styles)(SocialInput))

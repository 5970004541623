import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnCompaniesCount() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(
    `${configuration.apiBaseUrl}/core/projects/${currOrganization.uuid}/companies/count`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: {
        filter: JSON.stringify({ status: 'active' })
      }
    }
  )
}

export async function editUrl(uuid, data) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda

  // mando la query
  const response = await axios.put(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/companies/${uuid}/updateUrl`,
    data,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
  // l'istanza di membership supplychain viene creato solo sui prodotti finiti

  return response
}

export async function returnCompanyByUuid(uuid) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!uuid) {
    return
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/companies/${uuid}`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// esport query per i prori ACL
export async function returnAllCompanies() {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/companies/`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// funzione che crea una nuova azienda
export async function returnNewCompany(newCompany) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!newCompany) {
    return
  }
  console.log('BEFORE QUERY newCompany => ', newCompany)
  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/`,
    newCompany,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}

// funzione che crea una nuova azienda
export async function createDefaultCompany(newCompany) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  console.log('BEFORE QUERY newCompany => ', newCompany)
  // mando la query
  return axios.post(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/companies/`,
    newCompany,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )
}
// funzione che modifica un'azienda
export async function editCompany(company) {
  // per prima cosa devo capire se l'azienda è di core o di marketing
  // se è di marketing
  let uuid
  if (company.resourceId) {
    uuid = company.uuid
  } else {
    uuid = company.core.uuid
  }
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!company) {
    return
  }

  console.log('BEFORE QUERY edit company => ', company)
  // mando la query
  const response = await axios.put(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/companies/${uuid}/`,
    company,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    }
  )

  return response
}

// funzione che elimina un'azienda
export async function deleteCompany(company) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!company) {
    return
  }
  console.log('BEFORE QUERY delete company => ', company)
  // mando la query

  let uuid
  if (company.resourceId) {
    uuid = company.uuid
  } else {
    uuid = company.core.uuid
  }
  return axios.delete(
    `${configuration.apiBaseUrl}/marketing/dashboard/projects/${currOrganization.uuid}/companies/${uuid}/`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      data: {
        type: 'company'
      }
    }
  )
}

export async function returnAllCompaniesProjection(params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(
    `${configuration.apiBaseUrl}/marketing/projects/${currOrganization.uuid}/companies/`,
    {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      },
      params: params
    }
  )
}

import React from 'react'
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Popup,
  Polyline
} from 'react-leaflet'
import PropTypes from 'prop-types'

class Map extends React.Component {
  constructor() {
    super()
    this.mapRef = React.createRef()
  }

  componentDidUpdate() {
    this.mapRef.current.leafletElement.invalidateSize()
  }

  render() {
    return (
      <LeafletMap
        boxZoom={false}
        onClick={(e) => this.props.onLocationFound(e)}
        ref={this.mapRef}
        center={[this.props.lat || 0, this.props.lng || 0]}
        scrollWheelZoom={false}
        touchZoom={true}
        zoom={this.props.zoom ? this.props.zoom : 15}
        style={this.props.isHeader ? { height: 200 } : { height: 280 }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        />
        {this.props.positions ? (
          this.props.positions.map((position, index) => (
            <Marker
              key={`marker-${index}`}
              position={[position[0], position[1]]}
            >
              <Popup>
                <a
                  style={{ textDecoration: 'none' }}
                  href={`https://www.google.com/maps/search/?api=1&query=${position[0]},${position[1]}`}
                  target='_blank'
                  rel='noopener noreferrer nofollow'
                >
                  Apri la mappa
                </a>
              </Popup>
            </Marker>
          ))
        ) : (
          <Marker position={[this.props.lat || 0, this.props.lng || 0]}>
            <Popup>
              <a
                style={{ textDecoration: 'none' }}
                href={`https://www.google.com/maps/search/?api=1&query=${this.props.lat || 0},${this.props.lng || 0}`}
                target='_blank'
                rel='noopener noreferrer nofollow'
              >
                Apri la mappa
              </a>
            </Popup>
          </Marker>
        )}
        {this.props.positions ? (
          <Polyline color='#09a963' positions={this.props.positions} />
        ) : (
          ''
        )}
      </LeafletMap>
    )
  }
}

Map.propTypes = {
  positions: PropTypes.array,
  lat: PropTypes.number,
  lng: PropTypes.number,
  onLocationFound: PropTypes.func,
  isHeader: PropTypes.bool,
  zoom: PropTypes.number
}

export default Map

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { CardMedia, Paper, IconButton } from '@material-ui/core'

import Edit from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  imgInput: {
    display: 'none'
  },
  tabText: {
    color: theme.primary
  }
}))

function BackgroundImage(props) {
  const classes = useStyles()
  const { product, isModifyEnabled, changeImage, translation } = props
  const { t } = useTranslation(translation)

  return (
    <div>
      <CardMedia
        style={{
          height: '180px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start'
        }}
        image={
          product.backgroundImage?.original || '/images/img-placeholder.png'
        }
        title={t('tabs.general.inputs.backgroundImage')}
      >
        <input
          className={classes.imgInput}
          id='add-new-image-background'
          type='file'
          accept='image/png, image/jpeg'
          onChange={(e) => changeImage(e, 'backgroundImage')}
          disabled={!isModifyEnabled}
        />
        <label htmlFor='add-new-image-background'>
          <Paper
            elevation={6}
            style={{ borderRadius: '50%', margin: '5px 5px 0' }}
          >
            <IconButton
              classes={{ colorPrimary: classes.tabText }}
              disabled={!isModifyEnabled}
              component='span'
              color='primary'
              style={isModifyEnabled ? {} : { display: 'none' }}
            >
              <Edit />
            </IconButton>
          </Paper>
        </label>
      </CardMedia>
    </div>
  )
}

BackgroundImage.propTypes = {
  product: PropTypes.object.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  changeImage: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default BackgroundImage

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import { domainToLogos } from '../../domainToLogos'
import {
  Card,
  CardActionArea,
  CardHeader,
  CardActions,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  withStyles,
  Box,
  Avatar,
  Snackbar,
  SnackbarContent,
  withWidth,
  Tooltip,
  Fab,
  Link,
  IconButton,
  Button
} from '@material-ui/core'

import TranslateIcon from '@material-ui/icons/Translate'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CreateIcon from '@material-ui/icons/Create'
// import { isLocalhost } from '../../serviceWorker'
import classNames from 'classnames'

import Pagination from 'material-ui-flat-pagination'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import {
  returnAllProducts,
  editProduct,
  duplicateProduct
} from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'
import { deepCloneObj } from '../../utils/utils'

import configuration from '../../configuration'
import CardModalProduct from '../Modal/CardModalProduct'
// import FormDialogProduct from '../../components/FormDialogProduct/FormDialogProduct.component'
import FormDialogProduct from './FormDialogProduct'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox.component'

import {
  Search as SearchIcon,
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  AddCircle as AddCircleIcon,
  ExitToApp as ExitToAppIcon,
  // Edit as EditIcon,
  Cancel
} from '@material-ui/icons'

import { green, amber } from '@material-ui/core/colors'

const doesProductMatchCriteria = (product, searchCriteria) => {
  const searchWords = searchCriteria.trim().split(/\s+/) // split searchCriteria into words

  return Object.values(product).some((value) => {
    if (typeof value === 'object' && value !== null) {
      return doesProductMatchCriteria(value, searchCriteria)
    } else {
      return searchWords.every((word) => {
        const regex = new RegExp(word, 'i') // 'i' flag for case-insensitive matching
        return String(value).match(regex)
      })
    }
  })
}

const styles = (theme) => ({
  '@global': {
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  progressStyle: {
    color: theme.primary
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: 'white',
    width: '100%'
  },
  archivedCard: {
    opacity: 0.6
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  // Blue gradient
  /* cardAvatar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
    // width: 400
  },
  selectContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 12
    }
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  success: {
    backgroundColor: green[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  mh5: {
    margin: '0 5px'
  },
  draftProduct: {
    backgroundColor: '#eaeaea',
    opacity: '0.8'
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  }
})
function RenderFrontEndUrl(domain) {
  if (domain.type === 'LISTED') {
    if (domain.domain === 'trusty') {
      if (domain.subDomains.length > 0 && domain.subDomains.length <= 1) {
        return `${domainToLogos[domain.subDomains[0]].frontendBaseUrl}`
      } else if (domain.subDomains.length > 1) {
        return `${domainToLogos[domain.subDomains[1]].frontendBaseUrl}`
      } else {
        return configuration.frontendBaseUrl
      }
    } else {
      return `${domainToLogos[domain.domain].frontendBaseUrl}`
    }
  } else {
    return configuration.frontendBaseUrl
  }
}

class Products extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.user = ls.get('user') || {}

    // if (!props.guidedLoaded) { window.userGuiding.hideChecklist()

    this.state = {
      // loadedGuide: false,
      showArchivedProducts: false,
      showSelection: false,
      areProductsFetching: true,
      companies: [],
      companyOfProductsToDelete: '',
      companyUuid: '',
      orderBy: '',
      productType: '',
      isConfirmDialogOpened: false,
      isDeleting: false,
      isFormDialogOpened: false,
      isProductModalOpened: false,
      lastSearchCriteria: '',
      limit: 12,
      isFels: this.props.isFels,
      offset: 0,
      owner: this.user.uuid,
      page: 0,
      products: [],
      selectedProducts: {},
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: '',
      frontEndUrl: RenderFrontEndUrl(this.props.domain)
    }

    this.changePage = this.changePage.bind(this)
    this.closeProductModal = this.closeProductModal.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeFormDialog = this.closeFormDialog.bind(this)
    this.companyOfProductsToDeleteChanged =
      this.companyOfProductsToDeleteChanged.bind(this)
    this.duplicateProduct = this.duplicateProduct.bind(this)
    this.filterProductsViaProductType =
      this.filterProductsViaProductType.bind(this)
    this.filterProductsViaCompanyUuid =
      this.filterProductsViaCompanyUuid.bind(this)
    this.filterProductsViaText = this.filterProductsViaText.bind(this)
    this.openProductModal = this.openProductModal.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.openFormDialog = this.openFormDialog.bind(this)
    this.refreshProducts = this.refreshProducts.bind(this)
    this.searchCriteriaChanged = this.searchCriteriaChanged.bind(this)
    this.typeChanged = this.typeChanged.bind(this)
    this.companyUuidChanged = this.companyUuidChanged.bind(this)
    this.selectItems = this.selectItems.bind(this)
    this.handleSelectedProducts = this.handleSelectedProducts.bind(this)
    this.orderByChanged = this.orderByChanged.bind(this)
    // this.archiveProducts = this.archiveProducts.bind(this)
    // this.archiveProduct = this.archiveProduct.bind(this)
    // this.archiveAllProducts = this.archiveAllProducts.bind(this)
    // this.restoreProducts = this.restoreProducts.bind(this)
    // this.restoreAllProducts = this.restoreAllProducts.bind(this)
    this.resetSelection = this.resetSelection.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.selectAllActive = this.selectAllActive.bind(this)
    this.deselectAllActive = this.deselectAllActive.bind(this)
    this.selectAllArchived = this.selectAllArchived.bind(this)
    this.deselectAllArchived = this.deselectAllArchived.bind(this)
    this.closeFormDialogWithoutRefresh =
      this.closeFormDialogWithoutRefresh.bind(this)
  }

  async componentDidMount() {
    this._isMounted = true
    this.refreshProducts()

    // const history = useHistory()
    // const userData = ls.get('user')
    // if (this.props.guidedLoaded) {
    //   const response = await axios({
    //     method: 'GET',
    //     url: `${configuration.apiBaseUrl}/accounts/self/onboardings/last`,
    //     headers: {
    //       authorization: `Bearer ${ls.get('token')}`
    //     }
    //   })

    //   const lastOnboarding = response.data.data

    //   // Quando la guida non esiste e gli step non sono stati completati
    //   if (!lastOnboarding || (!lastOnboarding.completed && !lastOnboarding.guides[onboardingConfig.products.id])) {
    //     let dataToSend = lastOnboarding
    //     if (!dataToSend) {
    //       dataToSend = {
    //         accountId: userData.uuid,
    //         guides: {
    //           [onboardingConfig.products.id]: {
    //             steps: []
    //           }
    //         },
    //         completed: false
    //       }
    //     }

    //     // window.userGuidingLayer.push({
    //     //   event: 'onload',
    //     //   fn: () => { this.setState({ loadedGuide: true }) }
    //     // })

    //     // window.userGuidingLayer.push({
    //     //   event: 'onPreviewStart',
    //     //   fn: () => { this.setState({ loadedGuide: true }) }
    //     // })

    //     window.userGuiding.previewGuide(onboardingConfig.products.id)

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewStep',
    //       fn: async data => {
    //         const d = new Date()
    //         if (!Object.prototype.hasOwnProperty.call(dataToSend.guides, onboardingConfig.products.id)) {
    //           dataToSend.guides[onboardingConfig.products.id] = { steps: [] }
    //         }

    //         if (typeof dataToSend.guides[onboardingConfig.products.id].steps[data.stepIndex] !== 'object') {
    //           dataToSend.guides[onboardingConfig.products.id].steps[data.stepIndex] = {}
    //         }

    //         dataToSend.guides[onboardingConfig.products.id].steps[data.stepIndex].startedAt = d
    //         if (data.stepIndex > 0) {
    //           dataToSend.guides[onboardingConfig.products.id].steps[data.stepIndex - 1].completedAt = d
    //         }

    //         if (lastOnboarding) {
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${lastOnboarding.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         } else {
    //           const response = await axios({
    //             method: 'POST',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })

    //           dataToSend = response.data.data
    //         }
    //       }
    //     })

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewEnd',
    //       fn: async data => {
    //         if (data.completed) {
    //           const { steps } = dataToSend.guides[onboardingConfig.products.id]
    //           steps[steps.length - 1].completedAt = new Date()
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${dataToSend.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         }
    //       }
    //     })
    //   } else {
    //   // this.setState({ loadedGuide: true })
    //   }
    //   // }

    // // // Quando la guida è completa
    // // if (lastOnboarding.guides[onboardingConfig.products.id] && lastOnboarding.guides[onboardingConfig.products.id].steps.length === onboardingConfig.products.steps) {
    // //   history.push('/lots')
    // // }
    // }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  changePage(offset, page) {
    this.setState({ offset, page: page - 1 })
  }

  setSnackbar(message, type) {
    this.setState({
      snackbarMessage: message,
      snackbarType: type,
      showSnackbar: true
    })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  // apre conferma prodotto per nuovo lotto

  // Resetta la selezione dei prodotti settando tutti i prodotti come non selezionati
  resetSelection() {
    const { products } = this.state
    const selectedProducts = {}
    products.forEach((product) => {
      selectedProducts[product.name] = false
    })

    this.setState({
      selectedProducts,
      showSelection: false,
      showArchivedProducts: false
    })
  }

  async closeProductModal() {
    const currentPage = this.state.page + 1
    const offset = this.state.offset
    this.setState({ isProductModalOpened: false })
    await this.refreshProducts()
    console.log('products: on close', this.state.products)
    console.log(
      'this.state.lastSearchCriteria',
      this.state.lastSearchCriteria,
      'this.state.productType',
      this.state.productType,
      this.state.companyUuid
    )
    if (this.state.lastSearchCriteria) {
      this.filterProductsViaText(this.state.lastSearchCriteria)
    }
    if (this.state.companyUuid) {
      this.filterProductsViaCompanyUuid(this.state.companyUuid)
    }
    if (this.state.productType) {
      this.filterProductsViaProductType(this.state.productType)
    }
    this.changePage(offset, currentPage)
  }

  closeConfirmDialog() {
    this.setState({ isConfirmDialogOpened: false, isDeleting: false })
  }

  companyOfProductsToDeleteChanged({ target }) {
    this.setState({ companyOfProductsToDelete: target.value })
  }

  async closeFormDialog() {
    await this.refreshProducts()
    this.setState({ isFormDialogOpened: false })
  }

  closeFormDialogWithoutRefresh() {
    this.setState({ isFormDialogOpened: false })
  }

  async duplicateProduct(product) {
    try {
      if (!product.uuid) {
        this.setSnackbar(
          this.props.t('notifications.duplicateErrorOnProductType'),
          'warning'
        )
        return
      }
      await duplicateProduct(product.uuid)
      await this.refreshProducts()
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  // funzione che aggiorna un prodotto
  async updateProduct(product, iHaveToAwait) {
    if (!product) {
      return
    }
    // prepato l'oggetto da inviare
    const objToSend = deepCloneObj(product)
    delete objToSend.companyData
    delete objToSend._id
    if (iHaveToAwait) {
      await editProduct(objToSend)
    } else {
      editProduct(objToSend)
    }
  }

  // funzione che filtra i prodotti
  filterProductsViaProductType(filterCriteria) {
    const { originalProducts, companyUuid, lastSearchCriteria } = this.state

    let filteredProducts = originalProducts

    if (filterCriteria) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productType === filterCriteria
      )
    }

    console.log('filteredProducts', filteredProducts)
    if (companyUuid) {
      filteredProducts = filteredProducts.filter(
        (product) => product.core.companyId === companyUuid
      )
    }
    console.log('filteredProducts', filteredProducts)

    if (lastSearchCriteria) {
      filteredProducts = filteredProducts.filter((product) =>
        doesProductMatchCriteria(product, lastSearchCriteria)
      )
    }

    console.log(
      'filterCriteria',
      filterCriteria,
      'companyUuid',
      companyUuid,
      'lastSearchCriteria',
      lastSearchCriteria
    )
    this.setState({
      productType: filterCriteria,
      products: filteredProducts,
      offset: 0,
      page: 0
    })
  }

  // funzione che filtra per azienda
  filterProductsViaCompanyUuid(filterCriteria) {
    let products = this.state.originalProducts
    if (filterCriteria !== '') {
      products = products.filter(
        (product) => product.core.companyId === filterCriteria
      )
    }

    if (this.state.productType !== '') {
      products = products.filter(
        (product) => product.productType === this.state.productType
      )
    }

    if (this.state.lastSearchCriteria !== '') {
      products = products.filter((product) =>
        doesProductMatchCriteria(product, this.state.lastSearchCriteria)
      )
    }

    this.setState({
      companyUuid: filterCriteria,
      products,
      offset: 0,
      page: 0
    })
  }

  filterProductsViaText(searchCriteria) {
    const { originalProducts, productType, companyUuid } = this.state

    let filteredProducts = originalProducts

    if (searchCriteria) {
      filteredProducts = filteredProducts.filter((product) =>
        doesProductMatchCriteria(product, searchCriteria)
      )
    }

    if (productType) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productType === productType
      )
    }

    if (companyUuid) {
      filteredProducts = filteredProducts.filter(
        (product) => product.core.companyId === companyUuid
      )
    }

    this.setState({ products: filteredProducts, offset: 0, page: 0 })
  }

  async refreshProducts() {
    try {
      const companiesResponse = await returnAllCompanies()

      const root = document.getElementById('root')
      root.className = ''
      root.classList.add('products')

      const companies = companiesResponse.data.data
      console.log('refreshed companies => ', companies)
      // const companiesIds = companies.map(company => company.companyId)

      const productsResponse = await returnAllProducts()

      const products = productsResponse.data.data
      // console.log('refreshed allProducts => ', allProducts)
      products.forEach((product) => {
        // if (!product.status) {
        //   product.status = 'active'
        // }
        const company = companies.find((c) => c.uuid === product.companyId)
        product.companyData = company
        // add empty object to render for allergens
      })
      if (this._isMounted) {
        const currCompany = companies.length > 0 ? companies[0].uuid : ''
        // const selectedProducts = products.map(product => ({ name: product.name, selected: false }))
        const selectedProducts = {}
        products.forEach((product) => {
          selectedProducts[product.name] = false
        })
        // console.log('currCompany => ', currCompany)
        console.log('refreshed products => ', products)
        if (this.state.orderBy === 'name') {
          products.sort(function (a, b) {
            if (a.core.name < b.core.name) {
              return -1
            }
            if (a.core.name > b.core.name) {
              return 1
            }
            return 0
          })
        }
        this.setState({
          selectedProducts,
          areProductsFetching: false,
          companies,
          companyOfProductsToDelete: currCompany,
          products,
          originalProducts: JSON.parse(JSON.stringify(products))
        })
      }
    } catch (e) {
      console.error('Error while refreshing products: ', e)
      this.setState({
        snackbarMessage: this.props.t('notifications.productError'),
        snackbarType: 'error',
        showSnackbar: true
      })
      // alert('Impossibile riscaricare i prodotti.')
    }
  }

  searchCriteriaChanged({ target }) {
    this.setState({ lastSearchCriteria: target.value })
    this.filterProductsViaText(target.value)
  }

  openProductModal(product) {
    this.setState({ product, isProductModalOpened: true })
  }

  openConfirmDialog() {
    this.setState({ isConfirmDialogOpened: true })
  }

  selectItems() {
    this.setState({ showSelection: !this.state.showSelection })
  }

  openFormDialog() {
    this.setState({ isFormDialogOpened: true })
  }

  handleSelectedProducts({ target }) {
    const selectedProducts = this.state.selectedProducts
    selectedProducts[target.name] = !selectedProducts[target.name]
    this.setState({ selectedProducts })
  }

  // funzione seleziona tutti i prodotti
  selectAllActive() {
    const { selectedProducts, products } = this.state
    products.forEach((product) => {
      selectedProducts[product.name] = true
    })
    this.setState({ selectedProducts, products })
  }

  // funzione seleziona tutti i prodotti
  deselectAllActive() {
    const { selectedProducts, products } = this.state
    products.forEach((product) => {
      selectedProducts[product.name] = false
    })
    this.setState({ selectedProducts, products })
  }

  // funzione seleziona tutti i prodotti
  selectAllArchived() {
    const { selectedProducts, products } = this.state
    products.forEach((product) => {
      selectedProducts[product.name] = true
    })
    this.setState({ selectedProducts, products })
  }

  // funzione seleziona tutti i prodotti
  deselectAllArchived() {
    const { selectedProducts, products } = this.state
    products.forEach((product) => {
      selectedProducts[product.name] = false
    })
    this.setState({ selectedProducts, products })
  }

  companyUuidChanged({ target }) {
    this.filterProductsViaCompanyUuid(target.value)
  }

  typeChanged({ target }) {
    this.filterProductsViaProductType(target.value)
  }

  orderByChanged({ target }) {
    if (target.value === 'name') {
      const products = this.state.products.sort(function (a, b) {
        if (a.core.name < b.core.name) {
          return -1
        }
        if (a.core.name > b.core.name) {
          return 1
        }
        return 0
      })
      this.setState({ products, orderBy: target.value })
    } else {
      const products = JSON.parse(JSON.stringify(this.state.originalProducts))
      this.setState({ products, orderBy: target.value })
    }
  }

  render() {
    // console.log('state: ', this.state)
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <div>
        {/* <Loader
          show={!this.state.loadedGuide}
          // text={this.props.t('guide.loading')}
        >
        </Loader> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={
              this.state.snackbarType === 'error'
                ? classes.error
                : this.state.snackbarType === 'warning'
                ? classes.warning
                : classes.success
            }
            aria-describedby={
              this.state.snackbarType === 'error'
                ? 'error-snackbar'
                : 'success-snackbar'
            }
            message={
              <span id='error-snackbar' className={classes.message}>
                {this.state.snackbarType === 'error' ? (
                  <ErrorIcon
                    className={classNames(classes.icon, classes.iconVariant)}
                  />
                ) : (
                  <SuccessIcon
                    className={classNames(classes.icon, classes.iconVariant)}
                  />
                )}
                {this.state.snackbarMessage}
                <Cancel
                  onClick={() => this.setState({ showSnackbar: false })}
                  className={classNames(classes.icon, classes.iconVariantClose)}
                />
              </span>
            }
          />
        </Snackbar>

        {/* STEP: creazione prodotto */}
        {this.state.isFormDialogOpened ? (
          <FormDialogProduct
            setSnackbar={this.setSnackbar}
            open={this.state.isFormDialogOpened}
            onCloseDialog={this.closeFormDialog}
            onCloseDialogWithoutRefresh={this.closeFormDialogWithoutRefresh}
          />
        ) : null}

        {/* STEP: modifica prodotto */}
        {this.state.isProductModalOpened ? (
          <CardModalProduct
            isFels={this.state.isFels}
            setSnackbar={this.setSnackbar}
            product={this.state.product}
            open={this.state.isProductModalOpened}
            onCloseModal={this.closeProductModal}
            frontEndUrl={this.state.frontEndUrl}
          />
        ) : null}

        {/* STEP: search bar + buttons */}
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          className={`${classes.root} filterRoot`}
        >
          <Grid item xs={10} sm={10} md={11} lg={11}>
            <Box className={classes.paperContainer}>
              <Paper className={classes.paper}>
                <TextField
                  className={`${classes.input} searchField`}
                  placeholder={this.props.t('inputs.search')}
                  inputProps={{ 'aria-label': this.props.t('inputs.search') }}
                  onChange={this.searchCriteriaChanged}
                />
                <SearchIcon className={classes.searchIcon} />
              </Paper>
            </Box>
          </Grid>

          <Grid item style={{ textAlign: 'right' }}>
            <Tooltip title={this.props.t('tooltip.buttons.addProduct')}>
              <Fab
                size='medium'
                aria-label={this.props.t('tooltip.buttons.addProduct')}
                variant='round'
                className={classes.addButton}
                onClick={this.openFormDialog}
              >
                <AddCircleIcon />
              </Fab>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className={`${classes.selectContainer} formPr`}>
              <InputLabel shrink htmlFor='productType'>
                {this.props.t('inputs.typeFilter')}:
              </InputLabel>
              <Select
                id='productType'
                name='productType'
                value={this.state.productType}
                onChange={this.typeChanged}
                displayEmpty
                fullWidth
              >
                <MenuItem key='all' value=''>
                  <span style={{ color: '#AEAEAE' }}>
                    {this.props.t('inputs.allProducts')}
                  </span>
                </MenuItem>
                <MenuItem key={'rawMaterial'} value={'rawMaterial'}>
                  {this.props.t('inputs.rawMaterial')}
                </MenuItem>
                <MenuItem key={'intermediateGood'} value={'intermediateGood'}>
                  {this.props.t('inputs.intermediateGood')}
                </MenuItem>
                <MenuItem key={'finishedGood'} value={'finishedGood'}>
                  {this.props.t('inputs.finishedGood')}
                </MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className={`${classes.selectContainer} formPl`}>
              <InputLabel shrink htmlFor='companyUuid'>
                {this.props.t('inputs.companyFilter')}:
              </InputLabel>
              <Select
                id='companyUuid'
                name='companyUuid'
                value={this.state.companyUuid}
                onChange={this.companyUuidChanged}
                displayEmpty
                fullWidth
              >
                <MenuItem key='all' value=''>
                  <span style={{ color: '#AEAEAE' }}>
                    {this.props.t('inputs.allProducts')}
                  </span>
                </MenuItem>
                {this.state.companies.map((company) => (
                  <MenuItem key={company.core.uuid} value={company.core.uuid}>
                    {company.core.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className={`${classes.selectContainer} formPr`}>
              <InputLabel shrink htmlFor="productType">{ this.props.t('inputs.orderBy') }:</InputLabel>
              <Select
                id="orderBy"
                name="orderBy"
                value={this.state.orderBy}
                onChange={this.orderByChanged}
                displayEmpty
                fullWidth
              >
                <MenuItem key={'default-order'} value=""><span style={{ color: '#AEAEAE' }}>{ this.props.t('inputs.default') }</span></MenuItem>
                <MenuItem key={'name'} value='name'>
                  { this.props.t('inputs.name') }
                </MenuItem>
              </Select>
            </Box>
          </Grid> */}
          {/* {!isSmall &&
            <Grid item xs={2} sm={2} md={1} lg={1} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Tooltip title={this.props.t('tooltip.buttons.addProduct')}>
                <Fab
                  size="medium"
                  aria-label={this.props.t('tooltip.buttons.addProduct')}
                  variant="round"
                  className={classes.addButton}
                  onClick={this.openFormDialog}
                >
                  <AddCircleIcon />
                </Fab>
              </Tooltip>
            </Grid>
          } */}
        </Grid>

        {/* STEP: search bar + buttons */}
        {/* STEP: archiviazione prodotto */}
        {/* <Selection
          resource={{ singular: this.props.t('inputs.selection.single'), plural: this.props.t('inputs.selection.plural') }}
          selectItems={this.selectItems}
          selectedItems={this.state.selectedProducts}
          items={this.state.products}
          showArchived={this.state.showArchivedProducts}
          toggleArchived={() => this.setState({ showArchivedProducts: !this.state.showArchivedProducts })}
          show={this.state.showSelection}
          openDialog={this.openFormDialog}
          archiveAll={this.archiveAllProducts}
          archive={this.archiveProducts}
          restoreAll={this.restoreAllProducts}
          restore={this.restoreProducts}
        /> */}

        {/* STEP: Lista prodotti */}
        <Grid container direction='column'>
          <Grid item xs>
            {this.state.products.length > 12 && (
              <Pagination
                limit={this.state.limit}
                offset={this.state.offset}
                total={this.state.products.length}
                onClick={(e, offset, page) => this.changePage(offset, page)}
                style={{ textAlign: 'center', paddingBottom: 2 }}
              />
            )}
          </Grid>
          <Grid item xs className={classes.cardListContainer}>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
              className={classes.grid}
              spacing={3}
            >
              {this.state.areProductsFetching ? (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                  xs={12}
                >
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={this.props.t('loading')}
                    ></LogoLoader>
                  </Grid>
                </Grid>
              ) : (
                this.state.products
                  .slice(
                    this.state.page * this.state.limit,
                    this.state.page * this.state.limit + this.state.limit
                  )
                  .map(
                    (product, index) =>
                      product.core.uuid.indexOf('supplychain') !== 0 && ( // NOTE: nonostante sia rimossa tutta la logica della supplychain questo lo manteniamo perché riguarda gli uuid e potrebbe capitare che qualche uuid sia supplycnhain (nell import dei dati vecchi)
                        <Grid
                          key={`${product.core.uuid}#${index}`}
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={3}
                          zeroMinWidth
                          className={classes.cardContainer}
                        >
                          <Card>
                            <div
                              className='innerCard'
                              style={
                                isSmall
                                  ? {}
                                  : {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }
                              }
                            >
                              <CardActionArea
                                onClick={() =>
                                  this.state.showSelection
                                    ? this.handleSelectedProducts({
                                        target: { name: product.core.name }
                                      })
                                    : this.openProductModal(product)
                                }
                              >
                                <div className='borderFrame'>
                                  <Tooltip
                                    title={this.props.t(
                                      'productList.tooltip.buttons.editProduct'
                                    )}
                                  >
                                    <CreateIcon
                                      className='editIcon'
                                      onClick={() =>
                                        this.openProductModal(product)
                                      }
                                    ></CreateIcon>
                                  </Tooltip>

                                  <CardHeader
                                    className={classes.cardHeaderContainer}
                                    avatar={
                                      this.state.showSelection ? (
                                        <CustomCheckbox
                                          checked={
                                            Object.entries(
                                              this.state.selectedProducts
                                            ).length > 0 &&
                                            Object.prototype.hasOwnProperty.call(
                                              this.state.selectedProducts,
                                              product.core.name
                                            )
                                              ? this.state.selectedProducts[
                                                  product.core.name
                                                ]
                                              : ''
                                          }
                                          value={
                                            Object.entries(
                                              this.state.selectedProducts
                                            ).length > 0 &&
                                            Object.prototype.hasOwnProperty.call(
                                              this.state.selectedProducts,
                                              product.core.name
                                            )
                                              ? this.state.selectedProducts[
                                                  product.core.name
                                                ]
                                              : ''
                                          }
                                          name={product.core.name}
                                          color='primary'
                                        />
                                      ) : (
                                        <Avatar
                                          className={classes.cardAvatar}
                                          aria-label='product'
                                        >
                                          {/* {product.type === 'rawMaterial' ? 'M' : product.type === 'intermediateGood' ? 'S' : 'P'} */}

                                          {product.profileImage != null ? (
                                            <img
                                              alt=''
                                              style={
                                                isSmall
                                                  ? { height: 120, width: 120 }
                                                  : { width: 180, height: 180 }
                                              }
                                              src={product.profileImage.cropped}
                                            ></img>
                                          ) : product.productType ===
                                            'rawMaterial' ? (
                                            <span
                                              className='avatarPl'
                                              style={
                                                isSmall
                                                  ? { height: 120, width: 120 }
                                                  : { width: 180, height: 180 }
                                              }
                                            >
                                              M
                                            </span>
                                          ) : product.productType ===
                                            'intermediateGood' ? (
                                            <span
                                              className='avatarPl'
                                              style={
                                                isSmall
                                                  ? { height: 120, width: 120 }
                                                  : { width: 180, height: 180 }
                                              }
                                            >
                                              S
                                            </span>
                                          ) : (
                                            <span
                                              className='avatarPl'
                                              style={
                                                isSmall
                                                  ? { height: 120, width: 120 }
                                                  : { width: 180, height: 180 }
                                              }
                                            >
                                              P
                                            </span>
                                          )}
                                        </Avatar>
                                      )
                                    }
                                  />
                                </div>
                                <div className='bottomCard'>
                                  {/* <CardContent style={{ paddingTop: 0, paddingBottom: 10 }}>
                                  <Typography variant="body2" component="p" noWrap={true}>
                                    { this.props.t('productList.description') }: { product.description }
                                  </Typography>
                                </CardContent> */}

                                  <Tooltip title={product.core.name}>
                                    <CardHeader
                                      className={classes.cardHeaderContainer}
                                      title={product.core.name}
                                      titleTypographyProps={{
                                        component: 'h5',
                                        variant: 'h6',
                                        noWrap: true
                                      }}
                                      subheader={`${this.props.t(
                                        'productList.type'
                                      )}: ${this.props.t(
                                        'inputs.' + product.productType
                                      )}`}
                                      subheaderTypographyProps={{
                                        noWrap: true
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </CardActionArea>
                              <CardActions>
                                <Grid
                                  container
                                  direction='row'
                                  justify='center'
                                  alignItems='center'
                                >
                                  <>
                                    <Tooltip
                                      title={this.props.t(
                                        'productList.tooltip.buttons.goToPage'
                                      )}
                                    >
                                      <Link
                                        aria-label='settings'
                                        target='_blank'
                                        rel='noopener'
                                        href={`${this.state.frontEndUrl}/${product.core.gtin}/1`}
                                        style={{ color: '#319c5a' }}
                                      >
                                        <Button
                                          variant='outlined'
                                          style={{
                                            margin: '5px'
                                          }}
                                        >
                                          {this.props.t(
                                            'productList.tooltip.buttons.goToPage'
                                          )}
                                        </Button>
                                      </Link>
                                    </Tooltip>
                                  </>

                                  <Tooltip
                                    title={this.props.t(
                                      'productList.tooltip.buttons.duplicateProduct'
                                    )}
                                  >
                                    <Link
                                      aria-label='settings'
                                      target='_blank'
                                      rel='noopener'
                                      onClick={() =>
                                        this.duplicateProduct(product)
                                      }
                                    >
                                      <Button
                                        variant='outlined'
                                        style={{
                                          margin: '5px'
                                        }}
                                      >
                                        {this.props.t(
                                          'productList.tooltip.buttons.duplicateProduct'
                                        )}
                                      </Button>
                                    </Link>
                                  </Tooltip>

                                  <Tooltip
                                    title={this.props.t(
                                      'productList.tooltip.buttons.translate'
                                    )}
                                  >
                                    <Link
                                      component='button'
                                      variant='body2'
                                      onClick={() => {
                                        this.props.location.pathname = `/products/${product.uuid}`
                                        this.props.history.push(
                                          `/products/${product.uuid}`
                                        )
                                      }}
                                    >
                                      <Button
                                        variant='outlined'
                                        style={{
                                          margin: '5px'
                                        }}
                                      >
                                        {this.props.t(
                                          'productList.tooltip.buttons.translate'
                                        )}
                                      </Button>
                                    </Link>
                                  </Tooltip>

                                  {/* <Link target="_blank" style={{ color: '#319c5a' }} rel="noopener" href={`${configuration.frontendBaseUrl}/gtin/${product.gtin}`}>
                                Vai alla pagina
                              </Link> */}
                                </Grid>
                              </CardActions>
                            </div>
                          </Card>
                        </Grid>
                      )
                  )
              )}
            </Grid>
          </Grid>
          <Grid item xs>
            {this.state.areProductsFetching === false && (
              <Pagination
                limit={this.state.limit}
                offset={this.state.offset}
                total={this.state.products.length}
                onClick={(e, offset, page) => this.changePage(offset, page)}
                style={{ textAlign: 'center', paddingTop: 8 }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(
  withStyles(styles)(withWidth()(withTranslation('products')(Products)))
)

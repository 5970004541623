import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withWidth,
  Typography,
  IconButton,
  TextField
} from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

const styles = (theme) => ({
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  navBar: {
    color: 'white'
  },
  primary: {
    color: theme.primary
  }
})

class ChangePasswordDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      pass1: '',
      pass2: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { open, onClose, width, classes, changePassword } = this.props
    const { oldPassword, pass1, pass2 } = this.state
    return (
      <Dialog
        className='changePassword'
        fullWidth={true}
        fullScreen={width === 'xs' || width === 'sm'}
        maxWidth={'sm'}
        onClose={onClose}
        open={open}
      >
        <DialogTitle
          classes={{ root: classes.titleMobile }}
          disableTypography={true}
          className={classes.navBar}
        >
          <Typography
            style={{ width: '95%' }}
            noWrap={true}
            variant='h6'
            component='h2'
          >
            Cambia Password
          </Typography>
          <IconButton
            style={{ justifySelf: 'flex-end' }}
            color='inherit'
            aria-label='close modal'
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off' autoCapitalize='off'>
            <TextField
              fullWidth
              type='password'
              label={this.props.t('inputs.oldPassword')}
              name='oldPassword'
              required
              onChange={this.handleChange}
              value={this.state.oldPassword}
            />
            <TextField
              id='pass1'
              name='pass1'
              label={this.props.t('inputs.password')}
              type='password'
              fullWidth
              value={this.state.pass1}
              onChange={this.handleChange}
              margin='normal'
            />
            <TextField
              id='pass2'
              name='pass2'
              label={this.props.t('inputs.confirmPassword')}
              type='password'
              fullWidth
              value={this.state.pass2}
              onChange={this.handleChange}
              margin='normal'
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annulla</Button>
          <Button
            onClick={() => changePassword(oldPassword, pass1, pass2)}
            classes={{ textPrimary: classes.primary }}
            title='conferma'
            color='primary'
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ChangePasswordDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired
}

export default withStyles(styles, { withTheme: true })(
  withWidth()(withTranslation('changePassword')(ChangePasswordDialog))
)

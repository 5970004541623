import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/styles'
import { Grid, Card, Container, CardHeader, Box } from '@material-ui/core'
import CompleteInvitationForm from './CompleteInvitationForm'
import DomainLogo from '..//DomainLogo/DomainLogo'

import Message from '../Message'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center'
//   },
//   brand: {
//     height: '130px'
//   },
//   emailSent: {
//     width: '180px'
//   }
// }))

// window.userGuiding.hideChecklist()
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function CompleteInvitation(props) {
  const { token, email } = useParams()
  const { t } = useTranslation('completeInvitation')
  const [statusCode, setStatusCode] = useState(null)
  // window.userGuiding.hideChecklist()

  const selectErrorCase = (code) => {
    switch (code) {
      case 400:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.badRequest')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.badRequest')}
            </Message>
          </Box>
        )
      case 500:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.applicationError')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.applicationError')}
            </Message>
          </Box>
        )
      case 404:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.notFound')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.notFound')}
            </Message>
          </Box>
        )
      case 403:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.forbidden')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.forbidden')}
            </Message>
          </Box>
        )
      case 409:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.conflict')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.conflict')}
            </Message>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <div className='test'>
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ height: '100vh;' }}
      >
        <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
          <Card className={'signContainer'}>
            <DomainLogo url={window.location.host}></DomainLogo>
            {statusCode ? (
              <Container>{selectErrorCase(statusCode)}</Container>
            ) : (
              <>
                <CardHeader
                  className='signInHeader'
                  title={t('title')}
                  titleTypographyProps={{ variant: 'h6' }}
                  subheader={t('subtitle', {
                    whitelabel: capitalize(props.domain.domain)
                  })}
                />
                <CompleteInvitationForm
                  domain={props.domain}
                  email={email}
                  token={token}
                  changeStatusCode={setStatusCode}
                />
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default CompleteInvitation

import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, Box, Container, Card, CardHeader } from '@material-ui/core'
import Message from '../Message'
import ChangePasswordForm from './ChangePasswordForm'
import { useTranslation } from 'react-i18next'
import DomainLogo from '../../components/DomainLogo/DomainLogo'

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center'
//   },
//   brand: {
//     height: '130px'
//   }
// }))

function ChangePassword(props) {
  const { token, email } = useParams()
  const history = useHistory()
  const { t } = useTranslation('changePassword')

  const [statusCode, setStatusCode] = useState(null)

  const selectErrorCase = (code) => {
    switch (code) {
      case 200:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.loginButton')}
              action={() => history.push('/signin')}
            >
              {t('notifications.success')}
            </Message>
          </Box>
        )
      case 400:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.insertPasswordButton')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.badRequest')}
            </Message>
          </Box>
        )
      case 403:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.insertPasswordButton')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.forbidden')}
            </Message>
          </Box>
        )
      case 404:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.insertPasswordButton')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.notFound')}
            </Message>
          </Box>
        )
      case 500:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message
              buttonText={t('notifications.insertPasswordButton')}
              action={() => setStatusCode(null)}
            >
              {t('notifications.applicationError')}
            </Message>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={12} md={5} sm={8}>
        <Card>
          <DomainLogo url={window.location.host}></DomainLogo>
          {statusCode ? (
            <Container>{selectErrorCase(statusCode)}</Container>
          ) : (
            <>
              <CardHeader
                title={t('title')}
                titleTypographyProps={{ variant: 'h6' }}
                subheader={t('subtitle')}
              />
              <ChangePasswordForm
                email={email}
                token={token}
                changeStatusCode={setStatusCode}
              />
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default ChangePassword

/* eslint-disable no-undef*/

const manifest = require('../package.json')
const configuration = {
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  xApiKey: process.env.REACT_APP_PUBLIC_API_KEY,
  environment: process.env.REACT_APP_ENV,
  frontendBaseUrl:
    process.env.REACT_APP_FRONTEND_BASE_URL || 'https://m.trustyqr.com',
  blockchainExplorer: 'https://www.blockchain.com',
  sentry: {
    dsn: 'http://4d0576d1baab4ab990c5c6ee9abb1daf@sentry.apio.cloud/63'
  },
  smartlook: {
    apiKey: '921799a88e1f5fb5267f7a1bd3a3c63b5c80d642'
  }
}

export default configuration

import { useState, useEffect, useCallback } from 'react'
import { userDetails } from '../../utils/acl-organization'
import { requestEmbededUrl } from '../../utils/retool'

const trustyIntegration = {
  externalIdentifier: '7c2b7d8d-5738-4ff9-99f3-eb19c25e3bdd',
  userInfo: {
    firstName: 'Trusty',
    lastName: 'Integrations',
    email: 'trustyintegrations@trustyteam.it'
  }
}

const useEmbededUrl = ({ pageUuid, groupIdsArray }) => {
  // Variabili di stato di base per la lista di risorse
  const [isLoading, setIsLoading] = useState(false)
  const [embededUrl, setEmbededUrl] = useState('')

  const { projectId, apikey } = userDetails()

  const data = JSON.stringify({
    landingPageUuid: pageUuid,
    groupIds: groupIdsArray,
    externalIdentifier: trustyIntegration.externalIdentifier,
    userInfo: trustyIntegration.userInfo,
    metadata: {
      projectId,
      apiKey: apikey
    }
  })

  // Funzione che esegue la chiamata delle risorse
  const getEmbededUrl = async (data) => {
    const res = await requestEmbededUrl(data)
    setEmbededUrl(res)
  }

  // Funzione che ricarica la lista delle risorse forzatamente
  const reloadResource = () => {
    initializeResource(data)
  }

  const initializeResource = useCallback(async (data) => {
    setIsLoading(true)

    await getEmbededUrl(data)

    setIsLoading(false)
  }, [])

  useEffect(() => {
    initializeResource(data)
  }, [])

  const baseValues = { isLoading, embededUrl, reloadResource }
  return baseValues
}

export { useEmbededUrl }

import { withRouter } from 'react-router-dom'
import React from 'react'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  TextField,
  withStyles,
  IconButton,
  Box,
  Paper,
  Typography,
  withWidth,
  Tooltip
} from '@material-ui/core'
import Map from '../Map/Map.component'
import Info from '@material-ui/icons/Info'
// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import { editFacility, deleteFacility } from '../../utils/facilities'
// import { returnSomeProducts } from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'
import { deepCloneObj } from '../../utils/utils'
// import configuration from '../../configuration.js'

import CloseIcon from '@material-ui/icons/Close'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = (theme) => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  navBar: {
    color: 'white'
  },
  // Blue gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tableContainer: {
    marginTop: 16
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  primary: {
    color: theme.primary
  },
  warn: {
    color: 'red'
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
})

class CardModal extends React.Component {
  constructor(props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = {
      facility: {
        ...props.facility
      },
      companies: [],
      isConfirmDialogOpened: false,
      isSaveDialogOpen: false,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      open: props.open,
      modified: false,
      saveFromClose: false,
      mapError: false
    }

    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deleteFacility = this.deleteFacility.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.fieldChanged = this.fieldChanged.bind(this)
    this.addressChanged = this.addressChanged.bind(this)
    this.geoChanged = this.geoChanged.bind(this)
    this.coreFieldChanged = this.coreFieldChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.saveModify = this.saveModify.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.savePosition = this.savePosition.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.getKeyByValue = this.getKeyByValue.bind(this)
  }

  /* in teoria questo componentDidUpdate può essere sostituito con il componentDidMount */
  // async componentDidUpdate (prevProps) {
  //   console.log('sono nel didUpdate')
  //   if (prevProps.open !== this.props.open) {
  //     if (this.props.open) {
  //       const facility = this.state.facility
  //       if (!Object.prototype.hasOwnProperty.call(facility, 'address')) {
  //         facility.address = {
  //           country: '',
  //           state: '',
  //           city: '',
  //           postalCode: '',
  //           street: ''
  //         }
  //       }
  //       if (!Object.prototype.hasOwnProperty.call(facility, 'geolocation')) {
  //         facility.geolocation = {
  //           lat: 41.896187,
  //           lng: 12.492046,
  //           parcels: []
  //         }
  //       }

  //       const companyResponse = await returnAllCompanies()
  //       // console.log('companyResponse.data => ', companyResponse.data)
  //       this.setState({ companies: companyResponse.data.data })

  //       console.log('currFacility => ', facility)
  //       this.setState({ facility })
  //     }

  //     this.setState({ open: this.props.open })
  //   }
  // }

  async componentDidMount() {
    const facility = this.state.facility

    if (!Object.prototype.hasOwnProperty.call(facility.core, 'geolocation')) {
      facility.core.geolocation = {
        lat: '41.896187',
        lng: '12.492046',
        parcels: [],
        address: {
          country: '',
          state: '',
          city: '',
          postalCode: '',
          street: ''
        }
      }
    }
    if (
      facility.core &&
      facility.core.geolocation &&
      Object.keys(facility.core.geolocation).length &&
      !facility.core.geolocation.address
    ) {
      facility.core.geolocation.address = {
        country: '',
        state: '',
        city: '',
        postalCode: '',
        street: ''
      }
    }
    const companyResponse = await returnAllCompanies()
    // console.log('companyResponse.data => ', companyResponse.data)
    this.setState({ companies: companyResponse.data.data })

    console.log('currFacility => ', facility)
    this.setState({ facility })
  }

  closeConfirmDialog() {
    this.setState({ isConfirmDialogOpened: false })
  }

  // funzione lanciata per chiudere senza salvare
  async confirmClose() {
    this.setState({
      isModifyEnabled: false,
      open: false,
      isDeleting: false,
      isModifing: false,
      isConfirmDialogOpened: false
    })
    this.closeSaveDialog()
    await this.props.onCloseModal()
  }

  closeSaveDialog() {
    this.setState({ isSaveDialogOpen: false })
  }

  async confirmSave() {
    await this.saveModify()
    this.setState({ isModifyEnabled: false })
    if (this.state.saveFromClose) {
      this.setState({
        open: false,
        isDeleting: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      await this.props.onCloseModal()
    }
    this.closeSaveDialog()
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
    // return values.map(value => Object.keys(object).find(key => object[key] === value))
  }

  async closeModal() {
    console.log('this.state.facility', this.state.facility)
    if (this.state.isModifyEnabled || this.state.modified) {
      this.setState({
        isSaveDialogOpen: true,
        saveFromClose: true,
        modified: false
      })
    } else {
      this.setState({
        open: false,
        isDeleting: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      await this.props.onCloseModal()
    }
  }

  async deleteFacility() {
    if (this.state.isDeleting) {
      return
    }
    const facility = deepCloneObj(this.state.facility)

    /* NOTE: La possibilità di cancellazione degli stabilimenti solo se non sono usati nelle fasi dei prodotti è stata momentaneamente congelata */
    // const productParams = {
    //   filter: JSON.stringify({
    //     $or: [
    //       { 'traceability.location': facility.uuid },
    //       { 'traceability.location_from': facility.uuid },
    //       { 'traceability.location_to': facility.uuid }
    //     ],
    //     companyId: facility.companyId
    //   })
    // }
    // const productsResponse = await returnSomeProducts(productParams)

    // const products = productsResponse.data.data
    // console.log('products => ', products)
    // // return

    // if (products.length > 0) {
    //   const names = products.map(prod => prod.name).join(', \n')
    //   this.setState({ isConfirmDialogOpened: false })
    //   return this.props.setSnackbar(`${this.props.t('notifications.warningFacilityIsUsed.title')}:\n\n ${names}. ${this.props.t('notifications.warningFacilityIsUsed.subtitle')}`, 'error')
    // }

    this.setState({ isDeleting: true })
    delete facility._id
    delete facility.companyData
    try {
      await deleteFacility(facility)

      this.setState({ isDeleting: false })
      await this.closeModal()
    } catch (e) {
      console.error('Unable to delete facility, e => ', e)
      this.props.setSnackbar(
        this.props.t('notifications.errDeletingFacility'),
        'error'
      )
    }
  }

  enableOrDisableModify() {
    if (this.state.isModifyEnabled === true) {
      this.setState({
        isModifyEnabled: !this.state.isModifyEnabled,
        mapError: false,
        modified: true
      })
    } else {
      this.setState({
        isModifyEnabled: !this.state.isModifyEnabled,
        mapError: false
      })
    }
  }

  fieldChanged = ({ target }) => {
    const facility = this.state.facility
    facility[target.name] = target.value
    this.setState({ facility })
  }

  coreFieldChanged = ({ target }) => {
    const facility = this.state.facility
    facility.core[target.name] = target.value
    this.setState({ facility })
  }

  addressChanged = ({ target }) => {
    const facility = this.state.facility
    facility.core.geolocation.address[target.name] = target.value
    this.setState({ facility })
  }

  geoChanged = ({ target }) => {
    const facility = this.state.facility
    facility.core.geolocation[target.name] = target.value
    console.log('facility', facility)
    this.setState({ facility })
  }

  openConfirmDialog() {
    this.setState({ isConfirmDialogOpened: true })
  }

  async saveModify() {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    // const updateObject = Object.keys(this.state.facility).reduce((acc, k) => {
    //   acc[k] = this.state.facility[k]
    //   return acc
    // }, {})
    const facility = deepCloneObj(this.state.facility)
    console.log('updateObject => ', facility)
    // return

    // STEP: in questo modo permetto il salvataggio della facility quando gli passo solo le coordinate di geolocalizzazione e gli altri campi relativi agli indizzi sono vuoti
    if (
      facility.core.geolocation.address &&
      Object.keys(facility.core.geolocation.address).length
    ) {
      if (Object.values(facility.core.geolocation.address).every((x) => !x)) {
        delete facility.core.geolocation.address
      }
    }
    console.log('Facility:::', facility)
    delete facility._id
    delete facility.companyData
    facility.updatedAt = Date.now()

    try {
      const returnObject = await editFacility(facility)
      // TODO: mettere controlli sugli errori sfruttando returnObject (lo facevamo con la supplychain)
      this.props.setSnackbar(
        this.props.t('notifications.okEditingFacility'),
        'success'
      )

      // facility.updatedAt = updateObject.updatedAt

      /* facendo in questo modo non si rompe il componente della multiple select in quanto l'array di
      supplychainsId rimane caricato in facility e ciò che deve essere sostituito, come veniva fatto precedentemente,
      viene sostituito
      */
      this.setState({
        facility: {
          ...this.state.facility,
          facility
        },
        isModifing: false
      })
      // alert('Modifica avvenuta con successo')
    } catch (e) {
      console.error('Unable to modify facility e => ', e)
      this.props.setSnackbar(
        this.props.t('notifications.errEditingFacility'),
        'error'
      )
      // alert('Impossibile modificare la facility')
    }
  }

  savePosition(e) {
    const { lat, lng } = e.latlng
    const facility = this.state.facility
    if (!Object.prototype.hasOwnProperty.call(facility, 'geolocation')) {
      facility.geolocation = {}
    }

    facility.geolocation.lat = Number(lat)
    facility.geolocation.lng = Number(lng)

    this.setState({ facility })
  }
  isLatitude = (str) => {
    const latitudeRegex = /^(-?([1-8]?[0-9](\.\d+)?|90(\.0+)?))$/
    return latitudeRegex.test(str)
  }

  isLongitude = (str) => {
    const longitudeRegex =
      /^(-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?))$/
    return longitudeRegex.test(str)
  }

  render() {
    if (this.props.open !== true) {
      return <div></div>
    }
    const { classes } = this.props
    return (
      <React.Fragment>
        {/* dialog confirm delete company */}
        <Dialog
          className='facilityModal'
          open={this.state.isConfirmDialogOpened}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.deleteFacility.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.deleteFacility.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ textPrimary: classes.warn }}
              onClick={this.closeConfirmDialog}
              color='primary'
              autoFocus
            >
              {this.props.t('dialog.deleteFacility.buttons.cancel')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.deleteFacility}
              color='primary'
            >
              {this.state.isDeleting ? (
                <LogoLoader size='small'></LogoLoader>
              ) : (
                this.props.t('dialog.deleteFacility.buttons.confirm')
              )}
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog confirm saved changes */}
        <Dialog
          className='facilityModal'
          open={this.state.isSaveDialogOpen}
          aria-labelledby='Comfirm save'
        >
          <DialogTitle id='responsive-dialog-title'>
            {this.props.t('dialog.saveEdits.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.saveEdits.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color='primary' autoFocus>
              {this.props.t('dialog.saveEdits.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color='primary'>
              {this.props.t('dialog.saveEdits.buttons.closeWithoutSave')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.confirmSave}
              color='secondary'
              disabled={!!this.state.isModifing}
            >
              {this.state.isModifing ? (
                <LogoLoader size='small'></LogoLoader>
              ) : (
                this.props.t('dialog.saveEdits.buttons.closeAndSave')
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='facilityModal'
          PaperProps={{ square: true }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          <Paper elevation={4}>
            {this.props.width === 'sm' || this.props.width === 'xs' ? (
              <DialogTitle
                classes={{ root: classes.titleMobile }}
                disableTypography={true}
                className={classes.navBar}
              >
                <Typography
                  style={{ width: '95%' }}
                  noWrap={true}
                  variant='h6'
                  component='h2'
                >
                  {this.props.t('title')}
                </Typography>
                <IconButton
                  style={{ width: '5%', justifySelf: 'flex-end' }}
                  edge='end'
                  color='inherit'
                  aria-label='close modal'
                  onClick={this.closeModal}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            ) : (
              <DialogTitle className={classes.navBar}>
                {this.props.t('title')}
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-evenly'
                  className={classes.closeButton}
                  style={{ gap: '5px' }}
                >
                  {this.state.isModifyEnabled && (
                    <Button
                      title='disabilita modifiche'
                      onClick={this.enableOrDisableModify}
                      style={{ color: 'black' }}
                      color='inherit'
                      variant='outlined'
                      aria-label='disallow modify'
                    >
                      {this.props.t('buttons.disableEdit')}
                    </Button>
                  )}
                  {!this.state.isModifyEnabled && (
                    <Button
                      title='abilita modifiche'
                      onClick={this.enableOrDisableModify}
                      style={{ color: 'black' }}
                      color='inherit'
                      variant='outlined'
                      aria-label='allow modify'
                    >
                      {this.props.t('buttons.enableEdit')}
                    </Button>
                  )}
                  <Button
                    onClick={this.openConfirmDialog}
                    aria-label='delete facility'
                    color='inherit'
                    variant='outlined'
                    style={{ color: 'black' }}
                  >
                    {this.props.t('buttons.delete')}
                  </Button>
                  <Button
                    edge='end'
                    style={{ color: 'black' }}
                    aria-label='close modal'
                    color='inherit'
                    variant='outlined'
                    onClick={this.closeModal}
                  >
                    {this.props.t('buttons.cancel')}
                  </Button>
                </Box>
              </DialogTitle>
            )}
          </Paper>
          <DialogContent className='tabContent'>
            <Box p={2}>
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={12} md={5} lg={4}>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='name'
                        name='name'
                        label={this.props.t('inputs.name')}
                        type='text'
                        fullWidth
                        value={this.state.facility.core.name}
                        onChange={this.coreFieldChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='gln'
                        name='gln'
                        label={this.props.t('inputs.gln')}
                        type='text'
                        fullWidth
                        value={this.state.facility.core.gln}
                        onChange={this.coreFieldChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='city'
                        name='city'
                        label={this.props.t('inputs.city')}
                        type='text'
                        fullWidth
                        value={
                          this.state.facility.core.geolocation?.address?.city
                        }
                        onChange={this.addressChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='postalCode'
                        name='postalCode'
                        label={this.props.t('inputs.postalCode')}
                        type='text'
                        fullWidth
                        value={
                          this.state.facility.core.geolocation?.address
                            ?.postalCode
                        }
                        onChange={this.addressChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='street'
                        name='street'
                        label={this.props.t('inputs.street')}
                        type='text'
                        fullWidth
                        value={
                          this.state.facility.core.geolocation?.address?.street
                        }
                        onChange={this.addressChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='state'
                        name='state'
                        label={this.props.t('inputs.state')}
                        type='text'
                        fullWidth
                        value={
                          this.state.facility.core.geolocation?.address?.state
                        }
                        onChange={this.addressChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='lat'
                        name='lat'
                        label={this.props.t('inputs.lat')}
                        type='text'
                        fullWidth
                        value={this.state.facility.core.geolocation?.lat}
                        onChange={this.geoChanged}
                        margin='normal'
                        error={
                          this.state.facility.core.geolocation?.lat !== '' &&
                          !this.isLatitude(
                            this.state.facility.core.geolocation?.lat
                          )
                        }
                        helperText={
                          this.state.facility.core.geolocation?.lat !== '' &&
                          !this.isLatitude(
                            this.state.facility.core.geolocation?.lat
                          )
                            ? this.props.t('inputs.latError')
                            : ''
                        }
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='lng'
                        name='lng'
                        label={this.props.t('inputs.lng')}
                        type='text'
                        fullWidth
                        value={this.state.facility.core.geolocation?.lng}
                        onChange={this.geoChanged}
                        margin='normal'
                        error={
                          this.state.facility.core.geolocation?.lng !== '' &&
                          !this.isLongitude(
                            this.state.facility.core.geolocation?.lng
                          )
                        }
                        helperText={
                          this.state.facility.core.geolocation?.lng !== '' &&
                          !this.isLongitude(
                            this.state.facility.core.geolocation?.lng
                          )
                            ? this.props.t('inputs.lngError')
                            : ''
                        }
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='country'
                        name='country'
                        label={this.props.t('inputs.country')}
                        type='text'
                        fullWidth
                        value={
                          this.state.facility.core.geolocation?.address?.country
                        }
                        onChange={this.addressChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={10} md={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='customerErpCode'
                        name='customerErpCode'
                        label={this.props.t('inputs.customerErpCode')}
                        type='text'
                        fullWidth
                        value={this.state.facility.core.customerErpCode}
                        onChange={this.coreFieldChanged}
                        margin='normal'
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Tooltip
                        title={this.props.t('inputs.companyCodFacilityTooltip')}
                        placement='top-end'
                      >
                        <Info fontSize='small' />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7} lg={8}>
                  {this.state.facility.core.geolocation &&
                  this.state.facility.core.geolocation.lat &&
                  this.state.facility.core.geolocation.lng ? (
                    <Map
                      onLocationFound={
                        this.state.isModifyEnabled
                          ? this.savePosition
                          : () =>
                              this.props.setSnackbar(
                                this.props.t('notifications.mapWarning'),
                                'warning'
                              )
                      }
                      lat={
                        parseFloat(this.state.facility.core.geolocation.lat) ||
                        null
                      }
                      lng={
                        parseFloat(this.state.facility.core.geolocation.lng) ||
                        null
                      }
                    />
                  ) : (
                    <div>{this.props.t('notifications.setLatAndLng')}</div>
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          {this.props.width === 'sm' || this.props.width === 'xs' ? (
            <DialogActions classes={{ root: classes.mobileActions }}>
              <Button
                onClick={this.openConfirmDialog}
                color='white'
                aria-label='delete facility'
                startIcon={<DeleteIcon />}
              >
                {this.props.t('buttons.delete')}
              </Button>
              {this.state.isModifyEnabled && (
                <Button
                  classes={{ textPrimary: classes.primary }}
                  startIcon={<LockOpen />}
                  title='disabilita modifiche'
                  onClick={this.enableOrDisableModify}
                  color='primary'
                  aria-label='disallow modify'
                >
                  {this.props.t('buttons.close')}
                </Button>
              )}
              {!this.state.isModifyEnabled && (
                <Button
                  classes={{ textPrimary: classes.primary }}
                  startIcon={<Lock />}
                  title='abilita modifiche'
                  onClick={this.enableOrDisableModify}
                  color='primary'
                  aria-label='allow modify'
                >
                  {this.props.t('buttons.edit')}
                </Button>
              )}
            </DialogActions>
          ) : (
            ''
          )}
        </Dialog>
      </React.Fragment>
    )
  }
}

CardModal.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  open: PropTypes.bool,
  facility: PropTypes.object,
  setSnackbar: PropTypes.func
}

export default withRouter(
  withStyles(styles)(withWidth()(withTranslation('facilityDetails')(CardModal)))
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import { isThisAccountHasFashionFunctionality } from '../../utils/acl-organization'

import {
  Button,
  Card,
  CardActionArea,
  CardHeader,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  Box,
  Snackbar,
  SnackbarContent,
  withWidth,
  Tooltip,
  IconButton,
  CardContent
} from '@material-ui/core'

import classNames from 'classnames'
import CreateIcon from '@material-ui/icons/Create'
import Pagination from 'material-ui-flat-pagination'
import moment from 'moment'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import {
  returnSomeLots,
  createDefaultLot,
  returnLotsCount,
  returnLotsCountWithParams
} from '../../utils/lots'

// import { returnSomeLots, getLastInputOrOutputInformation, createDefaultLot } from '../../utils/lots'
import { returnAllCompaniesProjection } from '../../utils/companies'
import { returnSomeProducts } from '../../utils/products'
// import { deepCloneObj } from '../../utils/utils'

import configuration from '../../configuration'
import CardModalLot from '../Modal/CardModalLot'
// import FormDialogLot from '../../components/FormDialogLot/FormDialogLot.component'
import FabWithLoader from '../FabWithLoader/FabWithLoader'
import {
  Search as SearchIcon,
  // DeleteForever as DeleteForeverIcon,
  ExitToApp as ExitToAppIcon,
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  Close as CloseIcon,
  Cancel,
  Info as InfoIcon,
  CloudDownload as DownloadIcon
} from '@material-ui/icons'
import { domainToLogos } from '../../domainToLogos'
import { green, amber, grey, blue } from '@material-ui/core/colors'
import { QRCodeSVG, QRCodeCanvas } from 'qrcode.react'
import LotsTable from './LotsTable.js'

const styles = (theme) => ({
  '@global': {
    '.MuiInputBase-input': {
      padding: '6px 0 4px'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    },
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  addButton: {
    background: '#C0EDCB',
    color: 'white'
  },
  progressStyle: {
    color: theme.primary
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: 'white',
    width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  buttonGrid: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  card: {
    cursor: 'pointer',
    minWidth: 275
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[700]
  },
  info: {
    backgroundColor: blue[500]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  currentQrdescription: {
    color: grey[600]
  },
  lastLotcard: {
    padding: '24px 16px'
  },
  actionButton: {
    marginRight: theme.spacing(2)
  },
  latestQrButton: {
    color: theme.primary
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})
function RenderFrontEndUrl(domain) {
  if (domain.type === 'LISTED') {
    if (domain.domain === 'trusty') {
      if (domain.subDomains.length > 0 && domain.subDomains.length <= 1) {
        return `${domainToLogos[domain.subDomains[0]].frontendBaseUrl}`
      } else if (domain.subDomains.length > 1) {
        return `${domainToLogos[domain.subDomains[1]].frontendBaseUrl}`
      } else {
        return configuration.frontendBaseUrl
      }
    } else {
      return `${domainToLogos[domain.domain].frontendBaseUrl}`
    }
  } else {
    return configuration.frontendBaseUrl
  }
}
class Lots extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.user = ls.get('user') || {}

    // if (!props.guidedLoaded) { window.userGuiding.hideChecklist() }

    this.state = {
      // loadedGuide: false,
      areLotsFetching: false,
      areSearchingForLots: false,
      noSearchLotsFound: false,
      areProductsFetching: true,
      isLotOpened: false,
      isConfirmDialogOpened: false,
      isDeleting: false,
      isLoadingInfoProducts: false,
      isFels: this.props.isFels,
      companiesIds: [],
      // isFormDialogOpened: false,
      lastSearchCriteria: '',
      lastSearchCriteriaForTable: '',
      limit: 20,
      lot: {},
      lots: [],
      totalLotsrecords: 0,
      searchedLots: [],
      offset: 0,
      originalLots: [],
      owner: this.user.uuid,
      page: 0,
      productOfLot: '',
      products: [],
      productsIdNameMap: {},
      isAllProductSelected: true,
      selectedProductId: '',
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: '',
      hasFashionFunctionality: isThisAccountHasFashionFunctionality(),
      lotsColumns: [
        {
          title: this.props.t('lotsColumns.lotNumber'),
          render: (rowData) =>
            rowData.core.lotNumber && rowData.core.lotNumber !== ''
              ? rowData.core.lotNumber
              : this.props.t('lotTable.rowNoLotNumber')
        },
        {
          title: this.props.t('lotsColumns.expirationDate'),
          field: 'expirationDate',
          render: (rowData) =>
            !this.state.hasFashionFunctionality && rowData.core.expirationDate
              ? moment(rowData.core.expirationDate).format('DD/MM/YYYY')
              : '/'
        }
      ],
      lotsMobileColumns: [
        {
          title: this.props.t('lotsColumns.lotNumber'),
          field: 'lotNumber',
          render: (rowData) =>
            rowData.lotNumber && rowData.lotNumber !== ''
              ? rowData.lotNumber
              : this.props.t('lotTable.rowNoLotNumber')
        },
        {
          title: this.props.t('lotsColumns.expirationDate'),
          field: 'expirationDate',
          render: (rowData) =>
            !this.state.hasFashionFunctionality && rowData.core.expirationDate
              ? moment(rowData.core.expirationDate).format('DD/MM/YYYY')
              : '/'
        }
      ],
      lotsColumnsLastProducts: [
        {
          title: this.props.t('lotsColumns.lotNumber'),
          render: (rowData) =>
            rowData.core.lotNumber && rowData.core.lotNumber !== ''
              ? rowData.core.lotNumber
              : this.props.t('lotTable.rowNoLotNumber')
        },
        {
          title: this.props.t('lotsColumns.productName'),
          field: 'productName'
        },
        {
          title: this.props.t('lotsColumns.expirationDate'),
          field: 'expirationDate',
          render: (rowData) =>
            !this.state.hasFashionFunctionality && rowData.core.expirationDate
              ? moment(rowData.core.expirationDate).format('DD/MM/YYYY')
              : '/'
        }
      ],
      lotsMobileColumnsLastProducts: [
        {
          title: this.props.t('lotsColumns.lotNumber'),
          field: 'lotNumber',
          render: (rowData) =>
            rowData.core.lotNumber && rowData.core.lotNumber !== ''
              ? rowData.core.lotNumber
              : this.props.t('lotTable.rowNoLotNumber')
        },
        {
          title: this.props.t('lotsColumns.productName'),
          field: 'productName'
        }
      ],
      frontEndUrl: RenderFrontEndUrl(this.props.domain)
      // frontEndUrl: this.props.domain.domain === 'trusty' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}` /* fare attenzione che in caso di più topLevelsDomain (eg:co.uk) bisogna gestire */
      // frontEndUrl: this.props.domain.type === 'INVALID' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}` /* fare attenzione che in caso di più topLevelsDomain (eg:co.uk) bisogna gestire */
    }

    this.changePage = this.changePage.bind(this)
    this.closeLotModal = this.closeLotModal.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    // this.closeFormDialog = this.closeFormDialog.bind(this)
    this.filterLotsViaText = this.filterLotsViaText.bind(this)
    this.openLotModal = this.openLotModal.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    // this.openFormDialog = this.openFormDialog.bind(this)
    this.productHasChanged = this.productHasChanged.bind(this)
    this.productOfLotChanged = this.productOfLotChanged.bind(this)
    this.refreshLots = this.refreshLots.bind(this)
    this.refreshProducts = this.refreshProducts.bind(this)
    this.searchCriteriaChanged = this.searchCriteriaChanged.bind(this)
    this.searchLotsInTable = this.searchLotsInTable.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.searchLots = this.searchLots.bind(this)
    this.clearSearchBar = this.clearSearchBar.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.openNewLotDialog = this.openNewLotDialog.bind(this)
    this.setProductConfirmDialog = this.setProductConfirmDialog.bind(this)
    this.openUrl = this.openUrl.bind(this)
    this.receivingFromChild = this.receivingFromChild.bind(this)
    this.createNewDefaultLot = this.createNewDefaultLot.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  async componentDidMount() {
    this._isMounted = true
    await this.refreshProducts()
    /* default ultimi lotti */
    await this.refreshLots()
    // await this.prepareSelectChoice()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedProductId !== this.state.selectedProductId) {
      console.log('QIANEUIRUEIDJHJKSJFDISKDJ')
      await this.refreshLots()
    }
  }

  // /* funzione che mi prepara tutti gli input per le company, le facilities e i prodotti */
  // async prepareSelectChoice () {
  //   // FACILITY
  //   const facilityParam = {
  //     limit: Number.MAX_SAFE_INTEGER
  //   }
  //   const facilitiesResponse = await returnAllFacilitiesProjection(facilityParam)
  //   const facilities = facilitiesResponse.data.data
  //   // COMPANY
  //   const companiesParams = {
  //     limit: Number.MAX_SAFE_INTEGER
  //   }
  //   const companiesNameResponse = await returnAllCompaniesProjection(companiesParams)
  //   const companies = companiesNameResponse.data.data

  //   const products = this.state.products.map(product => {
  //     const company = companies
  //       .find(c => c.core.uuid === product.core.companyId)
  //     product.companyData = company

  //     return product
  //   })
  //   // console.log('product:::::', products)
  //   this.setState({ facilities, products, companies })
  // }

  componentWillUnmount() {
    this._isMounted = false
  }

  changePage(offset, page) {
    this.setState({ offset, page: page - 1 })
  }

  setSnackbar(message, type) {
    this.setState({
      snackbarMessage: message,
      snackbarType: type,
      showSnackbar: true
    })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  async closeLotModal(shouldIRefresh) {
    this.setState({ isLotOpened: false })
    if (shouldIRefresh === true) {
      await this.refreshLots()
    }
  }

  closeConfirmDialog() {
    this.setState({ isConfirmDialogOpened: false, isDeleting: false })
  }

  // funzione che setta il prodotto selezionato
  async setProductConfirmDialog() {
    // await new Lot for selected product

    this.setState(
      {
        isConfirmDialogOpened: false
        // isFormDialogOpened: true
      }, // ci assicura che la dialog sia chiusa
      async () => {
        this.createNewDefaultLot()
      }
    )
  }

  // funzione che ritorna il valore del gtin del prodotto selezionato
  getSelectedProductGtin() {
    const prodUuid = this.state.selectedProductId
    if (prodUuid) {
      const product = this.state.products.find(
        (prod) => prod.core.uuid === prodUuid
      )
      return product.core.gtin
    }
    return null
  }

  // async closeFormDialog (shouldIRefresh) {
  //   this.setState({ isFormDialogOpened: false })
  //   if (shouldIRefresh === true) {
  //     await this.refreshLots()
  //   }
  // }

  productHasChanged({ target }) {
    this.setState({ selectedProductId: target.value })
  }

  // funzione che filtra i lotti scaricati in base ad una stringa
  filterLotsViaText(searchCriteria) {
    const doesLotMatchCriteria = (lot) =>
      Object.keys(lot).some((key) => {
        if (typeof lot[key] === 'object') {
          return doesLotMatchCriteria(lot[key])
        }

        const value = String(lot[key])
        return value.toLowerCase().includes(searchCriteria.toLowerCase())
      })

    if (searchCriteria.length) {
      const filtered = this.state.originalLots.filter(doesLotMatchCriteria)
      this.setState({ lots: filtered, offset: 0, page: 0 })
    } else {
      this.setState({ lots: this.state.originalLots, offset: 0, page: 0 })
    }
  }
  async refreshLotsForPagination(params, productId, queryString) {
    console.log('refreshLotsForPagination')
    if (productId === '') {
      try {
        this.setState({ areLotsFetching: true })
        const lotsParamForCount = {
          filter: JSON.stringify({
            lotNumber: {
              $regex: queryString,
              $options: 'i'
            }
          })
        }
        console.log('lotsParamForCount =>', lotsParamForCount)
        const lotsParam =
          this.queryString !== ''
            ? {
                ...params,
                filter: JSON.stringify({
                  lotNumber: {
                    $regex: queryString,
                    $options: 'i'
                  }
                })
              }
            : params

        const lotsResponse = await returnSomeLots(lotsParam)
        const lots = lotsResponse.data.data
        console.log(' i lots trovati sono =>', lots)
        /* capire questione del lot status */
        lots.forEach((lot) => {
          const product = this.state.products.find(
            (product) => product.core.uuid === lot.core.productId
          )
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
          lot.productGtin = product?.core?.gtin || 'Undefined'
        })
        this.setState({
          areLotsFetching: false,
          lots,
          originalLots: lots
        })
      } catch (e) {
        console.error('Error while refreshing lots: ', e)
        this.setSnackbar(this.props.t('notifications.lotsError'), 'error')
      }
    } else {
      console.log('productId =>', productId)
      console.log('params =>', params)
      try {
        this.setState({ areLotsFetching: true })
        const product = this.state.products.find(
          (product) => product.core.uuid === this.state.selectedProductId
        )
        const lotsParam =
          queryString !== ''
            ? {
                ...params,
                filter: JSON.stringify({
                  productId: productId,
                  lotNumber: {
                    $regex: queryString,
                    $options: 'i'
                  }
                })
              }
            : {
                ...params,
                filter: JSON.stringify({
                  productId: product.core.uuid
                })
              }
        const lotsResponse = await returnSomeLots(lotsParam)
        const lots = lotsResponse.data.data
        console.log(' i lots trovati sono =>', lots)
        lots.forEach((lot) => {
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
          lot.productGtin = product?.core?.gtin || 'Undefined'
        })
        this.setState({
          areLotsFetching: false,
          lots,
          originalLots: lots
        })
      } catch (e) {
        console.error('Error while refreshing lots: ', e)
        this.setSnackbar(this.props.t('notifications.lotsError'), 'error')
        // alert('Impossibile ricaricare i lotti.')
      }
    }
  }
  // funzione che aggiorna i lotti
  async refreshLots() {
    const root = document.getElementById('root')
    root.className = ''
    root.classList.add('lots')

    this.setState({ offset: 0, page: 0 })
    if (this.state.selectedProductId === '') {
      // QUI FARE LA CHIAMATA PER GLI ULTIMI LOTTI INSERITi
      try {
        this.setState({ areLotsFetching: true })
        const lotsParam = {
          limit: '10'
        }
        const lotsCount = await returnLotsCount()
        console.log('lotsCount =>', lotsCount)
        const lotsResponse = await returnSomeLots(lotsParam)
        const lots = lotsResponse.data.data

        /* capire questione del lot status */
        lots.forEach((lot) => {
          const product = this.state.products.find(
            (product) => product.core.uuid === lot.core.productId
          )
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
          lot.productGtin = product?.core?.gtin || 'Undefined'
        })
        this.setState({
          areLotsFetching: false,
          lots,
          totalLotsrecords: lotsCount.data.data.count,
          originalLots: lots
        })
      } catch (e) {
        console.error('Error while refreshing lots: ', e)
        this.setSnackbar(this.props.t('notifications.lotsError'), 'error')
      }
    } else {
      try {
        this.setState({ areLotsFetching: true })
        const product = this.state.products.find(
          (product) => product.core.uuid === this.state.selectedProductId
        )
        const lotsParamForCount = {
          filter: JSON.stringify({
            productId: product.core.uuid,
            status: { $ne: 'disabled' }
          })
        }
        const lotsParamForPagination = {
          filter: JSON.stringify({
            limit: 10,
            productId: product.core.uuid,
            status: { $ne: 'disabled' }
          })
        }
        const lotsCounts = await returnLotsCountWithParams(lotsParamForCount)
        console.log('lotsCounts whit prod id  =>', lotsCounts)
        const lotsResponse = await returnSomeLots(lotsParamForPagination)
        const lots = lotsResponse.data.data
        lots.forEach((lot) => {
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
          lot.productGtin = product?.core?.gtin || 'Undefined'
        })
        this.setState({
          areLotsFetching: false,
          lots,
          originalLots: lots,
          totalLotsrecords: lotsCounts.data.data.count
        })
      } catch (e) {
        console.error('Error while refreshing lots: ', e)
        this.setSnackbar(this.props.t('notifications.lotsError'), 'error')
        // alert('Impossibile ricaricare i lotti.')
      }
    }
  }

  // DEPRECATA funzione che salva il valore del prodotto per cui creare nuovo lotto
  productOfLotChanged({ target }) {
    this.setState({ productOfLot: target.value })
  }

  async refreshProducts() {
    try {
      const companiesParams = {
        limit: Number.MAX_SAFE_INTEGER
      }
      const companiesResponse = await returnAllCompaniesProjection(
        companiesParams
      )
      const companies = companiesResponse.data.data
      const companiesIds = companies.map((company) => company.core.uuid)
      const productFields = {
        limit: Number.MAX_SAFE_INTEGER
      }
      const productsResponse = await returnSomeProducts(productFields)
      // const productsResponse = await returnAllProducts()
      const allProducts = productsResponse.data.data
      const products = allProducts.filter(
        (p) => p.productType === 'finishedGood'
      )
      products.forEach((product) => {
        const company = companies.find(
          (c) => c.core.uuid === product.core.companyId
        )
        product.companyData = company
      })
      const productsIdNameMap = products.reduce((acc, product) => {
        acc[product.core.uuid] = product.core.name
        return acc
      }, {})

      if (this._isMounted) {
        const currProduct = products.length > 0 ? products[0].gtin : ''
        this.setState({
          areProductsFetching: false,
          productOfLotsToDelete: currProduct,
          products,
          productsIdNameMap,
          companiesIds
        })
      }
    } catch (e) {
      console.log('Error while refreshing products: ', e)
      this.setSnackbar(this.props.t('notifications.productsError'), 'error')
    }
  }

  // funzione che resetta il search
  resetSearch() {
    this.setState({
      lastSearchCriteria: '',
      searchedLots: [],
      noSearchLotsFound: false
    })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl(url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode() {
    // creo link fittizio
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che cerca i lotti in base al campo di ricerca
  async searchLots(lotNumToSearch) {
    console.log('Sto qua')
    // console.log('lotNumToSearch => ', lotNumToSearch)
    if (lotNumToSearch === '') {
      // QUI FARE LA CHIAMATA PER GLI ULTIMI LOTTI INSERITI
      // created at 2020-11-24T14:33:41.880Z
      const lotsParam = {
        limit: '10'
      }
      const lotsResponse = await returnSomeLots(lotsParam)
      return lotsResponse
    }
    if (lotNumToSearch.length > 0) {
      console.log('lotNumToSearch =>', lotNumToSearch)
      try {
        this.setState({ areSearchingForLots: true })
        // mi prendo le aziende
        const companiesIds = this.state.companiesIds
        const lotsParam = {
          filter: JSON.stringify({
            companyId: { $in: companiesIds },
            status: { $ne: 'disabled' }
          })
        }
        console.log('lotsParam =>', lotsParam)
        const lotsResponse = await returnSomeLots(lotsParam)
        console.log('lotsResponse =>', lotsResponse)
        let searchedLots = lotsResponse.data.data
        searchedLots.forEach((lot) => {
          const product = this.state.products.find(
            (product) => product.core.uuid === lot.core.productId
          )
          lot.productGtin = product?.core?.gtin || 'Undefined'
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
        })
        console.log('searchedLots function =>', searchedLots)
        searchedLots = searchedLots.filter((lot) => {
          return (
            lot.core.lotNumber &&
            lot.core.lotNumber
              .toLowerCase()
              .includes(lotNumToSearch.toLowerCase())
          )
        })
        let noSearchLotsFound = false
        if (searchedLots.length === 0) {
          noSearchLotsFound = true
        }
        this.setState({
          areSearchingForLots: false,
          searchedLots,
          noSearchLotsFound
        })
      } catch (err) {
        console.error('Error while searching lots, err => ', err)
        this.setSnackbar(this.props.t('notifications.searchError'), 'error')
      }
    }
  }

  async searchLotsFromTable(lotNumToSearch, productId) {
    console.log('searchLotsFromTable')
    console.log(this.state.products)
    if (lotNumToSearch === '') {
      // QUI FARE LA CHIAMATA PER GLI ULTIMI LOTTI INSERITI
      // created at 2020-11-24T14:33:41.880Z
      const lotsParam = {
        limit: '10'
      }
      const lotsResponse = await returnSomeLots(lotsParam)
      return lotsResponse
    }
    /* if (lotNumToSearch.length === 0) return */
    if (productId === '') {
      try {
        this.setState({ areSearchingForLots: true })
        /*  const companiesIds = this.state.companiesIds
        const lotsParam = {
          filter: JSON.stringify({
            companyId: { $in: companiesIds },
            status: { $ne: 'disabled' }
          })
        } */
        const lotsParamForCount = {
          filter: JSON.stringify({
            lotNumber: { $regex: lotNumToSearch, $options: 'i' }
          })
        }
        const lotsParam = {
          limit: '10',
          filter: JSON.stringify({
            lotNumber: { $regex: lotNumToSearch, $options: 'i' }
          })
        }
        const lotsResponse = await returnSomeLots(lotsParam)
        const lotsCount = await returnLotsCountWithParams(lotsParamForCount)
        console.log('lotsResponse =>', lotsResponse)
        let searchedLots = lotsResponse.data.data

        searchedLots.forEach((lot) => {
          const product = this.state.products.find(
            (product) => product.core.uuid === lot.core.productId
          )
          lot.productGtin = product?.core?.gtin || 'Undefined'
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
        })
        console.log('searchedLots before filt =>', searchedLots)
        //Filter by pproduct name
        /* searchedLots = searchedLots.filter((lot) => {
          return (
            lot.core.lotNumber &&
            lot.core.lotNumber
              .toLowerCase()
              .includes(lotNumToSearch.toLowerCase())
          )
        }) */
        console.log('searchedLots after filter =>', searchedLots)
        let noSearchLotsFound = false
        if (searchedLots.length === 0) {
          noSearchLotsFound = true
        }
        this.setState({
          areSearchingForLots: false,
          lots: searchedLots,
          totalLotsrecords: lotsCount.data.data.count,
          noSearchLotsFound
        })
      } catch (err) {
        console.error('Error while searching lots, err => ', err)
        this.setSnackbar(this.props.t('notifications.searchError'), 'error')
      }
    } else {
      console.log('else')
      try {
        this.setState({ areSearchingForLots: true })
        const product = this.state.products.find(
          (product) => product.core.uuid === this.state.selectedProductId
        )

        const lotsParamforCount = {
          filter: JSON.stringify({
            productId: product.core.uuid,
            lotNumber: { $regex: lotNumToSearch, $options: 'i' }
          })
        }
        const lotsParam = {
          filter: JSON.stringify({
            limit: 10,
            productId: product.core.uuid,
            lotNumber: { $regex: lotNumToSearch, $options: 'i' }
          })
        }
        const lotsCount = await returnLotsCountWithParams(lotsParamforCount)
        const lotsResponse = await returnSomeLots(lotsParam)
        let searchedLots = lotsResponse.data.data
        searchedLots.forEach((lot) => {
          lot.productName =
            this.state.productsIdNameMap[lot.core.productId] || 'Undefined'
          lot.productGtin = product?.core?.gtin || 'Undefined'
        })
        /* searchedLots = searchedLots.filter((lot) => {
          return (
            lot.core.lotNumber &&
            lot.core.lotNumber
              .toLowerCase()
              .includes(lotNumToSearch.toLowerCase())
          )
        }) */
        let noSearchLotsFound = false
        if (searchedLots.length === 0) {
          noSearchLotsFound = true
        }
        this.setState({
          areSearchingForLots: false,
          lots: searchedLots,
          totalLotsrecords: lotsCount.data.data.count,
          noSearchLotsFound
        })
      } catch (err) {
        console.error('Error while searching lots, err => ', err)
        this.setSnackbar(this.props.t('notifications.searchError'), 'error')
      }
    }
  }

  // funzione lanciata al change dell'input di cerca
  async searchCriteriaChanged({ target }) {
    this.setState({ lastSearchCriteria: target.value })
    await this.searchLots(target.value)
    // this.filterLotsViaText(target.value)
  }
  async searchLotsInTable({ target }, productId) {
    console.log('searchLotsInTable')
    console.log('target =>', target)
    console.log('productId =>', productId)
    this.setState({ offset: 0, page: 0 })
    if (target.value === '') {
      this.clearSearchBar()
      return
    }
    this.setState({ lastSearchCriteriaForTable: target.value })
    await this.searchLotsFromTable(target.value, productId)
  }

  async clearSearchBar() {
    console.log('clearSearchBar')
    await this.refreshLots()
    this.setState({
      lastSearchCriteriaForTable: '',
      searchedLots: [],
      page: 0

      //totalLotsrecords: lotsCount.data.data.count
    })
  }

  openLotModal(lot) {
    this.setState({ lot, isLotOpened: true })
  }

  // apre conferma prodotto per nuovo lotto
  openConfirmDialog() {
    this.setState({ isConfirmDialogOpened: true })
  }

  // apre modal new lotto
  // openFormDialog () {
  //   this.setState({ isFormDialogOpened: true })
  // }

  receivingFromChild(bool) {
    this.setState({ isLoadingInfoProducts: bool }, () => {})
  }

  // // funzione che verifica se un prodotto è archiviato o meno
  // isProductArchived (prodId) {
  //   const selectedProduct = this.state.products.find(prod => prod.uuid === prodId)
  //   return selectedProduct.status === 'archived'
  // }

  async createNewDefaultLot() {
    await createDefaultLot(this.state.selectedProductId)
    await this.refreshLots()
  }

  // funzione che viene lanciata all'aggiunta di un lotto
  async openNewLotDialog() {
    if (this.state.selectedProductId === '') {
      this.openConfirmDialog()
    } else {
      await this.createNewDefaultLot()
    }
  }

  // funzione che apre un link
  openUrl(url) {
    // console.log('url =>', url)
    window.open(url, '_blank')
  }

  async handlePageChange(offset, value, productId, queryString) {
    console.log('handlePageChange offset =>', offset)
    console.log('handlePageChange value =>', value)
    //set the pgae state
    this.setState({ currentPage: value, offset: offset })

    await this.refreshLotsForPagination(
      {
        limit: 10,
        skip: offset
      },
      productId,
      queryString
    )
  }

  render() {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <div>
        {/* <Loader
          show={!this.state.loadedGuide}
          // text= {this.props.t('guide.loading')}
        >
        </Loader> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={
              this.state.snackbarType === 'error'
                ? classes.error
                : this.state.snackbarType === 'warning'
                ? classes.warning
                : this.state.snackbarType === 'info'
                ? classes.info
                : classes.success
            }
            aria-describedby={
              this.state.snackbarType === 'error'
                ? 'error-snackbar'
                : this.state.snackbarType === 'warning'
                ? 'warning-snackbar'
                : 'success-snackbar'
            }
            message={
              <span id='error-snackbar' className={classes.message}>
                {this.state.snackbarType === 'error' ||
                this.state.snackbarType === 'warning' ? (
                  <ErrorIcon
                    className={classNames(classes.icon, classes.iconVariant)}
                  />
                ) : this.state.snackbarType === 'success' ? (
                  <SuccessIcon
                    className={classNames(classes.icon, classes.iconVariant)}
                  />
                ) : (
                  <InfoIcon
                    className={classNames(classes.icon, classes.iconVariant)}
                  />
                )}
                {this.state.snackbarMessage}
                <Cancel
                  onClick={() => this.setState({ showSnackbar: false })}
                  className={classNames(classes.icon, classes.iconVariantClose)}
                />
              </span>
            }
          />
        </Snackbar>
        {/* dialog scelta prodotto per il nuovo lotto */}
        <Dialog
          className='lotModal'
          open={this.state.isConfirmDialogOpened}
          aria-labelledby='product-choice-dialog-title'
        >
          <DialogTitle id='product-choice-dialog-title'>
            {this.props.t('dialog.chooseProduct.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.chooseProduct.description')}
            </DialogContentText>
            <Select
              id='product-choiche-of-lot'
              value={this.state.selectedProductId}
              onChange={this.productHasChanged}
              displayEmpty
              name='product-choiche-of-lot'
              className={classes.selectEmpty}
              style={{ width: '100%' }}
            >
              {this.state.products
                .sort((a, b) => {
                  const nameA = a.core.name.toUpperCase()
                  const nameB = b.core.name.toUpperCase()
                  if (nameA < nameB) {
                    return -1
                  }
                  if (nameA > nameB) {
                    return 1
                  }
                  // names must be equal
                  return 0
                })
                .map((product) => (
                  <MenuItem key={product.core.uuid} value={product.core.uuid}>
                    {product.core.name}
                  </MenuItem>
                ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeConfirmDialog}
              color='secondary'
              autoFocus
            >
              {this.props.t('dialog.chooseProduct.buttons.cancel')}
            </Button>
            <Button
              onClick={this.setProductConfirmDialog}
              color='primary'
              disabled={this.state.selectedProductId === ''}
            >
              {this.props.t('dialog.chooseProduct.buttons.next')}
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.isLotOpened && (
          // NOTE: this code is to open the modal for the modify
          <CardModalLot
            setSnackbar={this.setSnackbar}
            products={this.state.products}
            lot={this.state.lot}
            open={this.state.isLotOpened}
            onCloseModal={this.closeLotModal}
            frontEndUrl={this.state.frontEndUrl}
          />
        )}

        {/* HEADER INTERFACCIA */}
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='center'
          className={`${classes.root} filterRoot`}
        >
          {/* SEARCH BOX */}
          {/*<Grid item xs={10} sm={11} md={11} lg={11}>
            <Box>
              <Paper className={classes.paper}>
                <TextField
                  value={this.state.lastSearchCriteria}
                  className={`${classes.input} searchField`}
                  placeholder={this.props.t('inputs.searchLot')}
                  inputProps={{
                    'aria-label': this.props.t('inputs.searchLot')
                  }}
                  onChange={this.searchCriteriaChanged}
                />
                {this.state.lastSearchCriteria === '' ? (
                  <SearchIcon className={classes.searchIcon} />
                ) : (
                  <CloseIcon
                    className={classes.searchIcon}
                    onClick={this.resetSearch}
                  />
                )}
              </Paper>
            </Box>
          </Grid>*/}
          {/* BOTTONE PER LA CREAZIONE DI UN NUOVO LOTTO O SCELTA DEL PRODOTTO */}
          {/*  <Grid item xs={2} sm={1} md={1} lg={1} style={{ textAlign: 'right' }}>
            <FabWithLoader
              title={this.props.t('buttons.addLot')}
              size='medium'
              className={classes.addButton}
              aria-label={this.props.t('buttons.addLot')}
              variant='round'
              onClick={this.openNewLotDialog}
              loader={this.state.isLoadingInfoProducts}
            ></FabWithLoader>
          </Grid> */}
        </Grid>
        {/*  HEADER INTERFACCIA - SELECT PRODOTTI */}
        <Grid>
          {/* PRIMO GRID ITEM
          
          SE NULLO: viene caricata la select di scelta dei prodotti dopo che essi sono stati caricati */}
          <div
            className={`${classes.root} filterRoot`}
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              textAlign: 'center',
              justifyContent: 'space-between'
            }}
          >
            {this.state.lastSearchCriteria === '' ? (
              // in base a se i prodotti sono caricati o meno viene mostrato il logo loader o la select dei prodotti
              <Grid container className={classes.root}>
                {this.state.areProductsFetching ? (
                  <Grid
                    item
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                    xs={12}
                  >
                    <Grid item>
                      <LogoLoader
                        size='large'
                        text={this.props.t('downloadingLots')}
                      ></LogoLoader>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      {/* <InputLabel htmlFor="selectedProductId">{this.props.t('inputs.selectProduct')}</InputLabel> */}
                      <Select
                        displayEmpty
                        value={this.state.selectedProductId}
                        onChange={this.productHasChanged}
                        name='selectedProductId'
                        inputProps={{ id: 'selectedProductId' }}
                      >
                        <MenuItem key='allProducts' value=''>
                          <span style={{ color: '#AEAEAE' }}>
                            {this.props.t('lotSelect.productSelect')}
                          </span>
                        </MenuItem>
                        {this.state.products
                          .sort((a, b) => {
                            const nameA = a.core.name.toUpperCase()
                            const nameB = b.core.name.toUpperCase()
                            if (nameA < nameB) {
                              return -1
                            }
                            if (nameA > nameB) {
                              return 1
                            }
                            // names must be equal
                            return 0
                          })
                          .map((product) => {
                            return (
                              <MenuItem
                                key={product.uuid}
                                value={product.core.uuid}
                              >
                                {product.core.name} (
                                {product.companyData?.core.name})
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            ) : (
              ''
            )}

            <Grid item xs={2} sm={1} md={1} lg={1}>
              <FabWithLoader
                title={this.props.t('buttons.addLot')}
                size='medium'
                className={classes.addButton}
                aria-label={this.props.t('buttons.addLot')}
                variant='round'
                onClick={this.openNewLotDialog}
                loader={this.state.isLoadingInfoProducts}
              ></FabWithLoader>
            </Grid>
          </div>

          {/* secondo grid item */}
          <Grid item xs>
            {this.state.lastSearchCriteria !== '' &&
              !this.state.areSearchingForLots &&
              !this.state.noSearchLotsFound &&
              this.state.searchedLots.length > 12 && (
                <Pagination
                  limit={this.state.limit}
                  offset={this.state.offset}
                  total={this.state.lots.length}
                  onClick={(e, offset, page) => this.changePage(offset, page)}
                  style={{
                    textAlign: 'center',
                    paddingBottom: 6,
                    paddingTop: 4
                  }}
                />
              )}
          </Grid>
          {/* RESPONSABILE NEL MOSTRARMI LE CARD SE SEARCH CRITERIA è DIVERSO DA NULLO. terzo grid item */}
          <Grid container className={classes.grid} spacing={3}>
            {this.state.lastSearchCriteria !== '' ? (
              this.state.areSearchingForLots ? (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                  xs={12}
                >
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={this.props.t('searchingLots')}
                    ></LogoLoader>
                  </Grid>
                </Grid>
              ) : this.state.noSearchLotsFound ? (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                  xs={12}
                >
                  <Grid item style={{ marginTop: 20 }}>
                    <Typography>{this.props.t('noLotsFount')}.</Typography>
                  </Grid>
                </Grid>
              ) : (
                this.state.searchedLots
                  .slice(
                    this.state.page * this.state.limit,
                    this.state.page * this.state.limit + this.state.limit
                  )
                  .map((lot) => {
                    return (
                      <Grid
                        key={lot.core.uuid}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        className={classes.cardContainer}
                      >
                        <Card className={classes.card}>
                          <div
                            className='innerCard'
                            style={
                              isSmall
                                ? {}
                                : {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }
                            }
                          >
                            <Tooltip
                              title={this.props.t(
                                'facilitiesList.tooltip.buttons.edit'
                              )}
                            >
                              <CreateIcon
                                className='editIcon'
                                onClick={() => this.openLotModal(lot)}
                              ></CreateIcon>
                            </Tooltip>
                            {/* <CardActionArea onClick={() => this.openLotModal(lot, lot.lotNumber)}>
                                <CardHeader
                                  className={classes.cardHeaderContainer}
                                  avatar={<Avatar className={classes.cardAvatar} aria-label="product">L</Avatar>}
                                  title={this.props.t('archivedLot.title') + lot.lotNumber}
                                  titleTypographyProps={{ variant: 'h6', noWrap: true }}
                                  subheader={lot.lastInputOrOutputInformation.productName}
                                  subheaderTypographyProps={{ noWrap: true }}
                                />
                              </CardActionArea> */}
                            <div className='bottomCard'>
                              <CardActionArea
                                onClick={() => this.openLotModal(lot)}
                              >
                                <CardHeader
                                  className={classes.cardHeaderContainer}
                                  title={
                                    this.props.t('archivedLot.title') +
                                    lot.core.lotNumber
                                  }
                                  titleTypographyProps={{
                                    variant: 'h6',
                                    noWrap: true
                                  }}
                                  subheader={lot.productName}
                                  subheaderTypographyProps={{ noWrap: true }}
                                />
                              </CardActionArea>
                              <CardActions style={{ paddingTop: 0 }}>
                                <Grid
                                  container
                                  direction='row'
                                  justify='flex-end'
                                  alignItems='flex-end'
                                >
                                  <Tooltip
                                    title={this.props.t('archivedLot.goToPage')}
                                  >
                                    <IconButton
                                      aria-label='settings'
                                      target='_blank'
                                      rel='noopener'
                                      href={`${this.state.frontEndUrl}/01/${lot.productGtin}/10/${lot.core.lotNumber}`}
                                      style={{ color: '#319C5A' }}
                                    >
                                      <ExitToAppIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </CardActions>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    )
                  })
              )
            ) : this.state.selectedProductId === '' ? (
              this.state.areLotsFetching ? (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justify='center'
                  xs={12}
                >
                  <Grid item>
                    {/* <LogoLoader
                        size='large'
                        text={this.props.t('downloadingLots')}
                      >
                      </LogoLoader> */}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className={`${classes.cardContainer} lotsTable`}
                >
                  <LotsTable
                    setSnackbar={this.setSnackbar}
                    location={this.props.location}
                    history={this.props.history}
                    selectedProductId={this.state.selectedProductId}
                    lotsMobileColumns={this.state.lotsMobileColumnsLastProducts}
                    lotsColumns={this.state.lotsColumnsLastProducts}
                    lots={this.state.lots}
                    frontEndUrl={this.state.frontEndUrl}
                    isSmall={isSmall}
                    pageSize={10}
                    openLotModal={this.openLotModal}
                    page={this.state.page}
                    offset={this.state.offset}
                    searchedLots={this.state.searchedLots}
                    handlePageChange={this.handlePageChange}
                    searchLotsInTable={this.searchLotsInTable}
                    clearSearchBar={this.clearSearchBar}
                    totalLotsrecords={this.state.totalLotsrecords}
                    lastSearchCriteriaForTable={
                      this.state.lastSearchCriteriaForTable
                    }
                  />
                </Grid>
              )
            ) : this.state.areLotsFetching ? (
              <Grid
                item
                container
                direction='column'
                alignItems='center'
                justify='center'
                xs={12}
              >
                <Grid item>
                  <LogoLoader
                    size='large'
                    text={this.props.t('downloadingLots')}
                  ></LogoLoader>
                </Grid>
              </Grid>
            ) : // CASO IN CUI PRODOTTO SELEZIONATO E MOSTRIAMO I SUOI LOTTI. se non ci sono lotti viene mostrata tabella vuota
            this.state.lots.length > 0 ? (
              <>
                <Grid item xs={12} className={classes.cardContainer}>
                  <Box marginTop={2} marginBottom={2}>
                    <Card>
                      <CardContent className={classes.lastLotcard}>
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          justify='space-between'
                        >
                          <Grid item xs={12} md={10}>
                            <Typography variant='h6'>
                              QR Code Ultimo lotto
                            </Typography>
                            <Typography
                              className={classes.currentQrdescription}
                              variant='subtitle2'
                            >
                              Inquadra questo QR Code per visualizzare la pagina
                              dell&apos;ultimo lotto creato
                            </Typography>
                            <Box mt={3} mb={1}>
                              <Button
                                onClick={() =>
                                  this.openQrcodeUrl(
                                    `${
                                      this.state.frontEndUrl
                                    }/01/${this.getSelectedProductGtin()}/10/latest`
                                  )
                                }
                                className={classes.actionButton}
                                startIcon={
                                  <ExitToAppIcon
                                    className={classes.latestQrButton}
                                  />
                                }
                              >
                                Vai alla pagina pubblica
                              </Button>
                              <Button
                                onClick={() => this.downloadQrcode()}
                                startIcon={
                                  <DownloadIcon
                                    className={classes.latestQrButton}
                                  />
                                }
                              >
                                Scarica QR Code
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Box
                              mt={isSmall ? 2 : 0}
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                            >
                              <QRCodeSVG
                                size={isSmall ? 130 : 100}
                                value={`${
                                  this.state.frontEndUrl
                                }/01/${this.getSelectedProductGtin()}/10/latest`}
                              />
                              <QRCodeCanvas
                                value={`${
                                  this.state.frontEndUrl
                                }/01/${this.getSelectedProductGtin()}/10/latest`}
                                size={512}
                                style={{ display: 'none' }}
                                id='hiddenCanvas'
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes.cardContainer} lotsTable`}
                >
                  <LotsTable
                    setSnackbar={this.setSnackbar}
                    location={this.props.location}
                    history={this.props.history}
                    selectedProductId={this.state.selectedProductId}
                    lotsMobileColumns={this.state.lotsMobileColumns}
                    lotsColumns={this.state.lotsColumns}
                    lots={this.state.lots}
                    frontEndUrl={this.state.frontEndUrl}
                    isSmall={isSmall}
                    pageSize={10}
                    openLotModal={this.openLotModal}
                    page={this.state.page}
                    offset={this.state.offset}
                    searchedLots={this.state.searchedLots}
                    handlePageChange={this.handlePageChange}
                    searchLotsInTable={this.searchLotsInTable}
                    clearSearchBar={this.clearSearchBar}
                    totalLotsrecords={this.state.totalLotsrecords}
                    lastSearchCriteriaForTable={
                      this.state.lastSearchCriteriaForTable
                    }
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                className={`${classes.cardContainer} lotsTable`}
              >
                <LotsTable
                  setSnackbar={this.setSnackbar}
                  location={this.props.location}
                  history={this.props.history}
                  selectedProductId={this.state.selectedProductId}
                  lotsMobileColumns={this.state.lotsMobileColumns}
                  lotsColumns={this.state.lotsColumns}
                  lots={this.state.lots}
                  frontEndUrl={this.state.frontEndUrl}
                  isSmall={isSmall}
                  pageSize={10}
                  openLotModal={this.openLotModal}
                  page={this.state.page}
                  offset={this.state.offset}
                  searchedLots={this.state.searchedLots}
                  handlePageChange={this.handlePageChange}
                  searchLotsInTable={this.searchLotsInTable}
                  clearSearchBar={this.clearSearchBar}
                  totalLotsrecords={this.state.totalLotsrecords}
                  lastSearchCriteriaForTable={
                    this.state.lastSearchCriteriaForTable
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs>
            {this.state.lastSearchCriteria !== '' &&
              !this.state.areSearchingForLots &&
              !this.state.noSearchLotsFound && (
                <Pagination
                  limit={this.state.limit}
                  offset={this.state.offset}
                  total={this.state.lots.length}
                  onClick={(e, offset, page) => this.changePage(offset, page)}
                  style={{ textAlign: 'center', paddingTop: 20 }}
                />
              )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Lots.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(
  withStyles(styles)(withWidth()(withTranslation('lots')(Lots)))
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import CardModalLotClassic from './CardModalLotClassic'
import { isThisAccountHasFashionFunctionality } from '../../utils/acl-organization'

import {
  withWidth
  // Switch
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'

import CardModalLotCore from './CardModalLotCore'

// import ErrorIcon from '@material-ui/icons/Error'

/* Questo componente è solo un layer intermedio per decidere se aprire la modal per la supplychain o quella classica.
Sono stati creati due componenti separati per non rompere nulla */
class CardModalLot extends React.Component {
  constructor(props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()
  }

  returnDefaultState() {
    return {
      isLoadingInfo: true,
      lot: this.props.lot,
      products: this.props.products
    }
  }

  render() {
    console.log('nel render:')
    // creo variabile che sarà true se siamo da mobile
    // if (!this.state.isLoadingInfo) {
    console.log('this.state.isLoadingInfo nel render:')
    if (this.props.lot.lotType === 'core') {
      return (
        this.props.open && (
          <CardModalLotCore
            setSnackbar={this.props.setSnackbar}
            lot={this.state.lot}
            open={this.props.open}
            onCloseModal={this.props.onCloseModal}
            frontEndUrl={this.props.frontEndUrl}
            // product={this.state.product}
          />
        )
      )
    } else {
      return (
        this.props.open && (
          <CardModalLotClassic
            setSnackbar={this.props.setSnackbar}
            isFashion={isThisAccountHasFashionFunctionality()}
            lot={this.state.lot}
            open={this.props.open}
            onCloseModal={this.props.onCloseModal}
            frontEndUrl={this.props.frontEndUrl}
            // product={this.state.product}
          />
        )
      )
    }
  }
}

CardModalLot.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withRouter(
  withWidth()(withTranslation('lotDetails')(CardModalLot))
)

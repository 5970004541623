import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'
import { parseDomain } from 'parse-domain'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null
export let organizations = ls.get('organizations') || []

// FUNCTIONS
// esport query per i prori ACL
export async function returnSelfACL(params) {
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/accounts/self`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params
  })
}
// esport query per le organizations
export async function returnOrganizations() {
  // console.log('dentro returnOrganizations')
  return axios.get(`${configuration.apiBaseUrl}/organizations/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export function isThisAccountHasLightLotFunctionality() {
  const currOrganization = ls.get('currOrganization')
  if (
    currOrganization &&
    currOrganization.functionality &&
    currOrganization.functionality.length > 0 &&
    currOrganization.functionality.includes('light-lot')
  ) {
    return true
  } else {
    return false
  }
}

export function userDetails() {
  const currOrganization = ls.get('currOrganization')
  const user = ls.get('user')
  return {
    projectId: currOrganization?.uuid || null,
    apikey: user?.metadata?.apikey || null
  }
}

export function isThisDomainIsIwine() {
  const domain = parseDomain(window.location.host)
  console.log('Domain::', domain)
  if (domain.type === 'LISTED') {
    if (domain.domain === 'trusty') {
      if (domain.subDomains.length) {
        const subDomain = domain.subDomains[0]
        if (subDomain.includes('i-wine')) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

export function isThisAccountHasFashionFunctionality() {
  const currOrganization = ls.get('currOrganization')
  if (
    currOrganization &&
    currOrganization.features &&
    currOrganization.features.length > 0 &&
    currOrganization.features.includes('fashion')
  ) {
    return true
  } else {
    return false
  }
}

export function isThisAccountHasPrintLabelFunctionality() {
  const currOrganization = ls.get('currOrganization')
  if (
    currOrganization &&
    currOrganization.functionality &&
    currOrganization.functionality.length > 0 &&
    currOrganization.functionality.includes('print-label')
  ) {
    return true
  } else {
    return false
  }
}

export function isThisOrgIsFels() {
  const currOrganization = ls.get('currOrganization')
  if (
    currOrganization &&
    currOrganization.uuid === '0f83790a-d927-4eb6-b910-faf2473645b4'
  ) {
    return true
  } else {
    return false
  }
}

export function isThisOrgIsFoodItaliae() {
  const currOrganization = ls.get('currOrganization')
  if (
    currOrganization &&
    currOrganization.uuid === '5ad4a56c-9326-43e5-a236-f1a99f1edf66'
  ) {
    console.log('sono nel true')
    return true
  } else {
    console.log('sono nel false')
    return false
  }
}

export function isThisOrgIsComcap() {
  const currOrganization = ls.get('currOrganization')
  if (
    currOrganization &&
    currOrganization.uuid === 'df810797-693c-4180-82a1-8a89bdae2de5'
  ) {
    return true
  } else {
    return false
  }
}

// funzione che torna/aggiorna l'ACL e l'organizzazione
export async function updateOrganizationACL() {
  console.log('dentro returnOrganizationACL')
  if (ls.get('token')) {
    try {
      const userParams = {
        include: 'roles,groups,aclrules,groupmemberships,projects'
      }
      // console.log('token => ', ls.get('token'))
      const aclResponse = await returnSelfACL(userParams)
      // console.log('aclResponse => ', aclResponse.data)
      // carico gli acl nell'utente
      const user = ls.get('user')
      user.acl = aclResponse.data.data
      console.log('user.acl', user.acl)
      // console.log('new user => ', user)
      ls.set('user', user)
      // mi prendo le organizzazioni -> NOTE: per come è costruito VIENE PRESA SOLO LA PRIMA ORGANIZAZZIONE -> MODIFICARE COME ALTRA PIATTAFORMA
      // console.log('organizationsResponse => ', organizationsResponse.data)
      organizations = user.acl.includes.projects
      ls.set('organizations', organizations)
      if (!ls.get('currOrganization')) {
        // nessuna organizzazione seleziono la prima
        currOrganization = organizations[0]
        ls.set('currOrganization', currOrganization)
      } else {
        const currIndex = organizations.findIndex(
          (organization) =>
            organization.uuid === ls.get('currOrganization').uuid
        )
        if (currIndex > -1) {
          currOrganization = organizations[currIndex]
        } else {
          // se c'è un'organizzazione ma non la trovo setto la prima
          currOrganization = organizations[0]
          ls.set('currOrganization', currOrganization)
        }
      }
    } catch (e) {
      // window.alert('Something went wrong')
      console.error('Error while loading organization, e => ', e)
    }
  } else {
    console.log('no user token')
  }
}

import React from 'react'
import {
  makeStyles,
  Card,
  CardContent,
  Box,
  Typography,
  lighten
} from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import BusinessIcon from '@material-ui/icons/Business'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none'
  },
  count: {
    color: '#333',
    lineHeight: 1,
    fontFamily: 'Fredoka One'
  },
  circle: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    height: 70
  },
  icon: {
    width: 50,
    height: 50,
    color: '#3C4858'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: '#939393'
  },
  card: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.95)
    }
  }
}))

function HomeCard({ type, data }) {
  const classes = useStyles()
  const { t } = useTranslation('home')

  const iconsMap = {
    companies: <GroupIcon className={classes.icon} />,
    facilities: <BusinessIcon className={classes.icon} />,
    products: <GroupWorkIcon className={classes.icon} />,
    lots: <ViewModuleIcon className={classes.icon} />,
    'light-lots': <ViewModuleIcon className={classes.icon} />
  }
  const titlesMap = {
    companies: t('cards.titles.companies'),
    facilities: t('cards.titles.facilities'),
    products: t('cards.titles.products'),
    lots: t('cards.titles.lots')
  }

  const getTitle = (cardType) => {
    return titlesMap[cardType] || ''
  }

  const getIcon = (cardType) => {
    return iconsMap[cardType] || ''
  }

  return (
    <Link to={`/${type}`} className={classes.link}>
      <Card className={classes.card}>
        <CardContent>
          <Box
            width='100%'
            display='flex'
            justifyContent='space-between'
            className={`${type}-box`}
          >
            <Box display='flex' flexDirection='column'>
              <Typography
                component='div'
                variant='h2'
                className={classes.count}
              >
                {data}
              </Typography>
              <Box mt={2}>
                <Typography
                  className={classes.title}
                  component='h6'
                  variant='body1'
                >
                  {getTitle(type)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <div className={`${classes.circle} icona`}>{getIcon(type)}</div>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Link>
  )
}

export default HomeCard
